import { Component, OnDestroy, OnInit } from "@angular/core";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import {
  Label,
  SingleDataSet,
  monkeyPatchChartJsTooltip,
  monkeyPatchChartJsLegend,
  Color,
  MultiDataSet,
} from "ng2-charts";
import { User } from "src/app/models/User";
import { UserService } from "src/app/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Farmacia } from "src/app/models/Farmacia";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { OrderService } from "src/app/services/order.service";
import { MessageService } from "src/app/services/message.service";
import { Messaggio } from "src/app/models/messaggio";
import { Order } from "src/app/models/Order";
import { CartService } from "src/app/services/cart.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-manager-dashboard",
  templateUrl: "./manager-dashboard.component.html",
  styleUrls: ["./manager-dashboard.component.css"],
})
export class ManagerDashboardComponent implements OnInit, OnDestroy {
  listamessaggiRicevuti: Messaggio[];
  listamessaggiInviati: Messaggio[];
  currentDate = new Date();
  updateTime: string;
  listautentiOrdinata: User[];
  showUtenti: boolean;
  listaUtenti: User[];
  listaFarmacie: Farmacia[];
  listafarmacieOrdinata: Farmacia[];
  idFarmacia: number = 0;
  averageHour: number = 0;
  averageMinutes: number = 0;
  nomeShop: string;
  size: number;
  oggettoDirezione = {
    field: "updateTime",
    dir: "desc",
  };
  page: Order[];
  burnedCard: number;
  unBurnedCard: number;
  //INIZIO DIAGRAMMA A FLUSSO
  lineChartDataDelivery: ChartDataSets[] = [
    { data: [0], label: "Farmacia" },
    { data: [0], label: "Domicilio" },
  ];

  lineChartLabelsDelivery: Label[] = [];

  lineChartDataOrders: ChartDataSets[] = [
    { data: [0], label: "Effettuati" },
    { data: [0], label: "Annullati" },
  ];

  lineChartLabelsOrders: Label[] = [];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      backgroundColor: "rgba(251,185,0,0.66)",
    },
    {
      backgroundColor: "rgba(255,0,0,0.67)",
    },
  ];

  lineChartColorsDelivery: Color[] = [
    {
      backgroundColor: "rgba(251,185,0,0.66)",
    },
    {
      backgroundColor: "rgba(0,172,0,0.71)",
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";

  //INIZIO DIAGRAMMA CARTESIANO

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabelsAge: Label[] = [
    "18-32",
    "33-46",
    "47-60",
    "61-74",
    "75-88",
    "90+",
  ];
  barChartColors: Color[] = [
    {
      backgroundColor: "rgba(0,95,223,0.6)",
    },
    {
      backgroundColor: "rgba(255,0,0,0.6)",
    },
  ];
  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [];

  barChartDataAge: ChartDataSets[] = [
    {
      data: [0, 0, 0, 0, 0, 0],
      label: "Maschi",
    },
    {
      data: [0, 0, 0, 0, 0, 0],
      label: "Femmine",
    },
  ];

  //INIZIO DIAGRAMMA CIAMBELLA
  doughnutChartLabelsMessages: Label[] = ["Letti", "Non letti", "Inviati"];
  doughnutChartDataMessages: MultiDataSet = [[0, 0, 0]];
  doughnutChartType: ChartType = "doughnut";
  doughnutChartLabelsOrders: Label[] = [
    "Evasi",
    "Annullati",
    "In attesa di preventivo",
  ];
  doughnutChartDataOrders: MultiDataSet = [[0, 0, 0]];

  //COLORI
  PieChartColorsInternal = [
    {
      backgroundColor: ["rgba(255,0,0,0.6)", "rgba(0,95,223,0.6)"],
    },
  ];
  doughnutChartColorsOrders = [
    {
      backgroundColor: [
        "rgba(0,172,0,0.71)",
        "rgba(255,0,0,0.67)",
        "rgba(251,185,0,0.66)",
      ],
    },
  ];
  doughnutChartColorsMessages = [
    {
      backgroundColor: [
        "rgba(0,172,0,0.71)",
        "rgba(255,0,0,0.67)",
        "rgba(251,185,0,0.66)",
      ],
    },
  ];

  public pieChartCard: Label[] = [["Native"], ["Convertite"]];
  public pieChartDataCard: SingleDataSet = [0, 0];
  private subscriptions: Subscription[] = [];
  //inizio diagramma a torta
  public pieChartDataOrders: SingleDataSet = [0, 0, 0];
  public pieChartOrders: Label[] = [
    "Evasi",
    "Annullati",
    "In attesa di preventivo",
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartPlugins = [];
  loader: boolean = false; 
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private FarmacieService: FarmacieService,
    private orderService: OrderService,
    private messageService: MessageService,
    private cartService: CartService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.loader = true;
    this.spinner.show();
    this.farmaciaList();
    /*     this.getOrders(); */
    this.calcolaListaMessaggi();
    this.orderChart();
    this.getCardNumber();

  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getOrders(id = 0) {
    this.subscriptions.push(this.orderService.getLastSevenMonthsOrders(this.idFarmacia).subscribe((x) => {
      /*  let temp: Order[] = []; */
      this.page = x;
      /* x.forEach((element) => {
        let dateMonths = this.currentDate.getMonth();
        let elementDate = new Date(element.updateTime);
        let elementMonths = elementDate.getMonth();
        if (dateMonths - elementMonths <= 7) {
          temp.push(element);
        }
      }); */

      this.getNumberOrders(this.page);
      this.calcolaListaMessaggi();
      this.calcolaUtenti(this.idFarmacia);
      this.getCardNumber();
      this.getUnburnedCardNative();
      this.getBurnedCardNative();
    },
      (err) => {
        console.log("Qualcosa è andato storto con le info degli ordini, riprova.")
        this.spinner.hide();
        this.loader = false;
      }));
    /*  let nextPage = 1;
    this.size = 999999;

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;
    this.orderService
      .getPageEmployee(
        nextPage,
        this.size,
        orderSession.field,
        orderSession.dir,
        -1,
        this.updateTime
      )
      .subscribe(
        (page) => {
          page.content.forEach((element, index) => {
            if (element.idShop != this.idFarmacia && this.idFarmacia != 0) {
              page.content.splice(index, 1);
            }
          });
          this.page = page;
          this.getNumberOrders();
          this.calcolaListaMessaggi();
          this.calcolaUtenti(this.idFarmacia);
          console.log(this.page);
        },
        (_) => {
          console.log("Get Order Failed");
        }
      ); */
  }
  getUnburnedCardNative() {
    this.subscriptions.push(this.cartService.getunburnedcardnumber().subscribe((x) => {
      this.unBurnedCard = x;
    },
      (err) => {
        console.log("Errore con le informazioni delle card")
        this.loader = false;
        this.spinner.hide();
        
      }));
  }
  getBurnedCardNative() {
    this.subscriptions.push(this.cartService.getburnedcardnumber().subscribe((x) => {
      this.burnedCard = x;
    },
      (err) => {
        console.log("Errore con le informazioni delle card")
        this.loader = false;
        this.spinner.hide();
      }));
  }
  getCardNumber() {
    this.subscriptions.push(this.userService.getDashboardCard(this.idFarmacia).subscribe((x) => {
      this.pieChartDataCard = [x.internal, x.external];
      this.loader = false;
      this.spinner.hide();
    },
      (err) => {
        console.log("Qualcosa è andato storto con le card degli utenti, riprova.")
        this.loader = false;
        this.spinner.hide();
        
      }));
  }
  getNumberOrders(page) {

    let tempArrayFarmacia = new Array(7).fill(0);
    let tempArrayDelivery = new Array(7).fill(0);
    let tempArray = new Array(7).fill(0);
    let tempArrayAnnullati = new Array(7).fill(0);
    let tempArrayCompilare = new Array(7).fill(0);
    let currentMonth = this.currentDate.getMonth() + 1;
    let cont = 6;
    let i = 0;
    page.forEach(element => {
      let dateToCompare = new Date(element.updateTime).getMonth() + 1;

      if (currentMonth != dateToCompare) {
        currentMonth = dateToCompare;
        cont--;
      }

      if (element.orderStatus >= 10 || element.orderStatus == 5) {
        tempArray[cont] += 1;
      }
      if (element.orderStatus == 3 || element.orderStatus == 6) {
        tempArrayCompilare[cont] += 1;
        if (element.orderStatus == 3) tempArrayFarmacia[cont] += 1;
        else tempArrayDelivery[cont] += 1;
      }
      if (element.orderStatus == 2) {
        tempArrayAnnullati[cont] += 1;
      }



    });

    /* 
        try {
          do {
            let element = page[i];
            let elementdate: Date;
            if (element.updateTime != null) {
              elementdate = new Date(element.updateTime);
            } else {
              elementdate = new Date(element.createTime);
            }
            let elementmonth = elementdate.getMonth() + 1;
    
            if (currentMonth != elementmonth) {
              currentMonth = elementdate.getMonth() + 1;
              cont--;
            }
            
            if (element.orderStatus >= 10 || element.orderStatus == 5) {
              tempArray[cont] += 1;
            }
            if (element.orderStatus == 3 || element.orderStatus == 6) {
              tempArrayCompilare[cont] += 1;
              if (element.orderStatus == 3) tempArrayFarmacia[cont] += 1;
              else tempArrayDelivery[cont] += 1;
            }
            if (element.orderStatus == 2) {
              tempArrayAnnullati[cont] += 1;
            }
            i++;
          } while (cont >= 0 && i < page.length);
        } catch (err) {} */


    this.lineChartDataDelivery[0].data = tempArrayFarmacia;
    this.lineChartDataDelivery[1].data = tempArrayDelivery;
    this.lineChartDataOrders[0].data = tempArray;
    this.lineChartDataOrders[1].data = tempArrayAnnullati;
    let a = this.sumTotale(tempArray);
    let b = this.sumTotale(tempArrayAnnullati);
    let c = this.sumTotale(tempArrayCompilare);
    /* let sum = a + b + c; */
    this.doughnutChartDataOrders = [[a, b, c]];
    /* parseInt(((a * 100) / sum).toFixed(3)) */
  }
  sumTotale(array: Array<number>) {
    let tot = 0;

    array.forEach((element) => {
      tot += element;
    });
    return tot;
  }
  onSubmit() {
    this.loader = true;
    this.spinner.show();
    this.lineChartDataDelivery[0].data = [0];
    this.lineChartDataDelivery[1].data = [0];
    this.lineChartDataOrders[0].data = [0];
    this.lineChartDataOrders[1].data = [0];
    this.pieChartDataOrders = [0, 0, 0];
    this.pieChartDataCard = [0, 0, 0];
    this.barChartDataAge[0].data = [0, 0, 0, 0, 0, 0];
    let i = 0;
    let flag = false;
    do {
      if (this.nomeShop == this.listafarmacieOrdinata[i].name) {
        this.idFarmacia = this.listafarmacieOrdinata[i].id;
        flag = true;
      }
      if (
        this.nomeShop == "" ||
        this.nomeShop == " " ||
        this.nomeShop == "  "
      ) {
        this.idFarmacia = 0;
      }
      i++;
    } while (flag == false && i < this.listafarmacieOrdinata.length);

    this.orderChart();
  }
  calcolaUtenti(id = 0) {
    this.showUtenti = false;
    const id_shop = id;
    this.subscriptions.push(this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_MANAGER");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
      let tempMaschio = new Array(5).fill(0);
      let tempFemmina = new Array(5).fill(0);
      let year = this.currentDate.getFullYear();
      let sum = 0;
      this.listautentiOrdinata.forEach((element) => {
        if (element.born) {
          let età = parseInt(element.born.substring(0, 4));
          sum = year - età;
          if (sum >= 18 && sum <= 32)
            element.sex == "M" ? tempMaschio[0]++ : tempFemmina[0]++;

          if (sum >= 33 && sum <= 46)
            element.sex == "M" ? tempMaschio[1]++ : tempFemmina[1]++;

          if (sum >= 47 && sum <= 60)
            element.sex == "M" ? tempMaschio[2]++ : tempFemmina[2]++;

          if (sum >= 61 && sum <= 74)
            element.sex == "M" ? tempMaschio[3]++ : tempFemmina[3]++;

          if (sum >= 75 && sum <= 80)
            element.sex == "M" ? tempMaschio[4]++ : tempFemmina[4]++;

          if (sum >= 90)
            element.sex == "M" ? tempMaschio[5]++ : tempFemmina[5]++;
        }
      });
      this.barChartDataAge[0].data = tempMaschio;
      this.barChartDataAge[1].data = tempFemmina;
    }));
  }

  farmaciaList() {
    this.subscriptions.push(this.FarmacieService.getFarmacie().subscribe((data) => {
      this.listaFarmacie = data.filter((e) => e.id != 0);
      this.listaFarmacie = this.listaFarmacie.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listafarmacieOrdinata = this.listaFarmacie.slice();
      let manager: Farmacia;
      manager = {
        id: 0,
        active: null,
        address: null,
        gifts: null,
        idCity: null,
        idDistrict: null,
        idEmployee: null,
        idRegion: null,
        idSmoove: null,
        lat: null,
        long_: null,
        name: "Tutti",
        openingTime: null,
        phone: null,
        meters: null,
        vat: null,
        deliveryService: null,
      };
      this.listafarmacieOrdinata.unshift(manager);
    },
      (err) => {
        console.log("Qualcosa è andato storto, riprova.");
        this.loader = false;
        this.spinner.hide();
        
      }));
  }
  searchFarmacia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchFarm(term)))
    );

  orderChart() {
    let tempMonth: Array<string> = [];
    let m = this.currentDate.getMonth() + 1;
    for (let i = 0; i <= 6; i++) {
      let y = this.currentDate.getFullYear();
      let currentMonth = m - i;

      if (currentMonth == 0) {
        currentMonth = 12 - currentMonth;
        y = y - 1;
      }
      if (currentMonth < 0) {
        currentMonth = 12 + currentMonth;
        y = y - 1;
      }
      tempMonth.unshift(this.fromNumberToMonth(currentMonth));
      this.updateTime = y + "-" + currentMonth + "-" + "01";
    }

    this.lineChartLabelsOrders = tempMonth;
    this.lineChartLabelsDelivery = tempMonth;
    this.getOrders(this.idFarmacia);
  }
  fromNumberToMonth(number) {
    switch (number) {
      case 1:
        return "Gennaio";

      case 2:
        return "Febbraio";

      case 3:
        return "Marzo";

      case 4:
        return "Aprile";

      case 5:
        return "Maggio";

      case 6:
        return "Giugno";

      case 7:
        return "Luglio";

      case 8:
        return "Agosto";

      case 9:
        return "Settembre";

      case 10:
        return "Ottobre";

      case 11:
        return "Novembre";

      case 12:
        return "Dicembre";
    }
  }

  searchFarm(term) {
    let tempFarm = [];
    this.listafarmacieOrdinata
      .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 10)
      .forEach((element) => {
        tempFarm.push(element.name);
      });
    return tempFarm;
  }
  /*   formatter = (result: Farmacia) => result.name;
  formatterInput = (result: Farmacia) => result.name; */
  /*   getOrderSession() {
    return localStorage.getItem("order_ordinamento");
  }

  setOrderSession(oggettoDirezione) {
    localStorage.setItem("order_ordinamento", JSON.stringify(oggettoDirezione));
  } */
  calcolaListaMessaggi() {
    let messaggiNonLetti = 0;
    let messaggiLetti = 0;
    let messaggiInviati = 0;
    if (this.idFarmacia != 0) {
      this.subscriptions.push( this.messageService
        .getMessageRicevuti(this.idFarmacia.toString())
        .subscribe((x) => {
          this.listamessaggiRicevuti = x;
          this.listamessaggiRicevuti.forEach((element) => {
            if (element.read == null) {
              messaggiNonLetti++;
            }
            if (element.read != null) {
              messaggiLetti++;
              this.averageHour =
                (this.averageHour +
                  this.averageCalculator(
                    new Date(element.created),
                    new Date(element.read)
                  )) /
                2;
            }
          });
          this.averageMinutes = Math.floor(
            (this.averageHour - Math.floor(this.averageHour)) * 60
          );
          this.averageHour = Math.floor(this.averageHour);
          this.messageService
            .getMessageInviati(this.idFarmacia.toString())
            .subscribe((msgInviati) => {
              this.listamessaggiInviati = msgInviati;
              this.listamessaggiInviati.forEach((msg) => {
                messaggiInviati++;
              });

              this.doughnutChartDataMessages = [
                [messaggiLetti, messaggiNonLetti, messaggiInviati],
              ];
            },
              (err) => {
                console.log("Qualcosa è andato storto con i messaggi, riprova.")
                this.spinner.hide();
              });
        },
          (err) => {
            console.log("Qualcosa è andato storto con i messaggi, riprova.")
            this.spinner.hide();
          }));
    } else {
     this.subscriptions.push(this.messageService
        .getMessageRicevutiEInviati(this.idFarmacia)
        .subscribe((x) => {
          let recipient;
          recipient = x.recipient;
          let sender;
          sender = x.sender;
          recipient.forEach((element) => {
            messaggiInviati++;
          });
          sender.forEach((element) => {
            if (element.read == null) {
              messaggiNonLetti++;
            }
            if (element.read != null) {
              messaggiLetti++;
              this.averageHour =
                (this.averageHour +
                  this.averageCalculator(
                    new Date(element.created),
                    new Date(element.read)
                  )) /
                2;
            }
          });
          this.averageMinutes = Math.floor(
            (this.averageHour - Math.floor(this.averageHour)) * 60
          );
          this.averageHour = Math.floor(this.averageHour);
          this.doughnutChartDataMessages = [
            [messaggiLetti, messaggiNonLetti, messaggiInviati],
          ];
        },
          (err) => {
            console.log("Errore con le informazioni dei messaggi")
            this.spinner.hide();
          }));
    }
  }
  averageCalculator(date1: Date, date2: Date) {
    var d1msecs = date1.getTime() / 1000; // get milliseconds
    var d2msecs = date2.getTime() / 1000; // get milliseconds

    var avgTime = Math.abs(d1msecs - d2msecs);
    //var result = new Date(avgTime);
    return (avgTime / 3600) % 24;
    //return result.getTime() / 3600000;
  }



}
