import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ProductInfo } from "../models/productInfo";
import { apiUrl } from "../../environments/environment";
import { UserService } from "./user.service";
@Injectable({
  providedIn: "root",
})
export class ProductService {
  private productUrl = `${apiUrl}/product`;
  private categoryUrl = `${apiUrl}/category`;
  private productTextUrl = `${apiUrl}/productext`;
  constructor(private http: HttpClient,
    private userService: UserService) {}

  getAllInPage(page: number, size: number): Observable<any> {
    const url = `${this.productUrl}?page=${page}&size=${size}`;
    return this.http
      .get(url)
      .pipe
      // tap(_ => console.log(_)),
      ();
  }
  getAllInPageFilter(filter): Observable<any> {
    const url = `${this.productUrl}?page&size&filter=${filter}`;
    return this.http
      .get(url)
      .pipe
      // tap(_ => console.log(_)),
      ();
  }

  getAllDiscountedInPage(page: number, size: number, filter): Observable<any> {
    const url = `${apiUrl}/productWithCodMinsan/?page=${page}&size=${size}&filter=${filter}`;
    return this.http
      .get(url)
      .pipe
      // tap(_ => console.log(_)),
      ();
  }

  getAllDiscountedInPageFilter(filter): Observable<any> {
    const url = `${apiUrl}/productWithCodMinsan/?page&size&filter=${filter}`;
    return this.http
      .get(url)
      .pipe
      // tap(_ => console.log(_)),
      ();
  }

  getAllProdotti(text, cont): Observable<any> {
    const url = `${this.productTextUrl}?text=${text}&page=${cont}`;
    return this.http
      .get(url)
      .pipe
      // tap(_ => console.log(_)),
      ();
  }

  getCategoryInPage(
    categoryType: number,
    page: number,
    size: number
  ): Observable<any> {
    const url = `${this.categoryUrl}/${categoryType}?page=${page}&size=${size}`;
    return this.http
      .get(url)
      .pipe
      // tap(data => console.log(data))
      ();
  }

  addOrModifiyPhoto(codMinsan, photo): Promise<any> {

    var formdata = new FormData();  
 
    formdata.append("productImage", photo)
  
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/addOrModifyProductImage?codMinsan=${codMinsan}`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  
  }

  getProductPhoto(codminsan): Promise<any> {
 
 
  
  
    var requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/mediaTemp/ProductImages/${codminsan}.jpg`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
  }
/* 
  /api/mediaTemp/ProductImages/{name} */

  // funziona
  getDetail(id: String): Observable<ProductInfo> {
    const url = `${this.productUrl}/${id}`;
    return this.http.get<ProductInfo>(url).pipe(
      catchError((_) => {
        console.log("Get Detail Failed");
        return of(new ProductInfo());
      })
    );
  }

  update(productInfo: ProductInfo): Observable<ProductInfo> {
    const url = `${apiUrl}/seller/product/${productInfo.productId}/edit`;
    return this.http.put<ProductInfo>(url, productInfo);
  }

  create(productInfo: ProductInfo): Observable<ProductInfo> {
    const url = `${apiUrl}/seller/product/new`;
    return this.http.post<ProductInfo>(url, productInfo);
  }

  delete(productInfo: ProductInfo): Observable<any> {
    const url = `${apiUrl}/seller/product/${productInfo.productId}/delete`;
    return this.http.delete(url);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
