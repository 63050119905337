import { Component, OnInit } from "@angular/core";
import { FarmacieService } from "src/app/services/farmacie.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Farmacia } from "src/app/models/Farmacia";
import { UserService } from "../../services/user.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-farmacia-edit",
  templateUrl: "./farmacia-edit.component.html",
  styleUrls: ["./farmacia-edit.component.css"],
})
export class FarmaciaEditComponent implements OnInit {
  farmacia = new Farmacia();
  nome: string;
  prov: string;
  citta: any;

  constructor(
    private farmaciaService: FarmacieService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private location: Location
  ) { }

  ngOnInit() {
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
      });

    const id = +this.route.snapshot.paramMap.get("id");
    this.farmaciaService.getFarmacia(id).subscribe(
      (farma) => {
        this.farmacia = farma;
        console.log(this.farmacia);
      },
      (e) => { }
    );
  }

  onSubmit() {
    this.modificaFarmacia();
  }

  modificaFarmacia() {
    this.farmacia.idCity = this.citta + " (" + this.prov.toUpperCase() + ")";
    this.farmaciaService.updateFarmacia(this.farmacia).subscribe(
      (farma) => {
        if (!farma) throw new Error();
        this.router.navigate(["/farmacie"]);
      },
      (err) => { }
    );
  }
}
