import { Component, Inject, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "src/app/models/User";
import { Location } from "@angular/common";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Role } from "src/app/enum/Role";
import { Sort } from "@angular/material/sort";
import { FarmacieService } from "src/app/services/farmacie.service";
import { CouponService } from "src/app/services/coupon.service";
import { Coupon, couponFormat, CouponUsers } from "src/app/models/Coupon";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

export interface DialogData {
  idShop;
  nomeShop;
}
@Component({
  selector: "app-new-coupon",
  templateUrl: "./new-coupon.component.html",
  styleUrls: ["./new-coupon.component.css"],
})
export class NewCouponComponent implements OnInit {
  allCoupon: Array<couponFormat> = [];
  allCouponOrdinata: Array<couponFormat> = [];
  nome: string;
  parolaChiave: number;
  parolaChiaveFarmacia: string;
  nomeUtente: string;
  cognomeUtente: string;
  sessoUtente: string;
  indirizzoUtente: string;
  email: string;
  telefono: string;
  dataNascita: string;
  listaUtenti: User[];
  idProva: string;
  user: User;
  currentUser: JwtResponse;
  Role = Role;
  listautentiOrdinata: User[];
  showUtenti: boolean;
  idShop;
  idUtenti: Array<number> = [];
  parolaChiaveSex: any;
  parolaChiaveBorn: any;
  parolaChiaveComune: any;
  nomeShop: any;
  codiceCoupon: string;
  flagDescription: boolean = true;
  sex = [
    {
      sex: "M",
    },
    { sex: "F" },
  ];
  shiplab
  constructor(
    private couponService: CouponService,
    private farmacieService: FarmacieService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    /*  let idUser;

    this.currentUser = this.userService.currentUserValue;
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
        idUser = x.id; */
    /*    if (
          this.route.snapshot.paramMap.get("id") != idUser &&
          this.userService.currentUserValue.role == Role.Manager
        ) {
          this.router.navigate(["/login"]);
        } */
    /*   }); */
  }

  ngOnInit() {
    this.spinner.show();
    this.listaCoupon();
    /* this.showUsers(); */
    this.idShop = this.route.snapshot.paramMap.get("id");
    this.farmacieService.getFarmacia(this.idShop).subscribe((x) => {
      this.nomeShop = x.name;
    });
  }
  listaCoupon() {
    this.couponService.getCoupon().subscribe(
      (x) => {
        this.allCoupon = x;
        this.spinner.hide();
        /*   this.allCoupon = this.allCoupon.sort(function (a, b) {
        return a.id < b.id ? 1 : -1;
      }); */
        return (this.allCouponOrdinata = this.allCoupon.slice());
        /*    this.allCoupon.forEach((element, index) => {
        this.couponService.getUsers(element.id).subscribe((u) => {
          if (u.length > 1) {
          }
          u.forEach((user) => {
            this.idUtenti.push(user.userId);
            console.log(this.idUtenti);
          });
        });
      }); */
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  /* YourCancelEvent() {
    this.popup.hide();
  } */
  ClickButton() {
    const dialogRef = this.dialog.open(DialogCouponManager, {
      width: "400px",
      height: "500px",
      data: { idShop: this.idShop, nomeShop: this.nomeShop },
    });
    /*     this.popup.options = {
    header: "Valida coupon",
    color: "rgb(251,185,0)", // red, blue....
    widthProsentage: 30, // The with of the popou measured by browser width
    animationDuration: 1, // in seconds, 0 = no animation
    showButtons: true, // You can hide this in case you want to use custom buttons
    confirmBtnContent: "Invia", // The text on your confirm button
    cancleBtnContent: "Annulla", // the text on your cancel button
    confirmBtnClass: "btn btn-default", // your class for styling the confirm button
    cancleBtnClass: "btn btn-default", // you class for styling the cancel button

    animation: "fadeInDown", // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
  };
  this.popup.show(this.popup.options); */
  }
  dettaglioUtente(utente: User) {
    this.showUtenti = true;
    //console.log(utente.id);
    ("profiloutente/:id");
    window.open(`profiloutente/${utente.id}`);
  }
  /* YourConfirmEvent() {
    this.couponService.useCoupon(this.codiceCoupon).subscribe(
      (x) => {
        window.alert("coupon utilizzato correttamente");
        this.invioNotifica(this.codiceCoupon.split("-")[0]);
        this.YourCancelEvent();
      },
      (_) => {
        window.alert(
          "Il coupon digitato potrebbe essere non ancora attivo, già usato o scaduto"
        );
      }
    );
  } */
  removeCoupon(id) {
    var r = confirm(
      "Attenzione, cancellare il coupon è un azione irreversibile, vuoi continuare?"
    );
    if (r == true) {
      this.couponService.removeCoupon(id).subscribe(
        (x) => {
          window.alert("Coupon eliminato corretamente!");
          this.listaCoupon();
        },
        (err) => {
          if (err.text.includes("coupon")) {
            window.alert("Coupon eliminato corretamente!");
            this.listaCoupon();
          }
        }
      );
    }
  }
  invioNotifica(codice) {
    this.userService.getprofilebyId(codice).subscribe((y) => {
      let token = y.fbtoken;
      this.notificatioService.invionotifica(
        [token],
        "Coupon usato con successo.",
        "Coupon utilizzato da: " +
          this.nomeShop /*  "Farmacia " + this.nameFarmacia */
      );
    });
  }
  /*   showUsers() {
    this.showUtenti = false;
    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_MANAGER");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();

      this.listautentiOrdinata.forEach((element) => {
        this.FarmacieService.getFarmacia(element.shop).subscribe((x) => {
          element.nomeShop = x.name;
        });
      });
    });
  } */
  
  truncate(str, n){
    if(this.flagDescription)
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    
    else
      return str;

   
  };
  toggleTruncate(){
   this.flagDescription = !this.flagDescription;
  }

  
  downloadFile(data: any, filename: string) {
    const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename + ".xlsx");
    } else {
        const today = new Date();
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}_${today.getTime()}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    window.URL.revokeObjectURL(url);
}

couponCsv() {
  this.spinner.show();
  this.couponService.getCouponListExcel().then((response) => {
      this.downloadFile(response, "lista-coupon");
      this.spinner.hide();
  }).catch((err) => {
      alert("Attenzione, non sono stati trovati acquisti con bonus punti");
      console.log(err);
      this.spinner.hide();
  });
}
  
  ordinalistaCoupon(sort: Sort) {
    const data = this.allCoupon.slice();
    if (!sort.active || sort.direction === "") {
      this.allCouponOrdinata = data;
      return;
    }

    this.allCouponOrdinata = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "idCoupon":
          return compare(a.id, b.id, isAsc);
        case "title":
          return compare(a.name, b.name, isAsc);
        case "description":
          return compare(a.description, b.description, isAsc);
        case "discount":
          return compare(a.discount, b.discount, isAsc);
        case "start":
          return compare(a.start, b.start, isAsc);
        case "expired":
          return compare(a.expired, b.expired, isAsc);
        case "idShop":
          return compare(a.idShop, b.idShop, isAsc);
        /*   case "idUtenti":
          return compare(a.idShop, b.idShop, isAsc); */
        default:
          return 0;
      }
    });
  }
}

function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: "dialog-coupon-manager",
  templateUrl: ".//dialog-coupon-manager.html",
})
export class DialogCouponManager {

  favoriteShop;
  codiceCoupon;
  checking: boolean = false;
  isCompleted: boolean = true;
  couponInfo: any;
  private inputSubject: Subject<string> = new Subject<string>();
  constructor(
    public dialogRef: MatDialogRef<DialogCouponManager>,
    private userService: UserService,
    private couponService: CouponService,
    private notificatioService: NotificationServiceService,
    private farmacieService: FarmacieService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {this.inputSubject.pipe(debounceTime(500)).subscribe(() => {
    this.onInput();
  });}

  onInput() {
 
    if (this.codiceCoupon && this.codiceCoupon.includes('-')) {
  
      this.checking = true;
      const idCoupon = this.codiceCoupon.split('-')[1];
      this.couponService.getCouponInfo(idCoupon).subscribe((x)=>{
        console.log(x);
        this.couponInfo = x;
        this.checking = false;
        this.isCompleted = true;
      },
    (err)=>{
      this.checking = false;
      this.isCompleted = false;
      this.couponInfo = null;
    })
     
      
    } else {
      this.couponInfo = null;
      this.isCompleted = false; 
    }
  }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    onConfirm() {
      this.userService
        .getprofilebyId(parseInt(this.codiceCoupon.split("-")[0]))
        .subscribe(
          (x) => {
            this.favoriteShop = x.idshop;
  
           
              this.couponService.useCoupon(this.codiceCoupon).subscribe(
                (x) => {
                  window.alert("coupon utilizzato correttamente");
            /*       this.invioNotifica(this.codiceCoupon.split("-")[0]); */
                  this.onNoClick();
                },
                (_) => {
                  window.alert(
                    "Il coupon digitato potrebbe essere non ancora attivo, già usato o scaduto"
                  );
                }
              );
          },
          (err) => {
            alert("attenzione, codice errato");
          }
        );
    }


}
