import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/enum/Role';
import { JwtResponse } from 'src/app/response/JwtResponse';
import { BonusService } from 'src/app/services/bonus.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-order-bonus-list',
  templateUrl: './order-bonus-list.component.html',
  styleUrls: ['./order-bonus-list.component.css']
})
export class OrderBonusListComponent implements OnInit {
  currentUser: JwtResponse;
  querySub: Subscription;
  Role = Role;
  size: number;
  cont: number = -1;
  oggettoDirezione = {
    field: "purchaseId",
    dir: "desc",
  };
  page;
  purchaseSort: string;
  searchTermTemp: string;
  searchTerm: string;
  purchaseDateTime: Date;
  ordinamento = "purchaseId";
  direzionamento = "asc";
  showButton = false;
  constructor(private bonusService: BonusService, private userService: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService,) {
  }

  ngOnInit() {
    this.currentUser = this.userService.currentUserValue;
    this.querySub = this.route.queryParams.subscribe(() => {
      this.update();
    });
  }

  checkOrderIcon(field: string, dir: string) {
    return (
      this.oggettoDirezione.field === field && this.oggettoDirezione.dir === dir
    );
  }

  checkOrderIconStatus() {
    return this.cont;
  }

  async update() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.bonusService
        .getPageBonus(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.searchTerm,
          this.purchaseDateTime
        )
        .subscribe(
          (page) => {
            this.page = page;
            this.spinner.hide();
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    } else {
      this.bonusService
        .getPageBonus(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.searchTerm,
          this.purchaseDateTime
        )
        .subscribe(
          (page) => {
            this.page = page;
            this.spinner.hide();
            if (page.content.length > 0) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    }
    return await this.page;
  }

  getOrderSession() {
    return localStorage.getItem("order_ordinamento_bonus-punti");
  }

  setOrderSession(oggettoDirezione) {
    localStorage.setItem("order_ordinamento_bonus-punti", JSON.stringify(oggettoDirezione));
  }

  ordina(ordinaField) {
    this.ordinamento = ordinaField;
    this.oggettoDirezione.field = ordinaField;
    if (this.oggettoDirezione.dir == "asc") {
      this.oggettoDirezione.dir = "desc";
    } else {
      this.oggettoDirezione.dir = "asc";
    }
    this.setOrderSession(this.oggettoDirezione);
    this.bonusService.getPageBonus
      (
        this.page.number + 1,
        15,
        this.oggettoDirezione.field,
        this.oggettoDirezione.dir,
        this.searchTerm,
        this.purchaseDateTime
      )
      .subscribe(
        (page) => {
          this.page = page;
        },
        (_) => {
          console.log("Get Order Failed");
          this.spinner.hide();
        }
      );
  }

  onSubmit() {
    this.spinner.show();
    let id = 0;
    let nextPage = 1;
    this.size = 15;

    if (this.searchTermTemp) {
      this.searchTerm = this.searchTermTemp.replace(' ', "%25")
    }
    this.searchTerm = this.searchTermTemp == '' ? undefined : this.searchTerm;
    /*    if (this.searchTermTemp != undefined && this.searchTermTemp != "") {
         this.searchTerm = "%25" + this.searchTermTemp + "%25";
       } else if (this.searchTermTemp == undefined || this.searchTermTemp == "") {
         this.searchTerm = undefined;
       } */

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;

    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.bonusService
        .getPageBonus(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.searchTerm,
          this.purchaseDateTime
        )
        .subscribe(
          (page) => {
            this.page = page;
            this.spinner.hide();
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    } else {
      this.bonusService
        .getPageBonus(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.searchTerm,
          this.purchaseDateTime
        )
        .subscribe(
          (page) => {
            this.page = page;
            this.spinner.hide();
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    }
  }

  /*   csvOrdini() {
      this.spinner.show();
      this.orderService.getOrderCsv().then((x) => {
        this.downloadFileCsv(x, "lista-ordini");
      }),
        (err) => {
          alert("Attenzione, la farmacia selezionata non ha ordini.");
          console.log(err);
        };
    } */

  onCsvButton() {
    if (this.currentUser.role == Role.Manager) {
      this.ConvertToCSV(this.page.content, ['cardNumber',
        'confirmationDate',
        'id',
        'nameAndSurname',
        'purchaseDateTime',
        'purchaseId',
        'shopName',
        'userId'
      ])
    }
    else {
      this.ConvertToCSV(this.page.content, ['cardNumber',
        'confirmationDate',
        'id',
        'nameAndSurname',
        'purchaseDateTime',
        'purchaseId',
        'userId'
      ])
    }
  }

  ConvertToCSV(objArray, headerList) {
    this.spinner.show();
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No;';
    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ';' + array[i][head];
      }
      str += line + '\r\n';
    }
    this.downloadFileCsv(str, "Bonus_ordini")
    return str;

  }

  downloadFileCsv(data: any, filename) {
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, "Book.csv");
    } else {
      const today = new Date();
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + "_" + today.getTime() + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
    this.spinner.hide();
  }

  csvAcquisti() {
    this.spinner.show();
    this.bonusService.getPurchasesCsv().then((x) => {
      this.downloadFileCsv(x, "Bonus_ordini");
      this.spinner.hide();
    }),
      (err) => {
        alert("Attenzione, non sono stati trovati acquisti con bonus punti");
        console.log(err);
        this.spinner.hide();
      };
  }
}

