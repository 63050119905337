import { Component, OnInit } from "@angular/core";
import { Coupon } from "src/app/models/Coupon";
import { CouponService } from "src/app/services/coupon.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { User } from "src/app/models/User";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Farmacia } from "src/app/models/Farmacia";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { ThrowStmt } from "@angular/compiler";
import { NgxSpinnerService } from "ngx-spinner";
import { ImageSnippet } from "../aggungi-evento/aggungi-evento.component";
import { ImageService } from "src/app/services/image.service";

@Component({
  selector: "app-compila-coupon",
  templateUrl: "./compila-coupon.component.html",
  styleUrls: ["./compila-coupon.component.css"],
})
export class CompilaCouponComponent implements OnInit {
  farmacieNameList: Array<string> = [];
  listaFarmacie: Farmacia[];
  listafarmacieOrdinata: Farmacia[];
  allUtenti: User[] = [];
  listaUtenti: User[];
  idUtenti: Array<number> = [];
  nomeShop: string;
  nomeUser: string;
  buono = new Coupon();
  citta: string;
  listautentiOrdinata: User[];
  etaDa: string;
  etaA: string;
  idFarmacie: Array<number> = [];
  id: any;
  minDate: any;
  flag: boolean = false;
  listOfImage: Array<any> = [];
  selectedFile: ImageSnippet;
  flagCoupon: boolean = false;
  sexChoice = [
    {
      sex: "M",
    },
    { sex: "F" },

    { sex: " " },
  ];
  sesso: any;
  dataDa: Date;
  dataA: Date;
  listaCitta: string[] = [];
  listaCittaNew: string[];
  codiceGiftcard;
  constructor(
    private couponService: CouponService,
    private route: ActivatedRoute,
    private router: Router,
    /*   private location: Location, */
    private userService: UserService,
    private farmacieService: FarmacieService,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    this.spinner.show();
    let city;
    this.userService.getallUsers(0).subscribe((lista) => {
      let i = 0;

      let listaOrdinata: any[] = lista;
      listaOrdinata.forEach((superelement) => {
        let flag = false;
        this.listaCitta.forEach((element) => {
          if (
            superelement.idCity &&
            element.toLowerCase() == superelement.idCity.toLowerCase()
          ) {
            flag = true;
          }
        });
        if (
          !flag &&
          superelement.idCity &&
          superelement.role != "ROLE_EMPLOYEE"
        ) {
          city = superelement.idCity.toLowerCase();
          this.listaCitta.push(city.charAt(0).toUpperCase() + city.slice(1));
        }
      });
      /*   for (var i = 0; i < listaU.length; i++) {
        if (listaU[i].idCity != null && listaU[i].role != "ROLE_EMPLOYEE")
          this.listaCitta[i] = listaU[i].idCity;
      }
      this.listaCittaNew = this.listaCitta.filter(
        (v, i) => this.listaCitta.indexOf(v) === i
      ); */
      //console.log(this.listaCittaNew);
    });
    this.minDate = new Date().toISOString().split("T")[0];
    this.farmaciaList();
    this.id = this.route.snapshot.paramMap.get("id");
    this.userService.getprofilebyId(this.id).subscribe((x) => {
      this.buono.coupon.idShop = x.shop;
      this.spinner.hide();
    });
  }
  onSubmit() {
    let date = new Date(this.dataDa);
    let dateTo = new Date(this.dataA);
    this.spinner.show();
    if (date.getTime() > dateTo.getTime()) {
      alert("Attenzione! La data di inizio è superiore a quella di fine.");
      this.spinner.hide();
    } else {
      this.buono.coupon.start = date;
      this.buono.coupon.expired = dateTo;
      this.idUtenti = [];
      this.allUtenti.forEach((element) => {
        this.idUtenti.push(parseInt(element.id));
      });
      this.buono.userIds = this.idUtenti;
      this.couponService.newCoupon(this.buono, this.listOfImage).then((res) => {
        if (res.status == 200) {
          /*   if (this.listOfImage.length > 0) {
          this.listOfImage.forEach((element, index) => {
            this.imageService
              .setAttachmentCoupon64(res.id, element)
              .subscribe((x) => {
                console.log(x);
              });
          });
        } */
          this.spinner.hide();
          /*       this.invioNotifica(this.buono.coupon.name); */
          this.router.navigate(["/crea-coupon/" + this.id]);
        } else {
          this.spinner.hide();
          alert(res.err);
        }
      });
    }
  }
  searchFarmacia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchFarm(term)))
    );
  searchFarm(term) {
    let tempFarm = [];
    this.listafarmacieOrdinata
      .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 10)
      .forEach((element) => {
        tempFarm.push(element.name);
      });
    return tempFarm;
  }
  farmaciaList() {
    this.farmacieService.getFarmacie().subscribe((data) => {
      this.listaFarmacie = data.filter((e) => e.id != 0);
      this.listaFarmacie = this.listaFarmacie.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listafarmacieOrdinata = this.listaFarmacie.slice();
      let allEmployees: Farmacia;
      allEmployees = {
        id: 0,
        active: null,
        address: null,
        gifts: null,
        idCity: null,
        idDistrict: null,
        idEmployee: null,
        idRegion: null,
        idSmoove: null,
        lat: null,
        long_: null,
        name: "Tutti",
        openingTime: null,
        phone: null,
        meters: null,
        vat: null,
        deliveryService: null,
      };
      this.listafarmacieOrdinata.unshift(allEmployees);
    });
  }
  showUtenti() {
    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_MANAGER");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
    });
  }
  svuotaLista() {
    var r = confirm("sicuro di voler svuotare la lista?");
    if (r) {
      this.farmacieNameList = [];
      this.idFarmacie = [];
      this.allUtenti = [];
    }
  }
  takeFarmacia() {
    let i = 0;
    let flag = false;
    do {
      let flagCicle = true;
      this.farmacieNameList.forEach((element) => {
        if (this.nomeShop == element) {
          flagCicle = false;
        }
      });
      if (flagCicle) {
        if (this.nomeShop == this.listafarmacieOrdinata[i].name) {
          this.idFarmacie.push(this.listafarmacieOrdinata[i].id);
          this.farmacieNameList.push(this.listafarmacieOrdinata[i].name);
          flag = true;
        }
      }
      if (
        this.nomeShop == "" ||
        this.nomeShop == " " ||
        this.nomeShop == "  " ||
        (this.nomeShop == "Tutti" && flagCicle)
      ) {
        var r = alert(
          "Attenzione, selezionando tutte le farmacie svuoterai la lista corrente e verranno inseriti tutti gli utenti"
        );
        this.idFarmacie = new Array(1);
        this.idFarmacie[0] = 0;
        this.farmacieNameList = ["Tutti"];
        this.allUtenti = [];
      }
      i++;
    } while (flag == false && i < this.listafarmacieOrdinata.length);
    console.log(this.idFarmacie);
    console.log(this.farmacieNameList);
  }

  takeUser() {
    let flag = false;
    this.allUtenti.forEach((element) => {
      if (element == this.codiceGiftcard) flag = true;
    });
    if (flag) {
      var r = alert("Attenzione, utente gia esistente nella lista");
    } else {
      this.userService.getProfileByGiftcard(this.codiceGiftcard).subscribe(
        (x) => {
          this.allUtenti.push(x);
        },
        (err) => {
          alert(
            "Attenzione! Non è stato trovato nessun utente con questa fidelity."
          );
        }
      );
    }
  }
  /*   invioNotifica(body) {
    let userListToken = [];
    this.allUtenti.forEach((element) => {
      if (element.fbtoken && element.privacykey2 && element.privacykey3)
        userListToken.push(element.fbtoken);
    });

    this.notificatioService.invionotifica(
      userListToken,
      body,
      "Nuovo coupon Eufarma" 
    );
  } */
  listOfUser() {
    /*   if (this.citta) {
      let citySplitted = this.citta.split(/[ ][(]/g);
      this.citta = citySplitted[0];
    } */
    if (parseInt(this.etaA) < parseInt(this.etaDa)) this.flag = true;
    if (this.sesso == " ") this.sesso = undefined;
    if (this.flag == false) {
      this.spinner.show();
      if (this.idFarmacie.length > 0) {
        this.idFarmacie.forEach((element) => {
          this.userService
            .getUsersByFilters(
              this.citta,
              this.sesso,
              this.etaDa,
              this.etaA,
              element
            )
            .subscribe(
              (x) => {
                x.forEach((element) => {
                  this.allUtenti.push(element);
                });
                this.idFarmacie = [];
                this.farmacieNameList = [];
                this.spinner.hide();
                /*             console.log(this.allUtenti); */
              },
              (err) => {
                window.alert("Qualcosa è andato storto, riprovare.");
                this.spinner.hide();
              }
            );
        });
      } else {
        this.userService
          .getUsersByFilters(this.citta, this.sesso, this.etaDa, this.etaA, 0)
          .subscribe(
            (x) => {
              x.forEach((element) => {
                this.allUtenti.push(element);
              });
              this.idFarmacie = [];
              this.farmacieNameList = [];
              this.spinner.hide();
            },
            (err) => {
              window.alert("Qualcosa è andato storto, riprovare.");
              this.spinner.hide();
            }
          );
      }
    } else {
      window.alert(
        "Impossibile creare la lista per campi digitati in maniera errata."
      );
      this.spinner.hide();
    }
  }
  removeSingoloUtente(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare l'utente " +
        this.allUtenti[i].name +
        " " +
        this.allUtenti[i].surname +
        " dalla lista?"
    );
    if (r == true) {
      this.allUtenti.splice(i, 1);
    }

    /*     console.log("farmacie: " + this.allFarmacie + " id: " + this.idFarmacie); */
  }
  processFile(imageInput: any) {
    let stringToCompare: string = imageInput.files[0].name;
    const file: File = imageInput.files[0];
    if (
      stringToCompare.split(".")[stringToCompare.split(".").length - 1] ==
        "jpeg" ||
      stringToCompare.split(".")[stringToCompare.split(".").length - 1] == "jpg"
    ) {
      const reader = new FileReader();

      reader.addEventListener("load", (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.listOfImage.push(file);
      });

      reader.readAsDataURL(file);
    } else {
      alert("Attenzione! puoi caricare solo immagini jpeg o jpg.");
    }
  }
  csvGiftcard(file) {
    let files = file.target.files[0];
    if (files.name.split(".")[1] == "csv") {
      file.value = "";
      this.userService.getCsvUserByGiftcardFile(files).then((x) => {
        this.downloadFile(x, "report-csv");
        /*     (\d){13} */
        var tempCsv: string[] = [];
        var csvSplit = [];
        tempCsv = x.split(/[a-z]|""|";"|"";|;|"=|:|"|\b\d\b|=/gi);
        tempCsv.forEach((element, index) => {
          if (element.includes("0000")) csvSplit.push(element);
        });

        this.userService.getUsersByGiftcards(csvSplit).subscribe((y) => {
          y.forEach((element) => {
            if (element.userFound != null)
              this.allUtenti.push(element.userFound);
          });
        });
        console.log(x);
      });
    } else {
      alert(
        "Attenzione! formato caricato non valido. Puoi caricare solo i file csv."
      );
    }
  }

  downloadFile(data: any, filename) {
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, "Book.csv");
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
  ConvertToCSV(objArray, headerList) {
    let detailproduct = ["giftcard trovate", "giftcard non trovate"];
    let products: any[];
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No;";
    for (let index in headerList) {
      row += headerList[index] + ";";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        products = array[i]["products"];

        for (let j = 0; j < products.length; j++) {
          for (let index1 in detailproduct) {
            if (products[j][detailproduct[index1]] == null) {
              products[j][detailproduct[index1]] = " ";
            }
            /*  console.log(products[j]); */
            //console.log(products[j][detailproduct[index1]]);
          }
        }
        if (head == "products") {
          products.forEach((element) => {
            element.productDescription = element.productDescription.replace(
              ";",
              "-"
            );
          });

          line += array[i][head] != null ? ";" + JSON.stringify(products) : ";";

          //console.log(JSON.stringify(products));
        } else {
          /*           array[i][head] = array[i][head] == null ? ";" : array[i][head];
          console.log(array[i][head]); */
          line += array[i][head] != null ? ";" + array[i][head] : ";";
        }
      }
      str += line + "\r\n";
    }
    return str;
  }

  removeSingolaFarmacia(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare la farmacia " +
        this.farmacieNameList[i] +
        " dalla lista?"
    );
    if (r == true) {
      this.farmacieNameList.splice(i, 1);
      this.idFarmacie.splice(i, 1);
    }

    console.log(
      "farmacie: " + this.farmacieNameList + " id: " + this.idFarmacie
    );
  }
  searchCitta = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchCity(term)))
    );
  searchCity(term) {
    let tempFarm = [];
    this.listaCitta
      .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 4)
      .forEach((element) => {
        tempFarm.push(element);
      });
    return tempFarm;
  }
}
