import { Component, OnInit } from "@angular/core";
import { Coupon, CouponUsers } from "src/app/models/Coupon";
import { CouponService } from "src/app/services/coupon.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Lightbox } from "ngx-lightbox";
import { FarmacieService } from "src/app/services/farmacie.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-info-coupon",
  templateUrl: "./info-coupon.component.html",
  styleUrls: ["./info-coupon.component.css"],
})
export class InfoCouponComponent implements OnInit {
  listOfUser: CouponUsers[] = [];
  cont;
  idCoupon: any;
  contUsed;
  _albums: Array<any> = [];
  idShop: any;
  listUtenti;
  constructor(
    private couponService: CouponService,
    private route: ActivatedRoute,
    private router: Router,
    /*   private location: Location, */
    private userService: UserService,
    private _lightbox: Lightbox,
    private farmaciaService: FarmacieService,
    private spinner: NgxSpinnerService,
  ) {
    this.cont = 0;
    this.contUsed = 0;
  }

  ngOnInit() {
 
    this.spinner.show();
    /* this.userService
    .get(this.userService.currentUserValue.account)
    .subscribe((x) => {
      this.farmaciaService.getFarmacia(x.shop).subscribe((y) => {
        this.idShop = y.id;
      });
    }); */

    this.idCoupon = this.route.snapshot.paramMap.get("id");
    this.couponService.getUsers(this.idCoupon).subscribe((x) => {
      let users = x;
/*       this.listUtenti = x; */
      users.forEach((element) => {
        if (element.user != null) {
          this.listOfUser.push(element);
      

          if (element.used == true) {
            this.contUsed++;
          }
        }
      });
    this.cont = this.listOfUser.length;
   
    this.couponService.getAttachmentCoupon(this.idCoupon).subscribe((x) => {
  
      x.forEach((element, index) => {
        const src = x[index];
        const descrizione = "allegato_" + index + ".jpg";
        const album = {
          src: src,
          descrizione: descrizione,
        };

        this._albums.push(album);
      });
    },
    (err)=>{
      alert("Qualcosa è andato storto, riprovare.")
    }); 
    this.spinner.hide();
  },
    (err)=>{
      this.spinner.hide();
      alert("Qualcosa è andato storto, riprovare.")
    });
    
 
  }
  
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  eliminaAllegato(image) {
    var domanda = confirm("Sei sicuro di voler eliminare l'allegato?");
    if (domanda == true) {
      this.couponService.deleteAttachmentCoupon(encodeURI(image.split("/")[5])).then((x)=>{
        if(x.status == 200){
        alert("Allegato rimosso con successo!");
        this.couponService
          .getAttachmentCoupon(this.idCoupon)
          .subscribe((x) => {
            this._albums = [];
            x.forEach((element, index) => {
              const src = x[index];
              const descrizione = "allegato_" + index + ".jpg";
              const album = {
                src: src,
                descrizione: descrizione,
              };

              this._albums.push(album);
            });
          });
        }
        else{alert("Attenzione! Qualcosa è andato storto.")}
      });
      
    /* 
      this.couponService.deleteAttachmentCoupon(encodeURI(image.split("/")[5])).subscribe(
        (y) => {
          alert("Allegato rimosso con successo!");
          this.couponService
            .getAttachmentCoupon(this.idCoupon)
            .subscribe((x) => {
              x.forEach((element, index) => {
                const src = x[index];
                const descrizione = "allegato_" + index + ".jpg";
                const album = {
                  src: src,
                  descrizione: descrizione,
                };

                this._albums.push(album);
              });
            });
        },
        (err) => {
          alert("Allegato rimosso con successo!");
          this.couponService
            .getAttachmentCoupon(this.idCoupon)
            .subscribe((x) => {
              this._albums = [];
              x.forEach((element, index) => {
                const src = x[index];
                const descrizione = "allegato_" + index + ".jpg";
                const album = {
                  src: src,
                  descrizione: descrizione,
                };

                this._albums.push(album);
              });
            });
        }
      ); */
    }
  }
  /* onSubmit() {
    this.couponService.newCoupon(this.buono).subscribe((res) => {
      this.router.navigate(["/crea-coupon"]);
    });
  } */
}
