import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { User } from "../../models/User";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Farmacia } from "src/app/models/Farmacia";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"]
})
export class SignupComponent implements OnInit {
  user: User;
  listaFarmacie: Farmacia[];
  name$;
  name: string;
  nome: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private FarmacieService: FarmacieService,
    private location: Location
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe(x => {
        this.nome = x.name;
      });

    this.FarmacieService.getFarmacie().subscribe(data => {
      this.listaFarmacie = data.filter(e => e.id != 0);
      this.listaFarmacie.sort(function (a, b) {
        return (a.id > b.id ? 1 : -1)
      })
    });

  }

  onSubmit() {
    this.userService.registerEmployee(this.user).subscribe(
      u => {
        this.router.navigate(["/farmacie"]);
      },
      e => { }
    );
  }

}
