import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Role } from "../../enum/Role";
import { User } from "src/app/models/User";
import { MessagingService } from "src/app/messaging.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  isInvalid: boolean;
  isLogout: boolean;
  submitted = false;
  error: boolean;
  model: any = {
    email: "",
    password: "",
    fbtoken: "",
    remembered: true,
  };
  username: string;
  returnUrl = "/";
  returnUrl2 = "/orderbyemployee";

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private msgService: MessagingService
  ) {}

  ngOnInit() {
    if (this.router.url != "silentlogin") {
      this.userService.logout();
      let params = this.route.snapshot.queryParamMap;
      this.isLogout = params.has("logout");
      this.returnUrl = params.get("returnUrl");
    } else {
      /*      this.manager = this.userService.currentUserValue; */
    }
  }

  onSubmit() {
    if (this.router.url != "/silentlogin") {
      if (this.model.username.includes("@")) {
        this.submitted = true;
        this.model.fbtoken = this.msgService.tokenUser;
        this.userService.login(this.model).subscribe((user) => {
          if (user) {
            if (user.role == Role.Customer) {
              this.userService.logout();
              this.error = true;
            } else if (user.role == Role.Employee) {
              // this.returnUrl = "/entryPoint";
              this.returnUrl = "/orderbyemployee";
            } else if (user.role == Role.Manager) {
              this.setLoginSession(user);
              // this.returnUrl = "/entryPoint";
              //console.log(this.model)
              this.returnUrl = "/dashboard";
            } /* else if (user.role == Role.Customer) {
          // this.returnUrl = "/entryPoint";
          //console.log(this.model)
          this.returnUrl = "/volantino";
        } */
            this.router.navigate([this.returnUrl]);
            //  window.location.href = this.returnUrl;
          } else {
            this.isLogout = false;
            this.isInvalid = true;
          }
        });
      } /*  else {
        let appModel = {
          phone: this.model.username,
          password: this.model.password,
          fbtoken: "",
          remembered: true,
        };

        this.submitted = true;
        this.model.fbtoken = this.msgService.tokenUser;
        this.userService.login(appModel).subscribe((user) => {
          if (user) {
            if (user.role == Role.Customer) {
              this.returnUrl = "/app-profile";

              this.router.navigate([this.returnUrl]);
          
            }
          } else {
            this.isLogout = false;
            this.isInvalid = true;
          }
        });
      } */
    } else {
      this.submitted = true;
      let loginSession = JSON.parse(this.getLoginSession());

      this.userService
        .silentLogin(this.model, loginSession)
        .subscribe((user) => {
          if (user) {
            if (user.role == Role.Employee) {
              // this.returnUrl = "/entryPoint";
              this.returnUrl = "/orderbyemployee";
            }
            this.router.navigate([this.returnUrl]);
          } else {
            this.isLogout = false;
            this.isInvalid = true;
          }
        });
    }
  }

  resetPassword() {
    this.router.navigate(["/resetpassword"]);
  }

  setLoginSession(oggetto) {
    localStorage.setItem("current_user", JSON.stringify(oggetto));
  }
  getLoginSession() {
    return localStorage.getItem("current_user");
  }
}
