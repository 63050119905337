import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Order } from "../models/Order";

import { apiUrl } from "../../environments/environment";
import { Coupon, CouponUsers, couponFormat } from "../models/Coupon";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  constructor(private http: HttpClient,
    private userService: UserService) {}

  //metodo che crea un nuovo coupon
  newCoupon(coupon: Coupon, arrayPhoto?): Promise<any> {
    var formdata = new FormData();
    arrayPhoto.forEach(element => {
      formdata.append("iconList", element)
    });
    formdata.append("couponToSaveRequest", JSON.stringify(coupon))    
  
    let expiredDate = new Date(coupon.coupon.expired);
    coupon.coupon.expired = new Date(
      Date.UTC(
        expiredDate.getFullYear(),
        expiredDate.getMonth(),
        expiredDate.getDate(),
        23,
        59,
        0
      )
    );
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };
    return fetch(`${apiUrl}/newcoupon`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
  }

  getAttachmentCoupon(idCoupon): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getattachmentcoupon/${idCoupon}`);
  }

  deleteAttachmentCoupon(filename): Promise<any> {
    var requestOptions = {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };
    const url = `${apiUrl}/deleteattachmentcoupon/${filename}`;
    return fetch(url, requestOptions);
  }

  //metodo che ottiene la lista di tutti i coupon
  getCoupon(): Observable<couponFormat[]> {
    return this.http.get<couponFormat[]>(`${apiUrl}/getcoupon`);
  }
  //metodo che crea ottiene la lista di tutti i coupon di un utente
  getCouponByIdUser(id): Observable<couponFormat[]> {
    return this.http.get<couponFormat[]>(`${apiUrl}/getcoupon/${id}`);
  }

  getStatusCoupon(promo): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getstatuscoupon/${promo}`);
  }
  //metodo che ottiene una lista di utenti dato id coupon
  getUsers(id): Observable<CouponUsers[]> {
    return this.http.get<CouponUsers[]>(`${apiUrl}/getusers/${id}`);
  }

  getCouponInfo(id): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getCouponDetail/${id}`);
  }

  getCouponListExcel(): Promise<any> {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };
  
    return fetch(`${apiUrl}/getAllCouponsForExcel`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.arrayBuffer();  // Usa arrayBuffer per ottenere il binario
      })
      .then((result) => {
        return result; 
      })
      .catch((error) => {
        console.log("error", error);
        throw error;  
      });
  }
  

  //metodo che brucia un coupon
  useCoupon(id): Observable<any> {
    return this.http.post<any>(`${apiUrl}/burncoupon/${id}`, id);
  }

  //metodo che rimuove un coupon
  removeCoupon(id): Observable<any> {
    return this.http.delete<any>(`${apiUrl}/removecoupon/${id}`, id);
  }
}
