import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Order } from "../models/Order";

import { apiUrl } from "../../environments/environment";
import { Volantino } from "../models/Volantino";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class VolantinoService {
  constructor(private http: HttpClient,
    private userService: UserService) {}

  //metodo che ottiene la lista di tutti i volantini
  getFlyerList(): Observable<any> {
    return this.http.get<any>(`${apiUrl}/flyerlist`);
  }

  getRewardFlyerList(): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getCurrentFlyerRewardInfo`);
  }
  uploadFlyer(xls, pdf, jpg, dataIn, dataOut, position): Promise<any> {
    
    var formdata = new FormData();
    var blob = new Blob([xls], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    formdata.append("elements", blob, `${position}_${dataIn}-${dataOut}.xlsx`);
    formdata.append("pdf", pdf, `${position}_${dataIn}-${dataOut}.pdf`);
    formdata.append("preview", jpg, `${position}_${dataIn}-${dataOut}.jpg`);
    
    

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/uploadflyer`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  }

  uploadRewardFlyer(xls, pdf, dataIn, dataOut): Promise<any> {

    var formdata = new FormData();
    var blob = new Blob([xls], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    formdata.append("elements", blob, `10_${dataIn}-${dataOut}.xlsx`);
    formdata.append("pdf", pdf, `10_${dataIn}-${dataOut}.pdf`);
  
    

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/uploadRewardFlyer`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  }

  //metodo che ritorna il path image di una pagina del volantino
  getFlyerPageImage(id, page): string {
    /*     var requestOptions = {
      method: "GET",
        responseType: "blob", 
    };

    return fetch(`${apiUrl}/getFlyerPageImage/${id}/${page}`, requestOptions)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error)); */
    return `${apiUrl}/getFlyerPageImage/${id}/${page}`;
  }

  //metodo che scarica il volantino corrente
  downloadFlyer(): Observable<any> {
    return this.http.get<any>(`${apiUrl}/downloadCurrentFlyer`);
  }

  //metodo che ottiene un array di prodotti in base alla posizione del click
  getProductsByClick(position, page, x, y): Observable<any> {
    return this.http.get<any>(
      `${apiUrl}/getproductsbyclick/${position}/${page}/${x}/${y}`
    );
  }

  getVolantinoByPosition(position): Observable<Volantino> {
    return this.http.get<Volantino>(
      `${apiUrl}/getCurrentFlyerInfo/${position}`
    );
  }
  getVolantinoRewardsByPosition(): Observable<Volantino> {
    return this.http.get<Volantino>(`${apiUrl}/getCurrentFlyerRewardInfo`);
  }

  deleteFlyerById(id): Observable<any> {
  
      return this.http.delete<any>(`${apiUrl}/deleteFlyersById/${id}`);
  }
}
