import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.css"]
})
export class PasswordResetComponent implements OnInit {
  error: boolean;
  numberPhone = "";
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() { }

  sendMessage(numberPhone: string) {
    if (numberPhone == null || numberPhone.length == 0) {
      this.error = true;
    } else {
      this.userService
        .forgotpassword(numberPhone)
        .subscribe(data => numberPhone);
      this.router.navigate([`/login`]);
    }
  }
}
