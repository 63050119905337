import { Component, OnInit, KeyValueDiffers } from "@angular/core";
import { Messaggio } from "src/app/models/messaggio";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { FarmacieService } from 'src/app/services/farmacie.service';
import { Role } from 'src/app/enum/Role';

interface ISimpleObj {
  id?: string;
  description: string;
}
@Component({
  selector: "app-nuovo-messaggio",
  templateUrl: "./nuovo-messaggio.component.html",
  styleUrls: ["./nuovo-messaggio.component.css"]
})
export class NuovoMessaggioComponent implements OnInit {
  messaggio: Messaggio;
  ListaMessaggi: Messaggio[];
  result: boolean;
  listaUtenti: ISimpleObj[];
  name$;
  name: string;
  recipient: ISimpleObj;
  values: boolean;
  destinatario: string;
  nameFarmacia: string;
  id2: number;
  id: string;
  idsender: string;
  idValue: boolean;
  idUser: string;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private farmacieService: FarmacieService,

  ) {
    this.messaggio = new Messaggio();

    const sub$ = this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe(profile => {
        this.idUser = profile.id;
        const subAllUser$ = this.userService
          .getallUsers(profile.idshop)
          .subscribe(users => {
            this.listaUtenti = users.map(x => {
              return {
                id: x.id,
                description: `${x.id} ${x.name} ${x.surname} ${x.email} ${x.phone}`
              };
            });
            this.listaUtenti = this.listaUtenti.filter(e => e.id != this.idUser)
            sub$.unsubscribe();
            subAllUser$.unsubscribe();
          });
      });
    let idUtente;
    this.userService.get(this.userService.currentUserValue.account).subscribe(y => {
      idUtente = y.id;
      if (this.route.snapshot.paramMap.get("id") != idUtente && this.userService.currentUserValue.role === Role.Employee && this.router.url.includes('/messaggi/new/generali')) {
        this.router.navigate(["/login"]);
      }
    })

  }

  formatter = (result: ISimpleObj) => result.description;
  formatterInput = (result: ISimpleObj) => result.description;

  ngOnInit() {
    this.values = false;
    if (this.router.url.includes('/messaggi/new/generali')) {
      this.values = true;
    }

    this.messaggio.idrecipient = +this.route.snapshot.paramMap.get("id");

    this.userService.getprofilebyId(+this.route.snapshot.paramMap.get("id")).subscribe(c => {
      this.destinatario = c.name + " " + c.surname
    })

    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe(x => {
        this.messaggio.idsender = +x.id;
      });

    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe(x => {
        this.id2 = x.idshop;
        this.farmacieService.getFarmacia(this.id2).subscribe(u => {
          this.nameFarmacia = u.name
        })
      });
    this.name = this.userService.currentUserValue.name;
    this.route.queryParamMap.subscribe(param => {
      this.messaggio.idsender = +param.get("id");
    });
  }

  onSubmit() {
    if (this.values == false) { this.aggiungiMessaggio(); }
    else if (this.values == true) { this.aggiungimessaggioGenerale() }
  }

  aggiungiMessaggio() {
    if (+this.messaggio.idrecipient == this.messaggio.idsender) {
      this.result = false;
    } else {
      //this.messaggio.idrecipient = parseInt(this.recipient.id, 10);
      this.messageService.add(this.messaggio).subscribe(mess => {
        if (!mess) {
          throw new Error();
        }
        this.invioNotifica();
        this.router.navigate([`messaggi/${this.messaggio.idsender}`]);
      });
    }
  }

  aggiungimessaggioGenerale() {
    if (parseInt(this.recipient.id, 10) === this.messaggio.idsender) {
    } else {
      this.messaggio.idrecipient = parseInt(this.recipient.id, 10);
      this.messageService.add(this.messaggio).subscribe(mess => {
        if (!mess) {
          throw new Error();
        }
        this.invioNotifica();
        this.router.navigate([`messaggi/${this.messaggio.idsender}`]);
      });
    }
  }


  searchRecipients = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term === "" || term.length < 3
          ? []
          : this.listaUtenti
            .filter(
              v =>
                v.description.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      ) //
    );

  invioNotifica() {
    let id = this.messaggio.idrecipient;
    this.userService.getprofilebyId(id).subscribe(y => {
      let token = y.fbtoken;
      this.notificatioService.invionotifica([token], this.messaggio.body, "Farmacia " + this.nameFarmacia);
    });
  }
}
