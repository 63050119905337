import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpHeaderResponse,
} from "@angular/common/http";
import { apiUrl } from "../../environments/environment";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError, tap, map, retry } from "rxjs/operators";
import { JwtResponse } from "../response/JwtResponse";
import { CookieService } from "ngx-cookie-service";
import { User } from "../models/User";
import { MessagingService } from "src/app/messaging.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Card } from "../models/Card";
import { Spedizione } from "../models/Spedizione";
import { ImageSnippet } from "../pages/aggungi-evento/aggungi-evento.component";

@Injectable({
  providedIn: "root",
})
export class ImageService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
    }),
  };
  constructor(
    private toastService: ToastrService,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private msgService: MessagingService
  ) {}

  uploadMedia64(name: string, data: string): Observable<any> {
    const file = JSON.stringify({
      name: name,
      data: btoa(data),
    });

    return this.http.post<any>(`${apiUrl}/newmediabase64`, file);
  }

  setEventAttachment(id, file: string) {
    return this.http.post(`${apiUrl}/setattachmentcalendarbase64/${id}`, file);
  }
  uploadMedia(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    return this.http.post(
      `${apiUrl}/uploadImagePietro`,
      formData
      // this.httpOptions
    );
  }
  downloadMedia(file): Observable<any> {
    const url = `${apiUrl}/mediaTemp/${file}`;
    return this.http.get<any>(url);
  }
  //metodo che setta un allegato al coupon
  setAttachmentCoupon64(idCoupon, file) {
    return this.http.post<any>(
      `${apiUrl}/setattachmentcouponbase64/${idCoupon}`,
      file
    );
  }
}
