import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CardComponent } from "./pages/card/card.component";
import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { DetailComponent } from "./pages/product-detail/detail.component";
import { AuthGuard } from "./_guards/auth.guard";
import { OrderDetailComponent } from "./pages/order-detail/order-detail.component";
import { ProductListComponent } from "./pages/product-list/product.list.component";
import { UserDetailComponent } from "./pages/user-edit/user-detail.component";
import { ProductEditComponent } from "./pages/product-edit/product-edit.component";
import { Role } from "./enum/Role";
import { FarmaciaComponent } from "./pages/farmacia/farmacia.component";
import { AggiungiFarmaciaComponent } from "src/app/pages/aggiungi-farmacia/aggiungi-farmacia.component";
import { MessagesComponent } from "./pages/messages/messages.component";
import { CalendarioComponent } from "./pages/calendario/calendario.component";
import { AggungiEventoComponent } from "./pages/aggungi-evento/aggungi-evento.component";
import { NuovoMessaggioComponent } from "src/app/pages/nuovo-messaggio/nuovo-messaggio.component";
import { OrderEmployeeComponent } from "./pages/order-employee/order-employee.component";
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { UsersComponent } from "./pages/users/users.component";
// import { FarmaciaEditComponent } from "./pages/farmacia-edit/farmacia-edit.component";
import { EventoEditComponent } from "./pages/evento-edit/evento-edit.component";
import { OrderDetailConfermedComponent } from "./pages/order-detail-confermed/order-detail-confermed.component";
import { EntryPointComponent } from "./pages/entry-point/entry-point.component";
import { SpedizioneComponent } from "./pages/spedizione/spedizione/spedizione.component";
import { OrderSpedizioneComponent } from "./pages/order-spedizione/order-spedizione.component";
import { OrderSpedizioneConfermedComponent } from "./pages/order-spedizione-confermed/order-spedizione-confermed.component";
import { ManagerDashboardComponent } from "./pages/manager-dashboard/manager-dashboard.component";
import { ManagersUsersComponent } from "./pages/managers-users/managers-users.component";
import { NewCouponComponent } from "./pages/new-coupon/new-coupon.component";
import { CompilaCouponComponent } from "./pages/compila-coupon/compila-coupon.component";
import { InfoCouponComponent } from "./pages/info-coupon/info-coupon.component";
import { CouponlistEmployeeComponent } from "./pages/couponlist-employee/couponlist-employee.component";
import { InfoCouponEmployeeComponent } from "./pages/info-coupon-employee/info-coupon-employee.component";
import { CreaSpedizioneComponent } from "./pages/crea-spedizione/crea-spedizione.component";
import { InteressatiInfoComponent } from "./pages/interessati-info/interessati-info.component";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { PrizeDetailComponent } from "./pages/prize-detail/prize-detail.component";
import { PrizeManagementComponent } from "./pages/prize-management-old/prize-management.component";
import { PrizeDetailConfermedComponent } from "./pages/prize-detail-confermed/prize-detail-confermed.component";
import { AppUserDetailComponent } from "./pages/app-user-detail/app-user-detail.component";
import { SliderNewsComponent } from "./pages/slider-news/slider-news.component";
import { SliderNewsAddComponent } from "./pages/slider-news-add/slider-news-add.component";
import { SliderNewsModifyComponent } from "./pages/slider-news-modify/slider-news-modify.component";
import { SliderNewsInfoComponent } from "./pages/slider-news-info/slider-news-info.component";
import { ProductDiscountComponent } from "./pages/product-discount/product-discount.component";
import { ProductDiscountAddingComponent } from "./pages/product-discount-adding/product-discount-adding.component";
import { ProductDiscountModifyComponent } from "./pages/product-discount-modify/product-discount-modify.component";
import { ProductDiscountInfoComponent } from "./pages/product-discount-info/product-discount-info.component";
import { AdvertisingComponent } from "./pages/advertising/advertising.component";
import { AddAdvertisingComponent } from "./pages/add-advertising/add-advertising.component";
import { AllServicesAppointmentComponent } from "./pages/all-services-appointment/all-services-appointment.component";
import { NewServicesComponent } from "./pages/new-services/new-services.component";
import { ServiceListComponent } from "./pages/service-list/service-list.component";
import { ServiceInfoComponent } from "./pages/service-info/service-info.component";
import { OrderBonusListComponent } from "./pages/order-bonus-list/order-bonus-list.component";
import { PrizeListComponent } from "./pages/prize-list/prize-list.component";

const routes: Routes = [
  {
    path: "entryPoint",
    component: EntryPointComponent,
    // canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "product/:id",
    component: DetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "product",
    component: CardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "calendario/new",
    component: AggungiEventoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "messaggi/new/:id/:id",
    component: NuovoMessaggioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "messaggi/new/utente/inviati/:id/:id",
    component: NuovoMessaggioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "messaggi/new/utente/ricevuti/:id/:id",
    component: NuovoMessaggioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "messaggi/new/generali/:id",
    component: NuovoMessaggioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "farmacia/new",
    component: AggiungiFarmaciaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "farmacie",
    component: FarmaciaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "editFarmacia/:id",
    component: AggiungiFarmaciaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "calendario/:id",
    component: CalendarioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "calendario/farmacia/:id",
    component: CalendarioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "editEvento/:id",
    component: EventoEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "login",
    component: LoginComponent,
  },

  {
    path: "silentlogin",
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  { path: "logout", component: LoginComponent },
  {
    path: "registrati",
    component: SignupComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "users/:id",
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "utentiFarmacia/:id",
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "success",
    component: SignupComponent,
  },
  { path: "resetpassword", component: PasswordResetComponent },
  {
    path: "orderConfermed/:id",
    component: OrderDetailConfermedComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "order/:id",
    component: OrderDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "orderbyemployee/:id",
    component: OrderEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "orderbyemployee",
    component: OrderEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "seller",
    redirectTo: "seller/product",
    pathMatch: "full",
  },
  {
    path: "seller/product",
    component: ProductListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "profile",
    component: UserDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee, Role.Customer] },
  },
  /* {
    path: "app-profile",
    component: AppUserDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Customer] },
  }, */
  {
    path: "profiloutente/:id",
    component: UserDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "messaggi/:id",
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "messaggi/utente/:id/:userId",
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] },
  },
  {
    path: "seller/product/:id/edit",
    component: ProductEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "seller/product/new",
    component: ProductEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "",
    redirectTo: "/orderbyemployee",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "spedizioni/:id",
    component: SpedizioneComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "order-spedizione/:id",
    component: OrderSpedizioneComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "dashboard",
    component: ManagerDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "order-spedizione-confermed/:id",
    component: OrderSpedizioneConfermedComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee][Role.Manager] },
  },
  {
    path: "lista-utenti",
    component: ManagersUsersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "crea-coupon/:id",
    component: NewCouponComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "compila-coupon/:id",
    component: CompilaCouponComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "info-coupon/:id",
    component: InfoCouponComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "info-coupon-employee/:id",
    component: InfoCouponEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "coupon-list/:id",
    component: CouponlistEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "nuovo-ordine",
    component: CreaSpedizioneComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee, Role.Manager] },
  },
  {
    path: "info-evento/:id",
    component: InteressatiInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "assets",
    component: ErrorPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "gestione-premi/:id",
    component: PrizeListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },
  },
  {
    path: "gestione-premi-old/:id",
    component: PrizeManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "premio/:id",
    component: PrizeDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] },
  },
  {
    path: "prizeConfirmed/:id",
    component: PrizeDetailConfermedComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] },


  },
  {
    path: "newsList/:id",
    component: SliderNewsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] }

  },
  {
    path: "addNews/:id",
    component: SliderNewsAddComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] }

  },
  {
    path: "modifyNews/:id",
    component: SliderNewsModifyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] }

  },
  {
    path: "infoNews/:id",
    component: SliderNewsInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] }

  },

  {
    path: "discountedProducts/:id",
    component: ProductDiscountComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "discountedProduct/info/:id/:idProduct",
    component: ProductDiscountInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "discountedProduct/add/:id",
    component: ProductDiscountAddingComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "discountedProduct/modify/:id/:idProduct",
    component: ProductDiscountModifyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "advertising/:id",
    component: AdvertisingComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] }

  },
  {
    path: "advertising/add/:id",
    component: AddAdvertisingComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager] }

  },
  {
    path: "allServices/:id",
    component: ServiceListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },

  {
    path: "allServices/add/:id",
    component: NewServicesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "allServices/info/:id/:serviceId",
    component: ServiceInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "allAppointment/:id",
    component: AllServicesAppointmentComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },
  {
    path: "orderBonus",
    component: OrderBonusListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Manager, Role.Employee] }

  },



  // },
  // { path: "**", redirectTo: "/orderbyemployee" }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes), //{onSameUrlNavigation: 'reload'}
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
