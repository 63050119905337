import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BonusService {


  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authoritation: "Bearer-token" + this.userS.currentUserValue.token,
    }),
  };

  constructor(private http: HttpClient, private userS: UserService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authoritation: "Bearer-token" + this.userS.currentUserValue.token,
      }),
    };
  }

  getPage(
    page = 1,
    size = 15,

    updateTime?: string,
    searchTerm?: string
  ): Observable<any> {

    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    return this.http
      .get(
        `${apiUrl}/getPurchases?page=${page}&size=${size}${sT}`
      )
      .pipe();
  }

  getPageBonus(
    page: number,
    size = 15,
    filtro: string,
    direzione: string,
    searchTerm?: string,
    dateSearchTerm?: Date
  ): Observable<any> {


    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    let pDT = dateSearchTerm ? "&dateSearchTerm=" + dateSearchTerm : "";
    return this.http
      .get(
        `${apiUrl}/getPurchases?page=${page}&size=${size}&purchasesSort=${filtro}&direction=${direzione}${sT}${pDT}`
      )
      .pipe();
  }

  getPurchasesCsv(): Promise<any> {
    var requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.userS.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/getPurchasesCsv`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  }
}
