import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductDiscountService } from 'src/app/services/product-discount.service';
import { NgxColorsModule } from 'ngx-colors';
import { ProductInOrder } from '../../models/ProductInOrder';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-discount-modify',
  templateUrl: './product-discount-modify.component.html',
  styleUrls: ['./product-discount-modify.component.css']
})
export class ProductDiscountModifyComponent implements OnInit {
  jpg;
  jpgFlag: boolean = false;
  idShop;
  idProduct;
  product;
  productName;
  productDescription;
  productPrice: number = 0;
  startTime;
  endTime;
  color;
  nomeProdotto: string;
  nomeProdottoVolantino: string;
  page: Array<any>;
  item: string = "";
  itemVolantino: string = "";
  pageVolantino: ProductInOrder[];
  productInfoIdList: any[] = [];
  selectedProductVolantino: any;
  selectedProductListino: any;
  _albums: Array<any> = [];
  prodottiAggiunti: any[] = [];
  lastPhoto = undefined;
  constructor(
    private discountedProductService: ProductDiscountService,
     private spinner: NgxSpinnerService,
     private route: ActivatedRoute,
     private router: Router,
     private _lightbox: Lightbox,
     private sanitizer: DomSanitizer,
     private productService: ProductService,
     ) { }

  ngOnInit() {
    this.spinner.show();
    this.idShop = this.route.snapshot.paramMap.get("id");
    this.idProduct = this.route.snapshot.paramMap.get("idProduct");
    this.discountedProductService.getDiscountedProduct(this.idProduct).subscribe((x)=>{
     this.productName = x.productName;
     this.productDescription = x.productDescription;
     this.productPrice = x.productPrice;
     this.startTime = x.discountStartDate;
      this.endTime = x.discountEndDate;
      this.jpgFlag = true;
      this.color = x.productBackgroundColor;
      this.prodottiAggiunti = x.productInDiscountSet;
      const album = {
        src: this.sanitizer.bypassSecurityTrustUrl(x.productIcon)
      }
      this._albums.push(album);
      this.spinner.hide();
    })
  }

  upload(type, fileUploaded){
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
        const file: File = fileUploaded.files[0];
       
       
         if (type =="jpg" && fileUploaded.files[0].name.split(".")[1] == type){
          this.jpg = file;
          this.lastPhoto = file;
            var reader = new FileReader();
            reader.readAsDataURL(file); 
            reader.onload = (_event) => { 
            this._albums[0].src = reader.result; 
    }
        }
        else{
          alert("Errore con l'upload dei formati.")
        }

        this.jpgFlag = this.jpg != undefined ? true : false
  
      }

  onSubmit(){
    let sottoProdotti = this.prodottiAggiunti.map((element) => {
      return {
        productInDiscountName: element.productName,
        productInDiscountDescription: element.productDescription,
        discountPrice: typeof this.productPrice === 'number' ? this.productPrice.toFixed(2) : this.productPrice
      };
    });
    let dateToCompare = new Date (this.startTime);
    let dateEndCompare = new Date (this.endTime);
    if(dateToCompare.getTime() < dateEndCompare.getTime()){
      if(this.jpg == undefined){
        this.jpg = this.lastPhoto;
      }
    this.spinner.show();
    let body = {
      id: this.idProduct,
      productName : this.productName,
      productDescription : this.productDescription,
      startDate : this.startTime ,
      endDate : this.endTime,
      productPrice : typeof this.productPrice === 'number' ? this.productPrice.toFixed(2) : this.productPrice ,
      productBackgroundColor : this.color,
      subProductInDiscountToSaveOrUpdateRequestList: sottoProdotti
    }
    this.discountedProductService.modifyDiscountedProduct(body, this.jpg).then((x)=>{
      if(x.status == 200){
        this.spinner.hide();
        alert("Prodotto scontato modificato con successo!")
        this.router.navigate(["/discountedProducts/" + this.idShop])
        }
        if(x.status == 400){
          
            this.spinner.hide();
            alert("Attenzione! Qualcosa è andato storto. Riprova")
          
        }
    })
  } 
   else{
    alert("Attenzione! Imposta una data valida")
  }
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onChangeColor(color){
    this.color = color;
    }

    rimuoviProdotto() {
      this.prodottiAggiunti.splice(this.prodottiAggiunti.length-1, 1);
    }
    
    aggiungiProdotto() {
      console.log(this.prodottiAggiunti);
      this.prodottiAggiunti.push({
        productName: '',
        productDescription: '',

      });
    
    }
    
    checkProdottiAggiunti(): boolean {
      return this.prodottiAggiunti.some(prodotto => prodotto.productName === '');
    }
    
}
