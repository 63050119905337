import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.css']
})


export class PopupMessageComponent {

}
