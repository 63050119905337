import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { User } from "src/app/models/User";
import { FarmacieService } from "src/app/services/farmacie.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-app-user-detail",
  templateUrl: "./app-user-detail.component.html",
  styleUrls: ["./app-user-detail.component.css"],
})
export class AppUserDetailComponent implements OnInit {
  user = new User();
  card;
  iduser;
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.spinner.show();
    const account = this.userService.currentUserValue.account;
    this.getUserInfo(account);
  }
  aggiornaUtente() {
    if (this.user.fiscalCode)
      this.user.fiscalCode = this.user.fiscalCode.toUpperCase();
    if (this.userService.currentUserValue.role == "ROLE_CUSTOMER") {
      if (this.user.sex == "Uomo") this.user.sex = "M";
      else if (this.user.sex == "Donna") this.user.sex = "F";
      this.user.active = true;
      this.userService.update(this.user).subscribe(
        (x) => {
          this.spinner.show();
          if (this.user.fiscalCode) {
            this.userService
              .setFiscalCode(+this.iduser, this.user.fiscalCode)
              .subscribe((x) => {
                window.alert("Utente modificato correttamente!");
              });
          } else {
            alert("Aggiornamento avvenuto con successo!");
          }

          this.spinner.hide();
        },
        (_) => {
          window.alert("La modifica non è andata a buon fine, riprova");
        }
      );
    } else {
      if (this.user.fiscalCode) {
        this.spinner.show();
        this.userService
          .setFiscalCode(
            +this.route.snapshot.paramMap.get("id"),
            this.user.fiscalCode
          )
          .subscribe((x) => {
            this.spinner.hide();
            alert("Codice fiscale aggiornato!");
          });
      }
    }
  }
  getUserInfo(email) {
    this.userService.get(email).subscribe(
      (u) => {
        this.user = u;
        this.iduser = u.id;
        if (this.user.sex == "M") this.user.sex = "Uomo";
        else if (this.user.sex == "F") this.user.sex = "Donna";
        this.userService.giftcardbyid(u.idGiftCard).subscribe((x) => {
          this.card = x.number;
        });
        this.user.fiscalCode = u.fiscalCode;
        this.spinner.hide();
      },
      (e) => {
        this.spinner.hide();
      }
    );
  }
}
