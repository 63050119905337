
import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import { OrderService } from "../../../services/order.service";
import { Order } from "../../../models/Order";
import { Router, ActivatedRoute } from "@angular/router";
import { OrderStatus } from "../../../enum/OrderStatus";
import {OrderDeliveryStatus } from "../../../enum/OrderDeliveryStatus";
import {PaymentType } from "../../../enum/PaymentType";
import { UserService } from "../../../services/user.service";
import { JwtResponse } from "../../../response/JwtResponse";
import { Subscription } from "rxjs";
import { Role } from "../../../enum/Role";
import { Location } from "@angular/common";
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { FarmacieService } from 'src/app/services/farmacie.service';
import { map } from 'rxjs/operators';
import { async } from 'q';
import { Sort } from "@angular/material/sort";
import { MessagingService } from "src/app/messaging.service";

@Component({
  selector: 'app-spedizione',
  templateUrl: './spedizione.component.html',
  styleUrls: ['./spedizione.component.css']
})
export class SpedizioneComponent implements OnInit {
  page: any;
  OrderStatus = OrderStatus;
  OrderDeliveryStatus = OrderDeliveryStatus;
  PaymentType = PaymentType;
  currentUser: JwtResponse;
  Role = Role;
  listaOrdini: Order[];
  name$;
  name: string;
  order$: Observable<Order>;
  querySub: Subscription;
  searchText: string;
  token: string;
  email: string;
  nomeFarmacia: string;
  order: Order;
  surname: string;
  ordinamento = "orderId";
  direzionamento = "asc";
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength1: number;
  size: number;
  localStorage2: Storage;
  @Input() currentPage: number;
  model: any = {
    email: "",
    password: "",
    fbtoken: this.msgService.tokenUser,
    remembered: true,
  };
  oggettoDirezione = {
    field: 'updateTime',
    dir: 'desc'
  };

  uplhandler = null;

  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private msgService: MessagingService,
  ) { }

  ngOnInit() {

    this.currentUser = this.userService.currentUserValue;
    this.querySub = this.route.queryParams.subscribe(() => {
/*       this.update(); */
    });
    this.uplhandler = setInterval(function () {
/*       this.update(); */
    }.bind(this), 500000);

  }

/*   update() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.orderService.getPageEmployee(nextPage, this.size, orderSession.field, orderSession.dir, 4,).subscribe(
        page => {
          this.page = page;
        },
        _ => {
          console.log("Get Orde Failed");
        }
      );

    } else {
      this.orderService.getPageEmployee(nextPage, this.size, orderSession.field, orderSession.dir, 4).subscribe(
        page => {
          this.page = page;
        },
        _ => {
          console.log("Get Orde Failed");
        }
      );
    }
  } */

  deleteOrdine(order: Order) {
    var domanda = confirm("Sei sicuro di voler annullare il preventivo n." + order.orderId + " ?")
    if (domanda == true) {
      this.orderService.nullPreventivo(order.orderId).subscribe(res => { })
      this.orderService.show(order.orderId).subscribe(x => {
        this.email = x.buyerEmail;

        this.userService.get(this.email).subscribe(y => {
          this.token = y.fbtoken;
          this.notificatioService.invionotifica([this.token], "Il preventivo relativo all'ordine n." + order.orderId + " è stato annullato per mancato ritiro", "Notifica preventivo ordine n." + order.orderId)
        });
       /*  this.update(); */
      });
    }
  }

  cancellaOrdine(order: Order) {
    this.orderService.cancel(order.orderId).subscribe(res => {
      if (res) {
        order.orderStatus = "2";
      }
    });
  }

  confermaOrdine(order: Order) {
    this.orderService.finish(order.orderId).subscribe(res => { });
  }

  ordineRitirato(order: Order) {
    this.orderService.orderRetired(order.orderId).subscribe(x => { });
    this.orderService.show(order.orderId).subscribe(x => {
      this.email = x.buyerEmail;

      this.userService.get(this.email).subscribe(y => {
        this.token = y.fbtoken;
        this.notificatioService.invionotifica([this.token], " L'ordine n." + order.orderId + " è stato ritirato con successo", "Notifica ordine n." + order.orderId)
      });
     /*  this.update(); */
    });
  }

  ngOnDestroy(): void {
    this.querySub.unsubscribe();
    clearInterval(this.uplhandler);
    this.uplhandler = null;
  }

  invioNotifica(order: Order) {
    this.orderService.show(order.orderId).subscribe(x => {
      this.email = x.buyerEmail;

      this.userService.get(this.email).subscribe(y => {
        this.token = y.fbtoken;
        this.notificatioService.invionotifica([this.token], "Ricordati di venire a ritirare l'ordine n." + order.orderId, "Notifica ordine n." + order.orderId)
      });
    });
  }

/*   ordina(ordinaField) {
    this.ordinamento = ordinaField;
    this.oggettoDirezione.field = ordinaField;
    if (this.oggettoDirezione.dir == 'asc') {
      this.oggettoDirezione.dir = 'desc';
    }
    else {
      this.oggettoDirezione.dir = 'asc';
    }
    this.setOrderSession(this.oggettoDirezione);
    this.orderService.getPageEmployee(this.page.number + 1, 15, this.oggettoDirezione.field, this.oggettoDirezione.dir, 4).subscribe(
      page => {
        this.page = page;
      },
      _ => {
        console.log("Get Orde Failed");
      }
    );
  } */

  checkOrderIcon(field: string, dir: string) {
    return (this.oggettoDirezione.field === field && this.oggettoDirezione.dir === dir);
  }

  getOrderSession() {
    return localStorage.getItem('order_ordinamento');
  }

  setOrderSession(oggettoDirezione) {
    localStorage.setItem('order_ordinamento', JSON.stringify(oggettoDirezione))
  }


}
