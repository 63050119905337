import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/user.service";
import { Role } from '../enum/Role';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.userService.currentUserValue;
    if (currentUser) {
      if (
        route.data.roles &&
        route.data.roles.indexOf(currentUser.role) === -1
      ) {
        console.log(currentUser.role + "fail in " + route.data.roles);
        // role not authorised so redirect to home page
        this.router.navigate(["/login"]);
        return false;
      }
      // authorised so return true
      // if (this.router.url == "/login") {
      //   this.router.navigate(["/orderbyemployee"])
      //   return true;
      // }
      return true;
    }

    console.log("Need log in");
    // not logged in so redirect to login page with the return url{queryParams: {returnUrl: state.url}}
    this.router.navigate(
      ["/login"] //{queryParams: {returnUrl: state.url}}
    );
    return false;
  }
}
