import { Component, OnInit, Input, OnDestroy, Inject } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { OrderService } from "../../services/order.service";
import { Order } from "../../models/Order";
import { Router, ActivatedRoute } from "@angular/router";
import { OrderStatus } from "../../enum/OrderStatus";
import { RewardStatusNew } from "../../enum/OrderStatus";
import { UserService } from "../../services/user.service";
import { JwtResponse } from "../../response/JwtResponse";
import { Subscription } from "rxjs";
import { Role } from "../../enum/Role";
import { Location } from "@angular/common";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { map } from "rxjs/operators";
import { async } from "q";
import { Sort } from "@angular/material/sort";
import { MessagingService } from "src/app/messaging.service";
import { isHitsEqual } from "@fullcalendar/interaction/interactions/HitDragging";
import { IMessagePushNotification } from "src/app/toaster/toaster.component";
import { Farmacia } from "src/app/models/Farmacia";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { NgxSpinnerService } from "ngx-spinner";
import { apiUrl } from "src/environments/environment";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
  from;
  to;
}

export class Prodotti {
  available: boolean;
  cartUserId: string;
  categoryType: number;
  count: number;
  id: number;
  productDescription: string;
  productIcon: string;
  productId: string;
  productName: string;
  productPrice: number;
  productStock: string;
}
@Component({
  selector: "prize-list",
  templateUrl: "./prize-list.component.html",
  styleUrls: ["./prize-list.component.css"],
})
export class PrizeListComponent implements OnInit, OnDestroy {
  order_spedizione: boolean = false;
  page: any;
  OrderStatus = OrderStatus;
  RewardStatus = RewardStatusNew;
  currentUser: JwtResponse;
  Role = Role;
  listaOrdini: Order[];
  name$;
  name: string;
  order$: Observable<Order>;
  querySub: Subscription;
  searchText: string;
  token2: string;
  token: string;
  email: string;
  nomeFarmacia: string;
  farmacia: string;
  order: Order;
  surname: string;
  ordinamento = "orderId";
  direzionamento = "asc";
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength1: number;
  size: number;
  localStorage2: Storage;
  cont: number = -1;
  @Input() currentPage: number;
  model: any = {
    email: "",
    password: "",
    fbtoken: this.msgService.tokenUser,
    remembered: true,
  };
  oggettoDirezione = {
    field: "updateTime",
    dir: "desc",
  };
  showAdditionalFilters = false;
  updateTimeDate: any;
  updateTimeDateEnd: any;
  updateTime: string; //"2019-04-10"
  updateTimeEnd: string;
  searchTerm: string; //"%25%25"
  searchTermTemp;
  statoOrdine: any; //stato ordine form
  actualStatus: number;
  listaFarmacie: Farmacia[];
  fromTime;
  toTime;
  status_ordine = [
    { stato: "Tutti i premi" },

    { stato: "Confermato" },

    { stato: "Annullato" },

    { stato: "In attesa di conferma" },

    { stato: "Premio in farmacia" },
    
    { stato: "Premio ritirato" },
  ];

  uplhandler = null;
  listOrder$: Subscription;
  idshop: number;
  urlOrdini: string;
  showButton = false;
  blob: Blob;
  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private msgService: MessagingService,
    private FarmacieService: FarmacieService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("document is hidden");
      } else {
        if (this.userService.currentUserValue.role == "ROLE_EMPLOYEE") {
          this.update();
        }
      }
    });
    if (this.router.url.includes("/gestione-premi")) {
      this.listOrder$ = this.msgService.currentMessage.subscribe(async (x) => {
        if (x) {
          if (x.title.includes("premio") || x.title.includes("Premio"))
            await this.update();
        }
      });
    }
  }

  ngOnInit() {
    this.spinner.show();
    const account = this.userService.currentUserValue.account;
    this.userService.get(account).subscribe((x) => {
      this.idshop = x.shop;
    });

    this.currentUser = this.userService.currentUserValue;
    this.querySub = this.route.queryParams.subscribe(() => {
      this.update();
    });
    /* this.uplhandler = setInterval(
      function () {
        this.update();
      }.bind(this),
      500000
    );*/
  }

  async update() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.orderService
        .getPageRewardEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.updateTimeEnd,
          this.searchTerm,
          true
        )
        .subscribe(
          (page) => {
            /*      for (var i = 0; i < page.content.length; i++) {
              if (page.content[i].paymentType == 2) {
                var totale = 0;
                var prodotti = page.content[i].products;
                for (var j = 0; j < prodotti.length; j++) {
                  if (prodotti[j].productName != "Spese di spedizione") {
                    totale += prodotti[j].productPrice * prodotti[j].count;
                  }
                }
                let gls = totale / 100;
                page.content[i].orderAmount = (
                  parseFloat(page.content[i].orderAmount) +
                  parseFloat(gls < 1 ? "0.00" : gls.toFixed(2))
                ).toFixed(2);
              }
            } */
            this.page = page;
            this.spinner.hide();
          },
          (_) => {
            console.log("Get Orde Failed");
            this.spinner.hide();
          }
        );
    } else {
      this.orderService
        .getPageRewardEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.updateTimeEnd,
          this.searchTerm,
          true
        )
        .subscribe(
          (page) => {
            /*       for (var i = 0; i < page.content.length; i++) {
              if (page.content[i].paymentType == 2) {
                var totale = 0;
                var prodotti = page.content[i].products;
                for (var j = 0; j < prodotti.length; j++) {
                  if (prodotti[j].productName != "Spese di spedizione") {
                    totale += prodotti[j].productPrice * prodotti[j].count;
                  }
                }
                let gls = totale / 100;
                page.content[i].orderAmount = (
                  parseFloat(page.content[i].orderAmount) +
                  parseFloat(gls < 1 ? "0.00" : gls.toFixed(2))
                ).toFixed(2);
              }
            } */
            this.page = page;
            this.spinner.hide();
            if (page.content.length > 0) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          },
          (_) => {
            console.log("Get Orde Failed");
            this.spinner.hide();
          }
        );
    }
    return await this.page;
  }

  deleteOrdine(order: Order) {
    var domanda = confirm(
      "Sei sicuro di voler annullare il preventivo n." + order.orderId + " ?"
    );
    if (domanda == true) {
      this.orderService
        .statusChangerRewardNew(order.orderId, "2")
        .subscribe((res) => {});
      this.orderService.showReward(order.orderId).subscribe((x) => {
        this.email = x.buyerEmail;

        this.userService.get(this.email).subscribe(async (y) => {
          this.token2 = y.fbtoken;
          this.notificatioService.invionotifica(
            [this.token2],
            "La richiesta premio relativo all'ordine n." +
              order.orderId +
              " è stata annullata, contattare la tua farmacia di riferimento per informazioni",
            "Notifica richiesta premio n." + order.orderId
          );
          await this.update();
        });
      });
    }
  }


  ordineConfermato(order: Order) {
    let orderStatus;
    this.orderService
      .statusChangerRewardNew(order.orderId, 1)
      .subscribe((x) => {});
    this.orderService.showReward(order.orderId).subscribe((x) => {
      this.email = x.buyerEmail;
      orderStatus = x.orderStatus;

      this.userService.get(this.email).subscribe((y) => {
        this.userService.get("manager@eufarma.eu").subscribe((m) => {
          this.token = y.fbtoken;
          let tokenManager = m.fbtoken;
          this.notificatioService.invionotifica(
            [this.token, tokenManager],
            " Il premio n." + order.orderId + " è stato confermato con successo.",
            "Richiesta Premio n." + order.orderId
          );
        });
        this.update();
      });
    });
  }

  ordineSpedito(order: Order) {
    let orderStatus;
    this.orderService
      .statusChangerRewardNew(order.orderId, 4)
      .subscribe((x) => {});
    this.orderService.showReward(order.orderId).subscribe((x) => {
      this.email = x.buyerEmail;
      orderStatus = x.orderStatus;

      this.userService.get(this.email).subscribe((y) => {
        this.userService.get("manager@eufarma.eu").subscribe((m) => {
          this.token = y.fbtoken;
          let tokenManager = m.fbtoken;
          this.notificatioService.invionotifica(
            [this.token, tokenManager],
            " Il premio n." + order.orderId + " è stato spedito alla farmacia con successo.",
            "Richiesta Premio n." + order.orderId
          );
        });
        this.update();
      });
    });
  }

  ordineRitirato(order: any, farmacista?) {
    const dialogRef = this.dialog.open(CheckPrizeCode, {
  
      width: '300px',
      height: '350px',
      data: {
        order: !farmacista ? order : null,
        code: !farmacista ? order.note : ""
      },
    });

    dialogRef.beforeClosed().subscribe((result) => {
      if (result.code != undefined && result.code != '') {
        let code = result.code;
        this.orderService
        .rewardCollected(order.orderId, 5, code)
        .subscribe((x) => {
          if(x){
          this.orderService.showReward(order.orderId).subscribe((x) => {
            this.email = x.buyerEmail;
        
      
            this.userService.get(this.email).subscribe((y) => {
              this.userService.get("manager@eufarma.eu").subscribe((m) => {
                this.token = y.fbtoken;
                let tokenManager = m.fbtoken;
                this.notificatioService.invionotifica(
                  [this.token, tokenManager],
                  " Il premio n." + order.orderId + " è stato ritirato in farmacia con successo.",
                  "Richiesta Premio n." + order.orderId
                );
              });
              this.update();
            });
          });
        }
        else{
          alert("Attenzione, è stato inserito un codice errato.");
        }
        },
        (err)=>{
          alert("Qualcosa è andato storto, riprova");
        });
    
      }
    });

    
  }

  ngOnDestroy(): void {
    this.querySub.unsubscribe();
    this.listOrder$.unsubscribe();
    clearInterval(this.uplhandler);
    this.uplhandler = null;
  }

  invioNotifica(order: Order) {
    this.orderService.show(order.orderId).subscribe((x) => {
      this.email = x.buyerEmail;

      this.userService.get(this.email).subscribe((y) => {
        this.token = y.fbtoken;
        this.notificatioService.invionotifica(
          [this.token],
          "Ricordati di venire a ritirare il premio n." + order.orderId,
          "Richiesta Premio n." + order.orderId
        );
      });
    });
  }

  ordina(ordinaField) {
    this.ordinamento = ordinaField;
    this.oggettoDirezione.field = ordinaField;
    if (this.oggettoDirezione.dir == "asc") {
      this.oggettoDirezione.dir = "desc";
    } else {
      this.oggettoDirezione.dir = "asc";
    }
    this.setOrderSession(this.oggettoDirezione);
    this.orderService
      .getPageRewardEmployee(
        this.page.number + 1,
        15,
        this.oggettoDirezione.field,
        this.oggettoDirezione.dir,
        this.actualStatus,
        this.updateTime,
        this.updateTimeEnd,
        this.searchTerm,
        true
      )
      .subscribe(
        (page) => {
          this.page = page;
        },
        (_) => {
          console.log("Get Order Failed");
        }
      );
  }

  checkOrderIcon(field: string, dir: string) {
    return (
      this.oggettoDirezione.field === field && this.oggettoDirezione.dir === dir
    );
  }

  checkOrderIconStatus() {
    return this.cont;
  }

  getOrderSession() {
    return localStorage.getItem("order_ordinamento");
  }

  setOrderSession(oggettoDirezione) {
    localStorage.setItem("order_ordinamento", JSON.stringify(oggettoDirezione));
  }

  /*   compilaSpedizione(order) {
    this.order_spedizione = true;

    ("order/:orderid");
    this.router.navigateByUrl(`order/${order}`);
  } */
  onSubmit() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;
    if (this.searchTermTemp != undefined && this.searchTermTemp != "") {
      this.searchTerm = "%25" + this.searchTermTemp + "%25";
    } else if (this.searchTermTemp == undefined || this.searchTermTemp == "") {
      this.searchTerm = undefined;
    }

    if (this.updateTimeDate != undefined && this.updateTimeDate != "") {
      this.updateTime = this.updateTimeDate.toString();
    } else if (this.updateTimeDate == undefined || this.updateTimeDate == "") {
      this.updateTime = undefined;
    }
    
    if (this.updateTimeDateEnd != undefined && this.updateTimeDateEnd != "") {
      this.updateTimeEnd = this.updateTimeDateEnd.toString();
    } else if (this.updateTimeDateEnd == undefined || this.updateTimeDateEnd == "") {
      this.updateTimeEnd = undefined;
    }

    if (this.statoOrdine == undefined || typeof this.statoOrdine != "string") {
      this.statoOrdine = undefined;
    }

    switch (this.statoOrdine) {
      case "Tutti i premi":
        this.actualStatus = -1;
        break;

      case "Confermato":
        this.actualStatus = 1;
        break;
      case "Annullato":
        this.actualStatus = 2;
        break;
      case "In attesa di conferma":
        this.actualStatus = 3;
        break;
      case "Premio in farmacia":
        this.actualStatus = 4;
        break;
      case "Premio ritirato":
        this.actualStatus = 5;
        break;
 
    }

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;

    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.orderService
        .getPageRewardEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.updateTimeEnd,
          this.searchTerm,
          true
        )
        .subscribe(
          (page) => {
            this.page = page;
          },
          (_) => {
            console.log("Get Order Failed");
          }
        );
    } else {
      this.orderService
        .getPageRewardEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.updateTimeEnd,
          this.searchTerm,
          true
        )
        .subscribe(
          (page) => {
            this.page = page;
          },
          (_) => {
            console.log("Get Order Failed");
          }
        );
    }
  }


  openFileInput() {
    const fileInput = document.getElementById('file');
    if (fileInput) {
        fileInput.click();
    }
}
  
  upload(event: any){
    let fileToUpload = event.target.files[0] as File;
    this.orderService.uploadOrderExcel(fileToUpload).then((x)=>{
      if(x.status == 200){
        if(x.body && x.body.includes("Nessun Ordine premio aggiunto!")) alert("Non è stato possibile aggiungere alcun ordine premio");
        else{ alert("Ordine premio caricato con successo");
         this.update();
        }
      }
     else{
      alert("Qualcosa è andato storto, riprova");
    }
      });
    }

  pdfOrdini(idOrder) {
    this.orderService.getPdfOrderReward(idOrder).subscribe(
      (x) => {
        var newBlob = new Blob([x], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement("a");
        link.href = data;
        let today = new Date();
        link.download = "lista_premi_" + today.getTime() + ".pdf";

        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (e) => {
        alert("Qualcosa è andato storto, riprova");
      }
    );

    
  }
}

@Component({
  selector: "dialog-Reward",
  templateUrl: "./checkPrizeCode.html",
})
export class CheckPrizeCode {

  constructor(
    public dialogRef: MatDialogRef<CheckPrizeCode>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }
}
