import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiUrl } from "../../environments/environment";
import { Farmacia } from "src/app/models/Farmacia";

@Injectable({
  providedIn: "root",
})
export class FarmacieService {
  constructor(private http: HttpClient) {}
  private farma = `${apiUrl}/getallshops`;
  private farma1 = `${apiUrl}/addnewshop`;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authoritation: "Bearer-token" + localStorage.getItem("token"),
    }),
  };

  // metodo che ritorna tutte la lista di farmacie
  getFarmacie(): Observable<any> {
    return this.http.get(this.farma);
  }
  //metodo che aggiungi una nuova farmacia
  addFarmacia(farmacia: Farmacia): Observable<Farmacia> {
    return this.http.post<Farmacia>(this.farma1, farmacia, this.httpOptions);
  }
  // metodo che modifica una farmacia
  updateFarmacia(farmacia: Farmacia): Observable<Farmacia> {
    const url = `${apiUrl}/modifyshop`;
    return this.http.put<Farmacia>(url, farmacia);
  }
  //metodo che ritorna i dettagli di un singola farmacia
  getFarmacia(id: number): Observable<Farmacia> {
    const url = `${apiUrl}/getshop/${id}`;
    return this.http.get<Farmacia>(url);
  }
  getTelegramCode(): Observable<any> {
    const url = `${apiUrl}/generateTelegramCode`;
    return this.http.get<any>(url);
  }
  saveAutomaticMessage(message): Observable<any> {
    const url = `${apiUrl}/automaticMessages/saveOrUpdate/true`;
    return this.http.post<any>(url, message);
  }
  getAllAutomaticMessages(): Observable<any> {
    const url = `${apiUrl}/automaticMessages/get`;
    return this.http.get<any>(url);
  }
  deleteSingleAutomaticMessage(idMessage): Observable<any> {
    const url = `${apiUrl}/automaticMessages/delete/${idMessage}`;
    return this.http.delete<any>(url);
  }
}
