import { Time } from "@angular/common";
import { Farmacia } from "./Farmacia";

export class EventoDettagli {
  //modifcato file di configurazione
  //ngb-date-struct.d.ts e file ngb-date.d.ts per aggiungere le variabili della chiamata http del database
  id: number;
  note: string;
  time: string;
  title: string;
  idshop: string;
  dayOfWeek: null;
  dayOfYear: null;
  lat: number;
  long: number;
  day: string;
  month: string;
  year: string;
  duration: number;
  kind: string;
  quarter: null;
  weekOfYear: null;
  shop: Farmacia;
  webUrl?: string;
}
