import { Component, OnInit } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { UserService } from "src/app/services/user.service";
import { Location } from "@angular/common";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { FarmacieService } from 'src/app/services/farmacie.service';
import { Role } from 'src/app/enum/Role';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-evento-edit",
  templateUrl: "./evento-edit.component.html",
  styleUrls: ["./evento-edit.component.css"]
})
export class EventoEditComponent implements OnInit {
  name$;
  name: string;
  evento: EventoDettagli;
  dataObj2: NgbDate;
  id2: number;
  nomeFarmacia: any;
  idFarmacia: number;
  constructor(
    private calendarioService: CalendarioService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private farmacieService: FarmacieService,
    private spinner: NgxSpinnerService,


  ) {
    this.userService.get(this.userService.currentUserValue.account).subscribe(z => {
      this.idFarmacia = z.idshop;
    });
    const id = +this.route.snapshot.paramMap.get("id");
    this.calendarioService.getEvent(id).subscribe(event => {
      this.evento = event;
      this.dataObj = new NgbDate(
        parseInt(this.evento.year, 10),
        parseInt(this.evento.month, 10),
        parseInt(this.evento.day, 10)
      );
    });
  }

  set dataObj(obj: NgbDateStruct) {
    if (obj.day < 10 && obj.day > 0) {
      this.evento.day = "0" + obj.day.toString();
    } else {
      this.evento.day = obj.day.toString();
    }
    if (obj.month < 10 && obj.month > 0) {
      this.evento.month = "0" + obj.month.toString();
    } else {
      this.evento.month = obj.month.toString();
    }
    this.evento.year = obj.year.toString();
    this.dataObj2 = new NgbDate(obj.year, obj.month, obj.day);
  }

  get dataObj(): NgbDateStruct {
    return this.dataObj2;
  }

  ngOnInit() {

  }

  onSubmit() {
    this.modificaEvento();
  }
  modificaEvento() {
    this.spinner.show();
    this.evento.year = this.dataObj2.year.toString();
    this.evento.month = parseInt(this.dataObj2.month.toString()) < 10 ? "0" + this.dataObj2.month.toString() : this.dataObj2.month.toString();
    this.evento.day = this.dataObj2.day.toString();

    if (this.userService.currentUserValue.role === Role.Manager) {
      this.calendarioService.updateEvent(this.evento).subscribe((x) => {
        this.spinner.hide();
        alert("Evento modificato!");
        this.router.navigate([`calendario/${this.idFarmacia}`]);
      },
        (err) => {
          this.spinner.hide()
          alert("Attenzione! Qualcosa è andato storto. Riprova")
        });
      /*this.calendarioService.updateEvent(this.evento).subscribe(
        event => {
          this.userService.getallUsers(+this.evento.idshop).subscribe(x => {
            let usersListPromise = [];
            for (let k = 0; k < x.length; k++) {
              let userEmail = x[k].email;
              let privacy = x[k].privacykey2;
              if (privacy === true) {
                usersListPromise.push(this.userService.get(userEmail).toPromise());
              }
            }
            Promise.all(usersListPromise).then(usersList => {
              let usersListToken = [];
              for (let i = 0; i < usersList.length; i++) {
                if (usersList[i].fbtoken) {
                  usersListToken.push(usersList[i].fbtoken);
                }
              }
              if (this.dataObj.day < 10 && this.dataObj.day > 0) {
                this.evento.day = "0" + this.dataObj.day.toString();
              } else {
                this.evento.day = this.dataObj.day.toString();
              }
              if (+this.dataObj.month < 10 && +this.dataObj.month > 0) {
                this.evento.month = "0" + this.dataObj.month.toString();
              } else {
                this.evento.month = this.dataObj.month.toString();
              }
              this.evento.year = this.dataObj.year.toString();
              this.notificatioService.invionotifica(usersListToken, "Farmacia " + this.evento.shop.name + " ha modificato l'evento in questa data", "L'evento del " + this.evento.day + "-" + this.evento.month + "-" + this.evento.year + " è stato modificato");
            }).then(() => {
              this.router.navigate([`calendario/${this.idFarmacia}`]);
            });
          }),
            err => { }
        });*/
    }
    else {
      this.calendarioService.updateEvent(this.evento).subscribe(
        event => {
          /*   this.userService.getallUsers(+this.evento.idshop).subscribe(x => { */
          /*     let usersListPromise = [];
              for (let k = 0; k < x.length; k++) {
                let userEmail = x[k].email;
                let privacy = x[k].privacykey2;
                if (privacy === true) {
                  usersListPromise.push(this.userService.get(userEmail).toPromise());
                }
              } */
          /*   Promise.all(usersListPromise).then(usersList => {
              let usersListToken = [];
              for (let i = 0; i < usersList.length; i++) {
                if (usersList[i].fbtoken) {
                  usersListToken.push(usersList[i].fbtoken);
                }
              }
              if (this.dataObj.day < 10 && this.dataObj.day > 0) {
                this.evento.day = "0" + this.dataObj.day.toString();
              } else {
                this.evento.day = this.dataObj.day.toString();
              }
              if (+this.dataObj.month < 10 && +this.dataObj.month > 0) {
                this.evento.month = "0" + this.dataObj.month.toString();
              } else {
                this.evento.month = this.dataObj.month.toString();
              }
              this.evento.year = this.dataObj.year.toString();
              this.notificatioService.invionotifica(usersListToken, "Farmacia " + this.evento.shop.name + " ha modificato l'evento in questa data", "L'evento del " + this.evento.day + "-" + this.evento.month + "-" + this.evento.year + " è stato modificato");
            }).then(() => { */
          this.spinner.hide();
          alert("Evento modificato!")
          this.router.navigate([`calendario/${this.idFarmacia}`]);
          /*   }); */
          /*   }),
              err => { } */
        },
        (err) => {
          this.spinner.hide()
          alert("Attenzione! Qualcosa è andato storto. Riprova")
        });
    }

  }

}
