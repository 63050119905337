  import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "src/app/models/User";
import { Location } from "@angular/common";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Role } from "src/app/enum/Role";
import { Sort } from "@angular/material/sort";
import { FarmacieService } from "src/app/services/farmacie.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-managers-users",
  templateUrl: "./managers-users.component.html",
  styleUrls: ["./managers-users.component.css"],
})
export class ManagersUsersComponent implements OnInit {
  nome: string;
  parolaChiaveNome: string;
  parolaChiaveCognome;
  parolaChiaveFarmacia: string;
  nomeUtente: string;
  cognomeUtente: string;
  sessoUtente: string;
  indirizzoUtente: string;
  email: string;
  telefono: string;
  dataNascita: string;
  listaUtenti: User[];
  idProva: string;
  user: User;
  currentUser: JwtResponse;
  Role = Role;
  listautentiOrdinata: User[];
  showUtenti: boolean;
  idShop;

  constructor(
    private FarmacieService: FarmacieService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private spinner: NgxSpinnerService
  ) {
    let idUser; 

    this.currentUser = this.userService.currentUserValue;
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
        idUser = x.id;
        /*    if (
          this.route.snapshot.paramMap.get("id") != idUser &&
          this.userService.currentUserValue.role == Role.Manager
        ) {
          this.router.navigate(["/login"]);
        } */
      });
  }

  ngOnInit() {
    this.spinner.show();

    this.showUsers();
    this.idShop = this.route.snapshot.paramMap.get("id");
  }

  creaCSV() {
    this.downloadFile(this.listautentiOrdinata, "lista-utenti");
  }

  dettaglioUtente(utente: User) {
    this.showUtenti = true;
    //console.log(utente.id);
    ("profiloutente/:id");
    window.open(`profiloutente/${utente.id}`);
  }

  downloadFile(data, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "giftCard",
      "id",
      "name",
      "surname",
      "sex",
      "born",
      "phone",
      "email",
      "vat",
      "idCity",
      "address",
      "idshop",
      "shopName",
      "active",
      "privacykey1",
      "privacykey2",
      "privacykey3",
    ]);
    console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No;";

    for (let index in headerList) {
      row += headerList[index] + ";";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += ";" + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }
  showUsers() {
    this.showUtenti = false;
    /*    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_MANAGER");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();

      this.listautentiOrdinata.forEach((element) => {
        this.FarmacieService.getFarmacia(element.shop).subscribe((x) => {
          element.nomeShop = x.name;
        });
      });
    }); */
    this.userService.getCsvFile().subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_MANAGER");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
      /*   this.downloadFile(users, "lista-utenti"); */
      this.spinner.hide();
    });
  }

  ordinalistaUtenti(sort: Sort) {
    const data = this.listaUtenti.slice();
    if (!sort.active || sort.direction === "") {
      this.listautentiOrdinata = data;
      return;
    }

    this.listautentiOrdinata = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compare(a.id, b.id, isAsc);
        case "email":
          return compare(a.email, b.email, isAsc);
        case "name":
          return compare(a.name, b.name, isAsc);
        case "surname":
          return compare(a.surname, b.surname, isAsc);
        case "phone":
          return compare(a.phone, b.phone, isAsc);
        case "address":
          return compare(a.address, b.address, isAsc);
        case "nomeShop":
          return compare(a.nomeShop, b.nomeShop, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
