import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductDiscountService } from 'src/app/services/product-discount.service';
import { NgxColorsModule } from 'ngx-colors';

@Component({
  selector: 'app-product-discount-info',
  templateUrl: './product-discount-info.component.html',
  styleUrls: ['./product-discount-info.component.css']
})
export class ProductDiscountInfoComponent implements OnInit {
  jpg;
  jpgFlag: boolean = false;
  idShop;
  idProduct;
  product;
  productName;
  productDescription;
  productPrice;
  startTime;
  endTime;
  color;
  productIdList: any[];
  _albums: Array<any> = [];
  lastPhoto = undefined;
  prodottiAggiunti = [];
  constructor(
    private discountedProductService: ProductDiscountService,
     private spinner: NgxSpinnerService,
     private route: ActivatedRoute,
     private router: Router,
     private _lightbox: Lightbox,
     private sanitizer: DomSanitizer,
     ) { }

  ngOnInit() {
    this.spinner.show();
    this.idShop = this.route.snapshot.paramMap.get("id");
    this.idProduct = this.route.snapshot.paramMap.get("idProduct");
    this.discountedProductService.getDiscountedProduct(this.idProduct).subscribe((x)=>{
     this.productName = x.productName;
     this.productDescription = x.productDescription;
     this.productPrice = x.productPrice;
     this.startTime = x.discountStartDate;
      this.endTime = x.discountEndDate;
      this.jpgFlag = true;
      this.color = x.productBackgroundColor;
      this.prodottiAggiunti = x.productInDiscountSet
      const album = {
        src: this.sanitizer.bypassSecurityTrustUrl(x.productIcon)
      }
      this._albums.push(album);
      this.spinner.hide();
    })
  }


  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }



}
