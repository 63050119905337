import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { SliderNewsService } from 'src/app/services/slider-news.service';


@Component({
  selector: 'app-slider-news',
  templateUrl: './slider-news.component.html',
  styleUrls: ['./slider-news.component.css']
})
export class SliderNewsComponent implements OnInit {
  idShop;
  newsList;
  newsListOrdinata;
  pageSize = 5;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private newsService: SliderNewsService) { }

  ngOnInit() {
    this.getNewsList();
    this.idShop = this.route.snapshot.paramMap.get("id");
  }

  getNewsList(){
    this.spinner.show();
    this.newsService.getNews().subscribe((x)=>{
      this.newsList = x;
      this.pageLength = x.length;
      this.newsListOrdinata = this.newsList;
      this.spinner.hide();
    },
    (err)=>{
      this.spinner.hide();
      alert("Qualcosa è andato storto!");
    })
  }

  deleteNews(news){
   let r = confirm("Sei sicuro di voler eliminare questa news?");
    if (r){
      this.spinner.show();
      this.newsService.removeNews(news.id).subscribe((x)=>{
        this.spinner.hide();
        alert("Rimozione avvenuta con successo");
        this.getNewsList();
        
      },
      (err)=>{
        if(err.text.includes("cancellata!")){
          this.spinner.hide();
          alert("Rimozione avvenuta con successo");
          this.getNewsList();
        
        }
      })
    }
  }

  navigateTo(routTo) {
      this.router.navigateByUrl(routTo);
    
  }

  truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };

  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.newsListOrdinata = this.newsList.slice(offset).slice(0, event.pageSize);
  }
}
