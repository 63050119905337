import { Injectable } from "@angular/core";
import { apiUrl } from "src/environments/environment";
import { Observable } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpResponseBase,
} from "@angular/common/http";
import { Messaggio } from "src/app/models/messaggio";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  private deletemessage = `${apiUrl}/deletemessagesbyid`;
  private newmessage = `${apiUrl}/newmessage`;
  private getmessageRicevuti = `${apiUrl}/getallmessagesbyrecipit`;
  private getmessageInviati = `${apiUrl}/getallmessagesbysender`;
  private setStato = `${apiUrl}/setmessageread`;

  constructor(
    private http: HttpClient,
    private message: MessageService,
    private route: ActivatedRoute
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      observe: "response",
    }),
  };
  //metodo che permette di creare un nuovo messaggio
  add(messaggio: Messaggio): Observable<Messaggio> {
    return this.http.post<Messaggio>(
      this.newmessage,
      messaggio,
      this.httpOptions
    );
  }
  getMessageRicevutiEInviati(idrecipient: number): Observable<any> {
    return this.http.get(
      `${apiUrl}/getmessagesinfo/${idrecipient}`,
      this.httpOptions
    );
  }

  // metodo che ritorna la lista dei messaggi ricevuti dal singolo utente
  getMessageRicevuti(idrecipient: string): Observable<any> {
    return this.http.get(
      `${this.getmessageRicevuti}/${idrecipient}`,
      this.httpOptions
    );
  }
  // metodo che ritorna la lista dei messaggi inviati dal singolo utente
  getMessageInviati(idrecipient: string): Observable<any> {
    /**interval(10000).pipe(flatMap(() => this.http
      .get(`${this.getmessageInviati}/${idrecipient}`, this.httpOptions))); */
    return this.http.get(
      `${this.getmessageInviati}/${idrecipient}`,
      this.httpOptions
    );
  }
  // metodod che elimina un messaggio
  delete(messaggio: Messaggio, id: number): Observable<any> {
    return this.http.get(`${this.deletemessage}/${messaggio.id}/${id}`, {
      observe: "response",
    });
  }

  setState(messaggio: Messaggio): Observable<any> {
    return this.http.get(`${this.setStato}/${messaggio.id}`, this.httpOptions);
  }

  getAllUnReadMessages(): Observable<any>{
    return this.http.get(`${apiUrl}/getshopsListWithUnreadMessages`, this.httpOptions)
  }
}
