import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingService {

  constructor(private http: HttpClient,
    private userService: UserService) { }




    getAdvertising(): Observable<any>{
    return this.http.get<any>(`${apiUrl}/getAllAdvertising`);
}

  newAdv(adv: any, durata, name): Promise<any> {
    var formdata = new FormData();
    var currentDate = new Date();
    var timeStamp = currentDate.getTime();
   
    formdata.append("advertisingsToSave", adv, `${durata}_${name}-${timeStamp}.${adv.name.split(".")[adv.name.split(".").length-1]}`

    )    
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };
    return fetch(`${apiUrl}/advertising/new`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
  }

  removeAdvertising(id): Observable<any> {
      return this.http.delete<any>(`${apiUrl}/advertising/delete/${id}`);

  }
}