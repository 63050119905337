import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User } from "../../models/User";
import { Router, ActivatedRoute } from "@angular/router";
import { Role } from "../../enum/Role";
import { formatDate, Location } from "@angular/common";
import { OrderService, IProcutList } from "../../services/order.service";
import { Spedizione } from "src/app/models/Spedizione";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { apiUrl } from "src/environments/environment.prod";
import { DomSanitizer } from "@angular/platform-browser";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { FarmacieService } from "src/app/services/farmacie.service";

@Component({
  selector: "app-order-spedizione-confermed",
  templateUrl: "./order-spedizione-confermed.component.html",
  styleUrls: ["./order-spedizione-confermed.component.css"],
})
export class OrderSpedizioneConfermedComponent implements OnInit {
  nome: string;
  user = new Spedizione();
  name$;
  name: string;
  passwordConfirm: string;
  valueUtente: boolean;
  email: string;
  card: string;
  listaUtenti: Spedizione[];
  listautentiOrdinata: Spedizione[];
  importoContrassegno: string;
  currentDate = new Date();
  users: Array<Spedizione> = [];
  pdf: any;
  urlPdf: any;
  codiceFiscale: string;
  formatoColli = [
    {
      tipo: "piccolo",
    },
    { tipo: "medio" },
    { tipo: "personalizzato" },
  ];
  dalle_alle = [
    {
      orario: "13",
    },
    { orario: "14" },
    { orario: "15" },
  ];
  misureColli: string;
  productList: ProductInOrder[];
  numeroColli: string;
  altezzaColli: string;
  larghezzaColli: string;
  profonditaColli: string;
  xincasso;
  xresacassegno;
  confirmedFlag: boolean = false;
  importo: any;
  token: string;
  giftCode: any;
  telBuyer: any;
  url: string;
  tipoCorriere: any;
  showCorriere: number;
  seeButton: boolean = false;
  paymentValore: string;
  userId;
  constructor(
    private farmaciaService: FarmacieService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private sanitizer: DomSanitizer,
    private notificatioService: NotificationServiceService
  ) {}

  ngOnInit() {
    const orderId = this.route.snapshot.paramMap.get("id");

    const account = this.userService.currentUserValue.account;
    this.userService.get(account).subscribe((x) => {
      let idUser = x.shop;
      this.farmaciaService.getFarmacia(idUser).subscribe((y) => {
        this.tipoCorriere = y.deliveryService;
        if (this.tipoCorriere == "GLS") {
          this.showCorriere = 1;
          this.userService
            .getPdfto64(this.user.deliveryId, this.user.sede)
            .subscribe(
              (pdf64) => {},
              (err) => {
                if (err.text) {
                  if (err.text.includes("labelservice.gls-italy.com")) {
                  }
                } else {
                  this.userService.sendPdfAlert(orderId).subscribe((x) => {});
                }
              }
            );
        } else if (
          this.tipoCorriere == "Altro" ||
          this.tipoCorriere == "Altro corriere"
        ) {
          this.showCorriere = 2;
        } else {
          this.showCorriere = 3;
        }
      });
    });

    this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        this.codiceFiscale = x.fiscalCode;
        this.importo = x.orderAmount;
        this.telBuyer = x.buyerPhone;
        this.userId = x.userId;
        let paymentType: number = parseInt(x.paymentType);
        switch (paymentType) {
          case null:
            window.alert("Attenzione, errore con i pagamenti");
            this.router.navigate(["/orderbyemployee"]);
            break;
          case 0:
            this.paymentValore = "PayPal";
            break;
          case 1:
            this.paymentValore = "Carta di credito";
            break;
          case 2:
            this.paymentValore = "Contrassegno";
            break;
        }
        this.userService.getProfile(this.telBuyer).subscribe((x) => {
          this.userService.giftcardbyid(x.idGiftCard).subscribe((x) => {
            this.giftCode = x.number;
          });
        });
      });
    /*  this.urlPdf = `${apiUrl}/delivery/pdfconsegna/${this.route.snapshot.paramMap.get(
      "id"
    )}`;
    this.pdf = this.sanitizer.bypassSecurityTrustUrl(this.urlPdf); */
    this.getDelivery();

    /*  if (this.user.importocontrassegno == "1") {
      this.importoContrassegno = "si";
    } else {
      this.importoContrassegno = "no";
    } */
  }

  /* onSubmit() {
    switch (this.misureColli) {
      case "piccolo":
        this.user.misureColli = "1,11,111,1";
        break;
      case "medio":
        this.user.misureColli = "1,22,222,1";
        break;
      case "personalizzato":
        this.user.misureColli =
          this.numeroColli +
          "," +
          this.altezzaColli +
          "," +
          this.larghezzaColli +
          "," +
          this.profonditaColli;
    }
    if (this.importoContrassegno == true) {
      this.user.importocontrassegno = "1";
      this.user.resaCAssegno = this.xresacassegno;
      this.user.modalitaIncasso = this.xincasso;
    }
      let hh = this.currentDate.getHours();
      if(hh < 13){
       let day:any = this.currentDate.getDate();
        let month:any = this.currentDate.getMonth() + 1;
        let year:any = this.currentDate.getFullYear();
    this.user.dataRitiro = day.toString()+"/"+month.toString()+"/"+year.toString();
      }
      else{
        let day:any = this.currentDate.getDate()+1;
        let month:any = this.currentDate.getMonth() + 1;
        let year:any = this.currentDate.getFullYear();
    this.user.dataRitiro = day.toString()+"/"+month.toString()+"/"+year.toString();
      }
    // if (this.user.password == this.passwordConfirm) {
this.users.push(this.user);
    this.userService.updateSpedizione(this.users).subscribe((u) => {
      const sub2$ = this.orderService
      .sendDelivery(this.route.snapshot.paramMap.get("id"))
      .subscribe(
        (res) => {
          this.router.navigate(["/orderbyemployee"]);
        },
        () => {
         
          sub2$.unsubscribe();
        },
        () => {
       
          sub2$.unsubscribe();
        }
      );
    });
   
    // }

     
      

    
  } */

  downloadPdfDelivery() {
    this.userService
      .getDelivery(this.route.snapshot.paramMap.get("id"))
      .subscribe((u) => {
        //TODO ASSEGNARE LA RESPONSE NEL CASO 200 E NON ERR
        this.user = u;
        this.userService
          .getPdfto64(this.user.deliveryId, this.user.sede)
          .subscribe(
            (pdf64) => {
              console.log(pdf64);
              this.pdf = pdf64;
            },
            (err) => {
              if (err.text) {
                if (err.text.includes("labelservice.gls-italy.com")) {
                  this.pdf = err.text.split(">")[1];
                  console.log(this.pdf);
                  this.showPdf();
                }
              } else {
                alert(
                  "Attenzione! Il corriere non ha ancora preso in carico la delivery, il pdf al momento non è disponibile."
                );
              }
            }
          );
      });
  }

  getDelivery() {
    this.userService
      .getDelivery(this.route.snapshot.paramMap.get("id"))
      .subscribe((u) => {
        //TODO ASSEGNARE LA RESPONSE NEL CASO 200 E NON ERR
        this.user = u;

        let str: string =
          u.dataRitiro.split("/")[0] +
          "-" +
          u.dataRitiro.split("/")[1] +
          "-" +
          u.dataRitiro.split("/")[2] +
          "T00:00:00";
        let dataRitiro = new Date(str).toLocaleString();
        let date1 = formatDate(new Date(this.currentDate), "yyyy-MM-dd", "it");
        let date2 = formatDate(
          new Date(
            parseInt(u.dataRitiro.split("/")[2]),
            parseInt(u.dataRitiro.split("/")[1]) - 1,
            parseInt(u.dataRitiro.split("/")[0])
          ),

          "yyyy-MM-dd",
          "it"
        );

        if (date1 < date2) {
          this.seeButton = true;
        } else if (date1 == date2) {
          if (this.currentDate.getHours() <= 12) {
            this.seeButton = true;
          }
        }
        /* this.userService
          .getPdfto64(this.user.deliveryId, this.user.sede)
          .subscribe(
            (pdf64) => {
              console.log(pdf64);
              this.pdf = pdf64;
            },
            (err) => {
              console.log("errore: " + err);

              this.pdf = err.text.split(">")[1];
              console.log(this.pdf);
            }
          ); */
      });
    // console.log(this.pdf);
  }
  showPdf() {
    const linkSource = "data:application/pdf;base64," + this.pdf;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  deleteDelivery() {
    var r = confirm("Sei sicuro di voler annullare l'ordine?");
    if (r == true) {
      this.confirmedFlag = true;
    }

    if (this.confirmedFlag == true) {
      const sub2$ = this.orderService
        .nullPreventivo(this.route.snapshot.paramMap.get("id"))
        .subscribe(
          (res) => {
            this.orderService
              .show(this.route.snapshot.paramMap.get("id"))
              .subscribe((x) => {
                this.email = x.buyerEmail;
                if (!this.email.includes("employee")) {
                  this.userService.get(this.email).subscribe(async (y) => {
                    this.token = y.fbtoken;
                    this.notificatioService.invionotifica(
                      [this.token],
                      "La spedizione relativa all'ordine n." +
                        this.route.snapshot.paramMap.get("id") +
                        " è stato annullata, contattare la tua farmacia di riferimento per informazioni",
                      "Notifica spedizione ordine n." +
                        this.route.snapshot.paramMap.get("id")
                    );
                  });
                }
              });
            this.router.navigate(["/orderbyemployee"]);
          },
          () => {
            sub2$.unsubscribe();
          },
          () => {
            sub2$.unsubscribe();
          }
        );
    }
  }
  pdfOtherCourier() {
    /*     window.location.href = err.text; */

    this.userService
      .getPdfOtherCourier(this.route.snapshot.paramMap.get("id"))
      .subscribe(
        (x) => {
          var newBlob = new Blob([x], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          var link = document.createElement("a");
          link.href = data;
          let today = new Date();
          link.download = "file" + ".pdf";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        (e) => {
          console.log(e);
        }
      );
    /*      window.location.href = err.text; */
  }
  showUsers() {
    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.nomeRichiedente > b.nomeRichiedente ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
    });
  }
}
