import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User } from "../../models/User";
import { Router, ActivatedRoute } from "@angular/router";
import { Role } from "../../enum/Role";
import { Location } from "@angular/common";
import { OrderService, IProcutList } from "../../services/order.service";
import { Spedizione } from "src/app/models/Spedizione";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { FarmacieService } from "../../services/farmacie.service";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-order-spedizione",
  templateUrl: "./order-spedizione.component.html",
  styleUrls: ["./order-spedizione.component.css"],
})
export class OrderSpedizioneComponent implements OnInit {
  nome: string;
  user = new Spedizione();
  name$;
  name: string;
  passwordConfirm: string;
  valueUtente: boolean;
  email: string;
  card: string;
  listaUtenti: Spedizione[];
  listautentiOrdinata: Spedizione[];
  importoContrassegno: boolean = false;
  currentDate: Date;
  users: Array<Spedizione> = [];
  confirmedFlag: boolean = false;
  idShop;
  corriere;
  formatoColli = [
    {
      tipo: "piccolo",
    },
    { tipo: "medio" },
    { tipo: "personalizzato" },
  ];
  dalle_alle = [
    {
      orario: "13:00",
    },
    { orario: "14:00" },
    { orario: "15:00" },
  ];
  misureColli: string;
  productList: ProductInOrder[];
  numeroColli: string;
  altezzaColli: string;
  larghezzaColli: string;
  profonditaColli: string;
  paymentTipo;
  paymentValore;
  fullAddressRitiro: string;
  fullAddressDestinatario: string;
  valoreReale: number;
  splittedAddressRitiro;
  splittedAddressDestinatario;
  totaleProdotto: string;
  codiceFiscale: string;
  telefonoDestinatario: any;
  token: string;
  giftCard: string;
  idSpedizione: string;
  totaleProdotti: ProductInOrder[];
  userId;
  farmaciaShop;
  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private farmacieService: FarmacieService,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    try {
      this.idSpedizione = this.route.snapshot.paramMap.get("id");
      this.userService
        .get(this.userService.currentUserValue.account)
        .subscribe((x) => {
          this.nome = x.name;
          this.idShop = x.shop;
          this.orderService.show(this.idSpedizione).subscribe((resp)=>{
            this.farmacieService.getFarmacia(resp.idShop).subscribe((x) => {
              this.farmaciaShop = resp.idShop;
              this.user.telefono = x.phone;
              this.user.emailRichiedente = x.email;
              this.corriere = x.deliveryService;
              if (this.corriere == "Altro") {
                this.dalle_alle = [
                  {
                    orario: "",
                  },
                  {
                    orario: "08:00",
                  },
                  { orario: "09:00" },
                  { orario: "10:00" },
                  {
                    orario: "11:00",
                  },
                  { orario: "12:00" },
                  { orario: "13:00" },
                  {
                    orario: "14:00",
                  },
                  { orario: "15:00" },
                  { orario: "16:00" },
                  {
                    orario: "17:00",
                  },
                  { orario: "18:00" },
                ];
              }
              this.user.nomeRichiedente = x.name;
              this.user.clienteritiro = x.name;
              this.splittedAddressRitiro = x.fullAddress.split(
                /[ ][(]|[ ][-][ ]|[)][ ]/g
              );
              if (this.splittedAddressRitiro.length == 4) {
                this.user.cAPritiro = this.splittedAddressRitiro[0];
                this.user.localitaritiro = this.splittedAddressRitiro[1];
                this.user.provinciaritiro = this.splittedAddressRitiro[2];
                this.user.indirizzoritiro =
                  this.splittedAddressRitiro[3].split(",")[0] +
                  "," +
                  this.splittedAddressRitiro[3].split(",")[1];
              }
            });
          })
       
        },
          (err) => {
            this.spinner.hide();
          });

      this.orderService
        .show(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          let contrassegno = 0;
          let dataSpedizione: Date;
          this.userId = x.userId;
          this.totaleProdotto = x.orderAmount;
          this.totaleProdotti = x.products;
          this.user.emaildestinatario =
            x.buyerEmail == this.user.emailRichiedente ? "" : x.buyerEmail;
          this.user.telefonodestinatario =
            x.buyerPhone == this.user.telefono ? "" : x.buyerPhone;
          this.user.noteSpedizione =
            "ID spedizione: " +
            this.idSpedizione +
            ", Num.Telefono destinatario: " +
            this.user.telefonodestinatario;
          this.codiceFiscale = x.fiscalCode;
          dataSpedizione = x.deliveryDate;
          this.currentDate = new Date(dataSpedizione);

          this.splittedAddressDestinatario = x.buyerAddress.split(
            /[ ][(]|[ ][-][ ]|[)][ ]/g
          );
          this.userService.get(x.buyerEmail).subscribe((z) => {
            this.userService.giftcardbyid(z.idGiftCard).subscribe((x) => {
              this.giftCard = x.number ? x.number : " ";
            });
          });
          if (this.splittedAddressDestinatario.length == 4) {
            this.user.cAPdestinatario = this.splittedAddressDestinatario[0];
            this.user.localitadestinatario = this.splittedAddressDestinatario[1];
            this.user.provinciadestinatario = this.splittedAddressDestinatario[2];
            this.user.indirizzodestinatario = this.splittedAddressDestinatario[3];
            this.spinner.hide();
          }
          else {
            alert("Errore nella precompilazione dell'indirizzo destinatario");
          }

          this.user.clientedestinatario = x.buyerName + " " + x.buyerSurname;
          this.user.colli = "1";
          this.user.tipoCollo = "0";
          this.paymentTipo = x.paymentType;
          switch (this.paymentTipo) {
            case null:
              window.alert("Attenzione, errore con i pagamenti");
              this.router.navigate(["/orderbyemployee"]);
              break;
            case 0:
              this.paymentValore = "PayPal";
              break;
            case 1:
              this.paymentValore = "Carta di credito";
              break;
            case 2:
              let sum = 0;
              this.user.resaCAssegno = "M";
              this.user.modalitaIncasso = "CONT";
              if (
                this.user.emaildestinatario != "" &&
                this.user.telefonodestinatario != ""
              ) {
                this.totaleProdotti.forEach((x) => {
                  if (x.productName != "Spese di spedizione") {
                    sum += x.productPrice * x.count;
                  }
                });
                if (this.corriere == "GLS") {
                  sum > 100
                    ? (contrassegno = (sum * 1) / 100)
                    : (contrassegno = 1);
                  this.totaleProdotto =
                    this.totaleProdotto + parseFloat(contrassegno.toFixed(2));
                }
                let num = parseFloat(this.totaleProdotto);

                this.user.importocontrassegno = num.toFixed(2).toString();
                this.paymentValore = "Contrassegno";
              }
              break;
          }
          this.spinner.hide();
        },
          (err) => { this.spinner.hide(); });

      const subs$ = this.orderService
        .show(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          let products = x.products.map((x) => {
            return x;
          });
          this.productList = products;
          subs$.unsubscribe();
        });
    }
    catch (err) {
      this.spinner.hide();
    }
  }

  onSubmit() {
    var r = confirm("Sei sicuro di aver compilato correttamente i campi?");
    if (r == true) {
      this.confirmedFlag = true;
    }

    let dalle2 = parseInt(this.user.dalle2.substring(0, 2));
    let alle2 = parseInt(this.user.alle2.substring(0, 2));
    if (dalle2 >= alle2) {
      this.confirmedFlag = false;
      window.alert(
        "Attenzione, ora iniziale superiore o uguale a quella finale"
      );
    }
    if (this.valoreReale <= 0) {
      this.confirmedFlag = false;
      window.alert(
        "Attenzione, il peso reale non può essere inferiore o pari a 0"
      );
    }
    if (
      this.corriere != "Altro" &&
      this.user.localitaritiro.toLowerCase() !=
      this.user.localitadestinatario.toLowerCase()
    ) {
      this.confirmedFlag = false;
      window.alert(
        "ATTENZIONE! La spedizione è permessa esclusivamente per consegne Comune su Comune."
      );
    }

    if (this.confirmedFlag == true) {
      this.spinner.show();
      this.user.pesoReale = this.valoreReale.toString();
      switch (this.misureColli) {
        case "piccolo":
          this.user.misureColli = "1,11,111,1";
          break;
        case "medio":
          this.user.misureColli = "1,22,222,1";
          break;
        case "personalizzato":
          this.user.misureColli =
            this.numeroColli +
            "," +
            this.altezzaColli +
            "," +
            this.larghezzaColli +
            "," +
            this.profonditaColli;
      }
      let d = this.currentDate.getDate();
      let m = this.currentDate.getMonth() + 1;
      let y = this.currentDate.getFullYear();
      this.user.dataRitiro =
        d.toString() + "/" + m.toString() + "/" + y.toString();
      /*  let nameday = this.currentDate.getDay();
      let hh = this.currentDate.getHours();

      if (nameday == 5) {
        if (hh < 12) {
          let day: any = this.currentDate.getDate();
          let month: any = this.currentDate.getMonth() + 1;
          let year: any = this.currentDate.getFullYear();
          this.user.dataRitiro =
            day.toString() + "/" + month.toString() + "/" + year.toString();
        } else {
          let day: any = this.currentDate.getDate() + 3;
          let month: any = this.currentDate.getMonth() + 1;
          let year: any = this.currentDate.getFullYear();
          this.user.dataRitiro =
            day.toString() + "/" + month.toString() + "/" + year.toString();
        }
      }
      if (nameday == 6) {
        let day: any = this.currentDate.getDate() + 2;
        let month: any = this.currentDate.getMonth() + 1;
        let year: any = this.currentDate.getFullYear();
        this.user.dataRitiro =
          day.toString() + "/" + month.toString() + "/" + year.toString();
      }

      if (nameday != 5 && nameday != 6) {
        if (hh < 12) {
          let day: any = this.currentDate.getDate();
          let month: any = this.currentDate.getMonth() + 1;
          let year: any = this.currentDate.getFullYear();
          this.user.dataRitiro =
            day.toString() + "/" + month.toString() + "/" + year.toString();
        } else {
          let day: any = this.currentDate.getDate() + 1;
          let month: any = this.currentDate.getMonth() + 1;
          let year: any = this.currentDate.getFullYear();
          this.user.dataRitiro =
            day.toString() + "/" + month.toString() + "/" + year.toString();
        }
      } */
      // if (this.user.password == this.passwordConfirm) {
      this.users = [];
      this.user.alle2 = alle2.toString();
      this.user.dalle2 = dalle2.toString();
      this.users.push(this.user);


   
      this.userService
        .updateSpedizione(this.users, this.route.snapshot.paramMap.get("id"), this.farmaciaShop)
        .subscribe((u) => {
          const sub2$ = this.orderService
            .statusChanger(this.route.snapshot.paramMap.get("id"), 9)
            .subscribe(
              (res) => {
                this.spinner.hide();
                /*         this.invioNotifica(); */
                this.router.navigate(["/orderbyemployee"]);
              },
              () => {
                this.spinner.show();
                sub2$.unsubscribe();
              },
              () => {
                this.spinner.show();
                sub2$.unsubscribe();
              }
            );
        });

      // }
    }
  }

  /*   indirizzoSintassi() {
    window.alert(
      "Attenzione, la sintassi corretta per inserire l'indirizzo è 'NOME CITTÀ'+ '(PROVINCIA)' + 'INDIRIZZO DI RESIDENZA' + ',' + 'NUMERO CIVICO'"
    );
  } */

  deleteDelivery() {
    var r = confirm("Sei sicuro di voler annullare l'ordine?");
    if (r == true) {
      this.confirmedFlag = true;
    }

    if (this.confirmedFlag == true) {
      const sub2$ = this.orderService
        .nullPreventivo(this.route.snapshot.paramMap.get("id"))
        .subscribe(
          (res) => {
            /*   this.userService
                .getProfile(this.user.telefonodestinatario)
                .subscribe(async (y) => {
                  this.token = y.fbtoken;
                  this.notificatioService.invionotifica(
                    [this.token],
                    "La spedizione relativa all'ordine n." +
                      this.route.snapshot.paramMap.get("id") +
                      " è stato annullata, contattare la tua farmacia di riferimento per informazioni",
                    "Notifica spedizione ordine n." +
                      this.route.snapshot.paramMap.get("id")
                  );
                }); */
            this.router.navigate(["/orderbyemployee"]);
          },
          () => {
            sub2$.unsubscribe();
          },
          () => {
            sub2$.unsubscribe();
          }
        );

      // }
    }
  }
  invioNotifica() {
    this.userService
      .getProfile(this.user.telefonodestinatario)
      .subscribe((y) => {
        let token = y.fbtoken;
        this.notificatioService.invionotifica(
          [token],
          "Ordine in spedizione dalla tua farmacia preferita",
          "Ordine evaso da " +
          this.user.nomeRichiedente /*  "Farmacia " + this.nameFarmacia */
        );
      });
  }
}
