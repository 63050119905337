import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductDiscountService } from 'src/app/services/product-discount.service';
import { NgxColorsModule } from 'ngx-colors';
import { ProductService } from 'src/app/services/product.service';
import { ProductInOrder } from 'src/app/models/ProductInOrder';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-product-discount-adding',
  templateUrl: './product-discount-adding.component.html',
  styleUrls: ['./product-discount-adding.component.css']
})
export class ProductDiscountAddingComponent implements OnInit {
  jpg;
  lastPhoto = undefined;
  jpgFlag: boolean = false;
  productName;
  productDescription;
  productPrice;
  startTime;
  endTime;
  idShop;
  _albums: Array<any> = [];
  color = "";
  backgroundColor;
  nomeProdotto: string;
  nomeProdottoVolantino: string;
  page: Array<any>;
  item: string = "";
  itemVolantino: string = "";
  pageVolantino: ProductInOrder[];
  productInfoIdList: any[] = [];
  selectedProductVolantino: any;
  selectedProductListino: any;
  prodottiAggiunti: any[] = [];

  constructor(
    private discountedProductService: ProductDiscountService,
     private spinner: NgxSpinnerService,
     private route: ActivatedRoute,
     private router: Router,
     private _lightbox: Lightbox,
     private productService: ProductService,
     ) { }

  ngOnInit() {
    this.idShop = this.route.snapshot.paramMap.get("id");
    const album = {
      src: "",
    }
    this._albums.push(album);
  }

  upload(fileUploaded){
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
        const file: File = fileUploaded.files[0];
       
       
         if (fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == 'jpg' || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == 'jpeg'){
          this.jpg = file;
          this.lastPhoto = file;
          var reader = new FileReader();
          reader.readAsDataURL(file); 
          reader.onload = (_event) => { 
          this._albums[0].src = reader.result; 
    
        }
      }
        else{
          alert("Errore con l'upload dei formati.")
        }

        this.jpgFlag = this.jpg != undefined ? true : false
  
      }

  onSubmit(){
    let dateToCompare = new Date (this.startTime);
    console.log("colore: "+this.color)
    let dateEndCompare = new Date (this.endTime);
    this.prodottiAggiunti.map(element => element.discountPrice = this.productPrice.toFixed(2));
    if(dateToCompare.getTime() < dateEndCompare.getTime()){
      if(this.jpg == undefined){
        this.jpg = this.lastPhoto;
      }
    this.spinner.show();
    let body = {
      productInDiscountName : this.productName,
      productInDiscountDescription : this.productDescription,
      discountStartDate : this.startTime ,
      discountEndDate : this.endTime,
      discountPrice : this.productPrice.toFixed(2),
      productInDiscountBackgroundColor: this.color,
      idShop: this.idShop,
      subProductInDiscountToSaveOrUpdateRequestList: this.prodottiAggiunti
      
    }
    this.discountedProductService.addDiscountedProduct(body, this.jpg).then((x)=>{
      if(x.status == 200){
        this.spinner.hide();
        alert("Prodotto scontato aggiunto con successo!")
        this.router.navigate(["/discountedProducts/" + this.idShop])
        }
      if(x.status == 400){
        this.spinner.hide();
       alert("Attenzione! Qualcosa è andato storto. Riprova")
      }
    }
   
   )
  } 
   else{
    alert("Attenzione! Imposta una data valida")
  }
}

onChangeColor(color){
this.color = color;
}
open(index: number): void {
  // open lightbox
  this._lightbox.open(this._albums, index);
}


close(): void {
  // close lightbox programmatically
  this._lightbox.close();
}


rimuoviProdotto() {
  this.prodottiAggiunti.splice(this.prodottiAggiunti.length-1, 1);
}

aggiungiProdotto() {
  console.log(this.prodottiAggiunti);
  this.prodottiAggiunti.push({
    productInDiscountName: '',
    productInDiscountDescription: '',
    discountPrice: ''
  });

}

checkProdottiAggiunti(): boolean {
  return this.prodottiAggiunti.some(prodotto => prodotto.productInDiscountName === '');
}
}
