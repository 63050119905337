import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { FarmacieService } from 'src/app/services/farmacie.service';
import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-automatic-responses',
  templateUrl: './automatic-responses.component.html',
  styleUrls: ['./automatic-responses.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(400, style({opacity: 0})))
    ])
  ]
})
export class AutomaticResponsesComponent implements OnInit {
  newPhrase: string = '';
  phrases = [];
  maxPhrases: number = 10;
  automaticPhrase: FormGroup;

  constructor(private form: FormBuilder, private farmaciaService: FarmacieService, private spinner: NgxSpinnerService) {
    this.automaticPhrase = this.form.group({
      phrase: [''],
    })
   }

  ngOnInit() {
    this.farmaciaService.getAllAutomaticMessages().subscribe((x)=>{
      this.phrases = x;
    })
  }

  addPhrase() {
    if (this.aP['phrase'].value.trim() !== '' && this.phrases.length < this.maxPhrases) {
      const raw = JSON.stringify([
        {
          "body": this.aP['phrase'].value
        }
      ]);
      this.farmaciaService.saveAutomaticMessage(raw).pipe(
        switchMap(() => {
          this.aP['phrase'].setValue("");
          return this.farmaciaService.getAllAutomaticMessages();
        })
      ).subscribe((updatedMessages) => {
        this.phrases = updatedMessages;
      });
    }
    else{
      alert("Attenzione, il limite è di 10 messaggi automatici!")
    }
  }

  deletePhrase(phrase: any) {
  /*   this.spinner.show();
 */
    this.farmaciaService.deleteSingleAutomaticMessage(phrase.messageId).pipe(
      switchMap(() => {
        return this.farmaciaService.getAllAutomaticMessages();
      })
    ).subscribe((updatedMessages) => {
      this.phrases = updatedMessages;
   /*    this.spinner.hide(); */
    }, (err)=>{
     /*  this.spinner.hide(); */
    });
  

  
  }

  get aP(): any {
    return this.automaticPhrase.controls;
  }
}
