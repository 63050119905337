export class Farmacia {
  id: number;
  active: boolean;
  address: string;
  gifts: boolean;
  idCity: string;
  idDistrict: number;
  idEmployee: number;
  idRegion: string;
  idSmoove: string;
  lat: number;
  long_: number;
  name: string;
  openingTime: string;
  phone: string;
  meters: number;
  vat: string;
  fullAddress?: string;
  msgUnread?: number;
  deliveryService: string;
  email?: string;
  webUrl?: string;
  fseEnabled?: boolean
  sections?: any;
  numVisibleSections?: any;
}
