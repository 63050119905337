import { User } from "./User";

export class Coupon {
  coupon: {
    idShop: number;
    name: string;
    discount: string;
    description: string;
    start: Date;
    expired: Date;
    id: number;
    oldUsers?: boolean;
    newUsers?: boolean;
  };
  userIds: Array<number>;

  constructor() {
    this.coupon = {
      idShop: 0,
      name: "",
      discount: "",
      description: "",
      start: new Date(),
      expired: new Date(),
      id: 0,
    };
    this.userIds = [];
  }
}

export class CouponUsers {
  user: User;
  used: boolean;
}

export class couponFormat {
  idShop: number;
  name: string;
  discount: string;
  description: string;
  start: Date;
  expired: Date;
  id: number;
}
