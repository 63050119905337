import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvertisingService } from 'src/app/services/advertising.service';


@Component({
  selector: 'app-add-advertising',
  templateUrl: './add-advertising.component.html',
  styleUrls: ['./add-advertising.component.css']
})
export class AddAdvertisingComponent implements OnInit {
  file;
  lastFile = undefined;
  fileFlag: boolean = false;
  name;
  durata;
  idShop;
  videoUrl;
  videoSizeInBytes;
  videoSizeError;
  _albums: Array<any> = [];
  constructor(
    private advertisingService: AdvertisingService,
     private spinner: NgxSpinnerService,
     private route: ActivatedRoute,
     private router: Router,
     private _lightbox: Lightbox,
     private sanitizer: DomSanitizer,
     ) { }

  ngOnInit() {
    this.idShop = this.route.snapshot.paramMap.get("id");
    const album = {
      src: "",
    }
    this._albums.push(album);
  }

  upload(fileUploaded){
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
        const file: File = fileUploaded.files[0];
        
       

       
         if (fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == "jpg" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == "mp4" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == "jpeg"){
          this.file = file;
          this.lastFile = file;
           if(fileUploaded.files[0].name.split(".")[1] == "mp4"){
            this.videoSizeInBytes = file.size;
             this._albums[0].src = "";
            this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
           }
           else{
            this.videoUrl = null;
            var reader = new FileReader();
            reader.readAsDataURL(file); 
            reader.onload = (_event) => { 
            this._albums[0].src = reader.result; 
          }
        }
         
       
      }
        else{
          alert("Errore con l'upload dei file. Il Sistema gestisce i seguenti formati: .jpg, .jpeg, .mp4")
          this.fileFlag = false;
        }

        this.fileFlag = this.file != undefined ? true : false
  
      }

  onSubmit(){
      if(this.file == undefined){
        this.file = this.lastFile;
      }
    this.spinner.show();
  
    this.advertisingService.newAdv(this.file, this.durata, this.name).then((x)=>{
      if(x.status == 200){
        this.spinner.hide();
        alert("Pubblicità inserita con successo!")
        this.router.navigate(["/advertising/" + this.idShop])
        }
        else{
          this.spinner.hide();
          alert("Attenzione! Qualcosa è andato storto. Riprova")
      }
    }
   )
}

getDuration(e) {
  const duration = e.target.duration;
  if(duration > 90 || this.videoSizeInBytes > 105472000){
  this.fileFlag = false;
  alert("Attenzione, il video è troppo lungo o pesa troppo.")
  
}
}

open(index: number): void {
  // open lightbox
  this._lightbox.open(this._albums, index);
}


close(): void {
  // close lightbox programmatically
  this._lightbox.close();
}

}
