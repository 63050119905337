export class Spedizione {
  codiceContrattoGls: string;
  nomeRichiedente: string;
  bda: string; //non obbligatorio
  colli: string;
  bancali: string; //non obbligatorio
  fuoriSagoma: string; //non obbligatorio
  misureColli: string;
  pesoReale: string; //obbligatorio
  pesoVolume: string;
  dataRitiro: string;
  dalle1: string; //non obbligatorio
  alle1: string; //non obbligatorio
  dalle2: string; //obbligatorio orario dalle 13 alle 15
  alle2: string; //obbligatorio orario dalle 13 alle 15
  tipoCollo: string; //uguale a 0
  clienteritiro: string; //prendo dal database con una chiamata già fatta
  indirizzoritiro: string; //prendo dal database con una chiamata già fatta
  localitaritiro: string; //prendo dal database con una chiamata già fatta
  cAPritiro: string; //prendo dal database con una chiamata già fatta
  provinciaritiro: string; //prendo dal database con una chiamata già fatta
  clientedestinatario: string;
  indirizzodestinatario: string;
  localitadestinatario: string;
  cAPdestinatario: string;
  provinciadestinatario: string;
  telefonodestinatario: string;
  emaildestinatario: string;
  importocontrassegno: string; //mettere 1 euro in più al totale
  modalitaIncasso: string; //solo contante
  noteSpedizione: string; //una sorta di nota per il farmicista con una textbox  //da fare
  assicurazione: string; //nascosta
  emailRichiedente: string; //prendere dalla chiamata utentifarmacia
  telefono: string; //prendere dalla chiamata utentifarmacia
  serviziAccessori: string; //per ora non in uso
  emailLuogoritiro: string; //prendere sempre dalla chiamata user farmacia
  resaCAssegno: string; //deve essere 'Mittente'
  riferimentocliente: string;
  numeroRitiro: string;
  pdfCode: string;
  sede: string;
  deliveryId: string;
  codiceConsegna: string;
}
