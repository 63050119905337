import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ProductService } from "../../services/product.service";
import { JwtResponse } from "../../response/JwtResponse";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoryType } from "../../enum/CategoryType";
import { ProductStatus } from "../../enum/ProductStatus";
import { ProductInfo } from "../../models/productInfo";
import { Role } from "../../enum/Role";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VolantinoService } from "src/app/services/volantino.service";
import { ArrayType, ThrowStmt } from "@angular/compiler";

export interface DialogData {

}

@Component({
  selector: "app-product.list",
  templateUrl: "./product.list.component.html",
  styleUrls: ["./product.list.component.css"],
})
export class ProductListComponent implements OnInit, OnDestroy {

  constructor(
    private userService: UserService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private discountedProductService: ProductDiscountService,
  ) {}
  // name$;
  // name: string;
  Role = Role;
  currentUser: JwtResponse;
  page: any;
  CategoryType = CategoryType;
  ProductStatus = ProductStatus;
  private querySub: Subscription;
  searchText2: string;
  searchTermTemp: string = "";
  searchTermTempCondition: boolean = false;
  productFlyer = true;
  discountedProduct = false;
  ngOnInit() {
    this.spinner.show();
 /*   this.getDiscountedProducts(); */
    this.querySub = this.route.queryParams.subscribe(() => {
      this.update();
    });
  }

  volantinoPopup(){
    const dialogRef = this.dialog.open(DialogFlyer, {
      width: "700px",
      data: {

      },
    });
  }

  editPhoto(product){
    const dialogRef = this.dialog.open(DialogPhoto, {
      width: "700px",
      data: product
    });
  }
  addProducts() {
    this.router.navigate(["/seller/product/new"]);
  }
  productsFilter() {
    if(this.productFlyer){
    if (this.searchTermTemp != "") {
      let wordSearch = this.searchTermTemp;
      setTimeout(() => {
        if (wordSearch == this.searchTermTemp) {
          if (this.searchTermTemp) {
            this.searchTermTempCondition = true;
            this.productService
              .getAllInPageFilter(this.searchTermTemp)
              .subscribe((page) => {
                this.page = page;
                /*       this.spinner.hide(); */
              });
          }
        }
      }, 3000);
    } else {
      this.searchTermTempCondition = false;
      this.update();
    }
  }
  else{
    if (this.searchTermTemp != "") {
      let wordSearch = this.searchTermTemp;
      setTimeout(() => {
        if (wordSearch == this.searchTermTemp) {
          if (this.searchTermTemp) {
            this.searchTermTempCondition = true;
            this.productService.getAllDiscountedInPageFilter(this.searchTermTemp)
              .subscribe((page) => {
                this.page = page;
                /*       this.spinner.hide(); */
              });
          }
        }
      }, 3000);
    } else {
      this.searchTermTempCondition = false;
      this.update();
    }

  }
  }
  ngOnDestroy(): void {
    this.querySub.unsubscribe();
  }

  getDiscountedProducts(page = 1, size = 30){
    this.productService.getAllDiscountedInPage(page, size, "").subscribe((x)=>{
      this.page = x;
    })
  }

  update() {
    if(this.productFlyer){
    if (this.route.snapshot.queryParamMap.get("page")) {
      const currentPage = +this.route.snapshot.queryParamMap.get("page");
      const size = +this.route.snapshot.queryParamMap.get("size");
      this.getProds(currentPage, size);
    } else {
      this.getProds();
    }
    }
    else{
    
    if (this.route.snapshot.queryParamMap.get("page")) {
      const currentPage = +this.route.snapshot.queryParamMap.get("page");
      const size = +this.route.snapshot.queryParamMap.get("size");
      this.getDiscountedProducts(currentPage, size);
    } else {
      this.getDiscountedProducts();
    }
  }
  }

  filterForDiscountedProduct(page: number = 1, size: number = 30){
    this.spinner.show();
    this.productFlyer = false;
    this.discountedProduct = true;
    this.productService.getAllDiscountedInPage(page, size, "").subscribe((x)=>{
      this.page = x;
      this.spinner.hide();
    })
  }

  filterForFlyersProduct(){
   
    this.discountedProduct = false;
    this.productFlyer = true;
    this.getProds();    
  }

  getProds(page: number = 1, size: number = 30) {
    this.spinner.show();
    this.productService.getAllInPage(+page, +size).subscribe((page) => {
      this.page = page;
      this.spinner.hide();
    });
  }

  remove(productInfos: ProductInfo[], productInfo) {
    var r = confirm("Sei sicuro di voler eliminare il prodotto?");
    if (r) {
      this.productService.delete(productInfo).subscribe();
      this.update();
    }
  }
}


import {NgForm} from '@angular/forms';
import { ProductDiscountService } from "src/app/services/product-discount.service";
import { Lightbox } from "ngx-lightbox";
import { apiUrl } from "src/environments/environment";
@Component({
  selector: "dialog-flyer",
  templateUrl: "./dialog-flyer.html",
})
export class DialogFlyer implements OnInit {
  
  userComponent;
  users: [];
  fileNameXls = '';
  fileNamePdf = '';;
  fileNameJpg = '';;
  xls;
  pdf;
  jpg;
  xlsFlag: boolean = false;
  pdfFlag: boolean = false;
  jpgFlag: boolean = false;
  position
  flyerList;
  flyerReward;
  dateIn;
  dateOut;
  flag: boolean = false;
  flyerPosition: number[] = [];
  currentDate: Date;
  flyersAllType = [
    { type: "Classico" },

    { type: "Per premi" },

  ];
  flyerType: string = "Classico";
  uploadFlag: boolean = false;
  @ViewChild('form', {static: true}) form: NgForm;
  constructor(
    public dialogRef: MatDialogRef<DialogFlyer>,
    private userService: UserService,
    private volantinoService: VolantinoService,
    private spinner: NgxSpinnerService,

    @Inject(MAT_DIALOG_DATA) public data: DialogFlyer
    
  ) {

  }
  
  ngOnInit() {
    this.currentDate = new Date();
    this.getFlyerList();
    this.getRewardFlyerList();
      
    }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }
  
 
  /* processFile(fileUploaded: any) {
    let stringToCompare: string = fileUploaded.files[0].name;
    
    const file: File = fileUploaded.files[0];
    if(stringToCompare.split(".")[1] != "jpg" && stringToCompare.split(".")[1] != "jpeg" && stringToCompare.split(".")[1] != "csv" && stringToCompare.split(".")[1] != "pdf"){
      alert("Attenzione, puoi caricare solo i formati richiesti")
    }
    else{
    if (
      stringToCompare.split(".")[1] == "jpg" || stringToCompare.split(".")[1] == "jpeg" 
    ) {
      this.jpg = file;
      this.flag
      this.fileNameJpg = stringToCompare;
    }
    if (
      stringToCompare.split(".")[1] == "csv" 
    ) {

      this.csv = file;
      this.fileNameCsv = stringToCompare
    }
    if (
      stringToCompare.split(".")[1] == "pdf" 
    ) {
      this.pdf = file;
      this.fileNamePdf = stringToCompare
    }
  }
  }
 */
  upload(type, fileUploaded){
/*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
    const file: File = fileUploaded.files[0];
    if(type == "xlsx" && fileUploaded.files[0].name.split(".")[1] == type){
      this.xls = file

    }
    else if( type =="pdf" && fileUploaded.files[0].name.split(".")[1] == type){
      this.pdf = file

    }
    else if (type =="jpg" && fileUploaded.files[0].name.split(".")[1] == type){
      this.jpg = file;

    }
    else{
      alert("Errore con l'upload dei formati.")
    }
    this.xlsFlag = this.xls != undefined ? true : false
    this.pdfFlag = this.pdf != undefined ? true : false
    this.jpgFlag = this.jpg != undefined ? true : false
   if (this.flyerType == "Classico"){
    if(this.xlsFlag && this.pdfFlag && this.jpgFlag) this.uploadFlag = true;
   }
   else{
    if(this.xlsFlag && this.pdfFlag) this.uploadFlag = true;
   }
  }



  getFlyerList(){
    let tempArray = new Array(9);
/*     this.flyerPosition = new Array(10); */
    for (let index = 0; index < 10; index++) {
   /*    this.flyerPosition.push(0); */
   tempArray[index] = 0;
    }
    this.volantinoService.getFlyerList().subscribe((x)=>{
      this.flyerList = x;
     this.flyerList.forEach(element => {
 
      tempArray[element.position] = element.position;
       
     });
      this.flyerPosition = tempArray;
   
    },
    (err)=>{
      this.flyerList = [];
    })
  }

  getRewardFlyerList(){

/*     this.flyerPosition = new Array(10); */
    this.volantinoService.getRewardFlyerList().subscribe((x)=>{
      this.flyerReward = x;
    },
    (err)=>{
      this.flyerReward = null;
    })
  }

inviaVolantino(){
  let dataConf = new Date(this.dateOut)
  if(this.currentDate.getTime()<dataConf.getTime()){
  this.spinner.show();
  let dataIn: any = this.dateIn.toString().replaceAll("-","");
  let dataOut: any = this.dateOut.toString().replaceAll("-","");
  if(this.flyerType == "Classico"){
  this.volantinoService.uploadFlyer(this.xls, this.pdf, this.jpg, dataIn, dataOut, this.position).then((x)=>{
    if(x.status == 200){

    this.spinner.hide();
    alert("Volantino inserito con successo!")
    this.dialogRef.close()
    }
    if (x.status == 406){
      this.xls = null;
      this.pdf = null; 
      this.jpg = null;
      this.xlsFlag = false;
      this.pdfFlag = false;
      this.jpgFlag = false;
      this.uploadFlag = false;
      this.spinner.hide();
    alert("Attenzione! file non corretti, riprovare.")
    }
  },
  )
}
else{
  this.volantinoService.uploadRewardFlyer(this.xls, this.pdf, dataIn, dataOut).then((x)=>{
    if(x.status == 200){
    this.spinner.hide();
    alert("Volantino inserito con successo!")
    this.dialogRef.close();
    }
    else if(x.status == 406){
      this.xls = null;
      this.pdf = null; 
      this.xlsFlag = false;
      this.pdfFlag = false;
      this.uploadFlag = false;
      this.spinner.hide();
      alert("Attenzione! file non corretti, riprovare.")
    }
  })
}
  }
else{
  alert("Attenzione! la data di fine è precedente a quella odierna.")
}
  
}
goNext(){
this.flag = true;
}

goBack(){
  this.xls = null;
  this.pdf = null;
  this.jpg = null;
  this.xlsFlag = false;
  this.pdfFlag = false;
  this.jpgFlag = false;
  this.flag = false;
}

  eliminaVolantino(volantino) {
    let r = confirm(
      "Sei sicuro di voler cancellare il volantino?  "
    );
    if (r) {
  this.volantinoService.deleteFlyerById(volantino.id).subscribe((x)=>{
    alert("Volantino eliminato!")
    if(volantino.position == 10) this.getRewardFlyerList();
    else this.getFlyerList();
  })
    }
  
  }

  log(log){
    console.log(log);
  }

}


@Component({
  selector: "dialog-photo",
  templateUrl: "./dialog-photo.html",
})
export class DialogPhoto implements OnInit {
  _albums: Array<any> = [];
  jpg;
  jpgFlag = false;
  constructor(
    public dialogRef: MatDialogRef<DialogPhoto>,
    private userService: UserService,
    private _lightbox: Lightbox,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) {

  }
  
  ngOnInit() {
    this.productService.getProductPhoto(this.data.codMinsan).then((x)=>{
      if(x.status == "200"){
      const album = {
        src: `${apiUrl}/mediaTemp/ProductImages/${this.data.codMinsan}.jpg`,
      }
      this._albums.push(album);
    }
    else{
      const album = {
        src: `${apiUrl}/mediaTemp/ProductImages/Eufarma.jpg`,
      }
      this._albums.push(album);
    }
    

    })

  
    }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }
  
  upload(fileUploaded){
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
        const file: File = fileUploaded.files[0];
       
       
         if (fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == 'jpg' || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length-1] == 'jpeg'){
          this.jpg = file;
         
          var reader = new FileReader();
          reader.readAsDataURL(file); 
          reader.onload = (_event) => { 
          this._albums[0].src = reader.result; 
    
        }
      }
        else{
          alert("Errore con l'upload dei formati. Puoi caricare solo jpg/jpeg.")
        }

        this.jpgFlag = this.jpg != undefined ? true : false
  
      }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
  
  
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  updatePhoto(){
    this.productService.addOrModifiyPhoto(this.data.codMinsan, this.jpg).then((x)=>{
      if(x.status == "200"){
        alert("Foto correttamente aggiornata!");
        this.dialogRef.close();
      }
    })
  }
  
}

