import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { apiUrl } from "../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { UserService } from "./user.service";
import { Cart } from "../models/Cart";
import { Item } from "../models/Item";
import { JwtResponse } from "../response/JwtResponse";
import { ProductInOrder } from "../models/ProductInOrder";
import { UrlSegment } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private cartUrl = `${apiUrl}/cart`;

  localMap = {};

  private itemsSubject: BehaviorSubject<Item[]>;
  private totalSubject: BehaviorSubject<number>;
  public items: Observable<Item[]>;
  public total: Observable<number>;

  private currentUser: JwtResponse;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private userService: UserService
  ) {
    this.itemsSubject = new BehaviorSubject<Item[]>(null);
    this.items = this.itemsSubject.asObservable();
    this.totalSubject = new BehaviorSubject<number>(null);
    this.total = this.totalSubject.asObservable();
    this.userService.currentUser.subscribe((user) => (this.currentUser = user));
  }
  // funzione che ritorna il carrello attuale
  private getLocalCart(): ProductInOrder[] {
    if (this.cookieService.check("cart")) {
      this.localMap = JSON.parse(this.cookieService.get("cart"));
      return Object.values(this.localMap);
    } else {
      this.localMap = {};
      return [];
    }
  }
  // funzione che ritorna il carrello dell'Utente che ha effettuato il login
  getCart(): Observable<any> {
    const localCart = this.getLocalCart();
    if (this.currentUser) {
      if (localCart.length > 0) {
        return this.http.post<Cart>(this.cartUrl, localCart).pipe(
          tap((_) => {
            //  this.clearLocalCart();
          }),
          map((cart) => cart.products),
          catchError((_) => of([]))
        );
      } else {
        return this.http.get<Cart>(this.cartUrl).pipe(
          map((cart) => cart.products),
          catchError((_) => of([]))
        );
      }
    } else {
      return of(localCart);
    }
  }
  // funzione che aggiunge un nuovo oggetto al carrello
  addItem(productInOrder): Observable<any> {
    if (!this.currentUser) {
      if (this.cookieService.check("cart")) {
        this.localMap = JSON.parse(this.cookieService.get("cart"));
      }
      if (!this.localMap[productInOrder.productId]) {
        this.localMap[productInOrder.productId] = productInOrder;
      } else {
        this.localMap[productInOrder.productId].count += productInOrder.count;
      }
      this.cookieService.set("cart", JSON.stringify(this.localMap));
      return of(true);
    } else {
      const url = `${this.cartUrl}/add`;
      return this.http.post<any>(url, {
        quantity: productInOrder.count,
        productId: productInOrder.productId,
      });
    }
  }


  // funzione che serve a svuotare il carrello.
  removeCart() {
    const url = `${apiUrl}/product/deleteallbycart`;
    return this.http.delete(url).pipe();
  }

  // funzione che aggiorna i dati scelti di un elemento nel carrello
  update(productInOrder): Observable<ProductInOrder> {
    if (this.currentUser) {
      const url = `${this.cartUrl}/${productInOrder.productId}`;
      return this.http.put<ProductInOrder>(url, productInOrder.count);
    }
  }

  // funzione che rimuove un oggetto dal carrello
  remove(productInOrder) {
    if (!this.currentUser) {
      delete this.localMap[productInOrder.productId];
      return of(null);
    } else {
      const url = `${this.cartUrl}/${productInOrder.productId}`;
      return this.http.delete(url).pipe();
    }
  }

  //funzione per finalizzare un ordine destinato al click&collect
  checkout(id): Observable<any> {
    const url = `${this.cartUrl}/checkout/6/${id}`;
    return this.http.post(url, null).pipe();
  }

  // funzione per aggiungere prodotti all'ordine
  addProductToOrder(product: ProductInOrder, orderId) {
    const url = `${apiUrl}/addProduct/${orderId}`;
    return this.http.post(url, product);
  }
  // funzione per eliminare i prodotti, in base all'id, di uno specifico ordine
  deleteProductToOrder(orderId, productId) {
    const url = `${apiUrl}/removeProduct/${orderId}/${productId}`;
    return this.http.delete(url).pipe();
  }

  // funzione che serve a finalizzare un ordine destinato ad una spedizione
  checkoutShipping(
    address,
    nameAndSurname,
    codiceFiscale,
    buyerPhone,
    body,
    farmacia?
  ): Observable<any> {
    let farma = farmacia ? `/${farmacia}` : "";
    const url = `${this.cartUrl}/checkout/shipping/${address}/${nameAndSurname}/${codiceFiscale}/${buyerPhone}${farma}`;
    return this.http.post(url, body).pipe();
  }

  // funzione per ricevere il numero di cartnumber bruciate
  getburnedcardnumber(): Observable<any> {
    const url = `${apiUrl}/getburnedcardnumber`;
    return this.http.get(url).pipe();
  }

    // funzione per ricevere il numero di cartnumber ancora disponibili
  getunburnedcardnumber(): Observable<any> {
    const url = `${apiUrl}/getunburnedcardnumber `;
    return this.http.get(url).pipe();
  }

  // funzione per creare il carrello di una farmacia
  createCart(): Observable<any> {
    const url = `${this.cartUrl}/createcart`;
    return this.http.post(url, null).pipe();
  }

  // funzione che ritorna in base ad un indirizzo i luoghi più vicini all'input in ingresso.
  getLocation(indirizzo): Observable<any> {
    const url = `${this.cartUrl}/apiLocation/${indirizzo}`;
    setTimeout(()=>{

    }, 2000)
    return this.http.get<any>(url);
  }

  // funzione per conservare in maniera locale i contenuti di un carrello.
  storeLocalCart() {
    this.cookieService.set("cart", JSON.stringify(this.localMap));
  }
  
  // pulisce il carrello dell'utente che ha effettuato il login
  clearLocalCart() {
    console.log("clear local cart");
    this.cookieService.delete("cart");
    this.localMap = {};
  }
}
