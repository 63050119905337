import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvertisingService } from 'src/app/services/advertising.service';
import { NotificationServiceService } from 'src/app/services/notification.service.service';



@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.css']
})
export class AdvertisingComponent implements OnInit {
  idShop;
  advertisingList;
  advertisingListOrdinata;
  pageSize = 5;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private advertisingService: AdvertisingService,
    private _lightbox: Lightbox,) { }

  ngOnInit() {
    this.getAdvertisingList();
    this.idShop = this.route.snapshot.paramMap.get("id");
  }

  getAdvertisingList(){
    this.spinner.show();
    this.advertisingService.getAdvertising().subscribe((x)=>{
      this.advertisingList = x;
      this.pageLength = x.length;
      this.advertisingListOrdinata = this.advertisingList;
      this.spinner.hide();
    },
    (err)=>{
      this.spinner.hide();
      alert("Qualcosa è andato storto!");
    })
  }

  deleteAdvertising(advertising){
   let r = confirm("Sei sicuro di voler eliminare questa pubblicità?");
    if (r){
      this.spinner.show();
      this.advertisingService.removeAdvertising(advertising.id).subscribe((x)=>{
        this.spinner.hide();
        alert("Rimozione avvenuta con successo");
        this.getAdvertisingList();
        
      },
      (err)=>{
        if(err.text.includes("cancellato!")){
          this.spinner.hide();
          alert("Rimozione avvenuta con successo");
          this.getAdvertisingList();
        
        }
      })
    }
  }

  


  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.advertisingListOrdinata = this.advertisingList.slice(offset).slice(0, event.pageSize);
  }

  open(src): void {
    let albums = [];
    const album = {
      src: src
    }
    albums.push(album)
    // open lightbox
    this._lightbox.open(albums);
  }
  
  
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
