import { Component, OnInit } from "@angular/core";
import { Farmacia } from "../../models/Farmacia";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../services/user.service";
import { Location } from "@angular/common";
import { OrderService } from "src/app/services/order.service";
import { CartService } from "src/app/services/cart.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-aggiungi-farmacia",
  templateUrl: "./aggiungi-farmacia.component.html",
  styleUrls: ["./aggiungi-farmacia.component.css"],
})
export class AggiungiFarmaciaComponent implements OnInit {
  nome: string;
  farmacia: Farmacia;
  value: boolean;
  idShop: any;
  prov: string;
  indirizzo: string;
  citta: any;
  nomePersonalizzato = {
/*     BONUS_POINTS: "Punti Bonus", */
    FIDELITY: "Premi/Fidelity Card",
    FLYER: "Volantino",
/*     REWARD: "Premi", */
    SLIDER_SHOW_OFFERS: "Offerte Slider"
  };
  allSezioni = [
   /*  {
        enabled: true,
        sectionEnum: "BONUS_POINTS",
    }, */
    { 
        enabled: true,
        sectionEnum: "FIDELITY",
    },
    {
        enabled : true,
        sectionEnum: "FLYER",
    },
  /*   {
        enabled: true,
        sectionEnum: "REWARD",
    }, */
    {
        enabled: true,
        sectionEnum: "SLIDER_SHOW_OFFERS",
    }
];
  arrayIndirizzi: string[] = [];
  constructor(
    private farmaciaService: FarmacieService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private orderService: OrderService,
    private cartService: CartService
  ) {
    this.farmacia = new Farmacia();
  }

  ngOnInit() {
    this.value = false;
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
        this.idShop = x.id;
      });

    if (this.router.url.includes("editFarmacia")) {
      this.value = true;
      const id = +this.route.snapshot.paramMap.get("id");
      this.farmaciaService.getFarmacia(id).subscribe(
        (farma) => {
          this.farmacia = farma;
          this.farmacia.deliveryService =
            this.farmacia.deliveryService == null
              ? "Nessun corriere"
              : this.farmacia.deliveryService;
          this.citta = farma.idCity.split(/[ ]|[(]|[)]/g)[0];
          this.prov = farma.idCity.split(/[ ]|[(]|[)]/g)[2];
          farma.idCity.split(/[ ]|[(]|[)]/g)[2]
            ? (this.prov = farma.idCity.split(/[ ]|[(]|[)]/g)[2])
            : (this.prov = " ");
          farma.vat
            ? (this.farmacia.vat = farma.vat)
            : (this.farmacia.vat = " ");
          this.farmacia.address =
            farma.address +
            ", " +
            farma.vat +
            " " +
            farma.idCity.replace(/[(]|[)]/g, "") +
            ", " +
            "Italia";
            this.allSezioni = farma.sections.sort((a, b) => {
              const nameA = a.sectionEnum.toUpperCase();
              const nameB = b.sectionEnum.toUpperCase();
            
              if (nameA < nameB) {
                return -1; 
              }
              if (nameA > nameB) {
                return 1; 
              }
              return 0;
            });
        },
        (e) => {}
      );
    }
  }

  onSubmit() {
    if (this.router.url.includes("/editFarmacia")) {
      this.modificaFarmacia();
    } else {
      this.aggiungiFarmacia();
    }
  }

  modificaFarmacia() {
    if (this.farmacia.deliveryService == "Nessun corriere")
      this.farmacia.deliveryService = null;
    let addressSplitted = this.farmacia.address.split(",");
    if (addressSplitted.length != 4 || addressSplitted[2].includes("Italia")) {
      // caso in cui non è presente il numero civico nell'indirizzo
      /*  addressSplitted[2] = ""; */
      //let cap = addressSplitted[1].split(" ")[1];
      //let address1 = addressSplitted[1].split(" ")[1].split(" ")[1];
      //console.log(addressSplitted[1]);
      alert(
        "Attenzione! l'indirizzo digitato è errato. Ricordati di inserire anche il numero civico e la sintassi corretta deve essere: Via, civico, Cap Comune Provincia, Italia"
      );
    } else if (addressSplitted[3].includes("Italia")) {
      // caso in cui è presente il numero civico nell'indirizzo
      addressSplitted[3] = "";
      let cap = addressSplitted[2].substring(1, 6);
      let indirizzo = addressSplitted[0];
      let civico = addressSplitted[1];
      let comune = addressSplitted[2].substring(
        7,
        addressSplitted[2].length - 2
      );
      let prov = addressSplitted[2].substring(
        addressSplitted[2].length - 2,
        addressSplitted[2].length
      );

      this.farmacia.idCity = comune + "(" + prov.toUpperCase() + ")";
      this.farmacia.vat = cap;
      let farmaciaToUpdate = new Farmacia();
   ;   farmaciaToUpdate.active = this.farmacia.active;
      farmaciaToUpdate.fseEnabled = this.farmacia.fseEnabled;
      farmaciaToUpdate.address = this.farmacia.address;
      farmaciaToUpdate.deliveryService = this.farmacia.deliveryService;
      farmaciaToUpdate.fullAddress = this.farmacia.fullAddress;
      farmaciaToUpdate.gifts = this.farmacia.gifts;
      farmaciaToUpdate.id = this.farmacia.id;
      farmaciaToUpdate.idCity = this.farmacia.idCity;
      farmaciaToUpdate.idDistrict = this.farmacia.idDistrict;
      farmaciaToUpdate.idEmployee = this.farmacia.idEmployee;
      farmaciaToUpdate.idRegion = this.farmacia.idRegion;
      farmaciaToUpdate.idSmoove = this.farmacia.idSmoove;
      farmaciaToUpdate.lat = this.farmacia.lat;
      farmaciaToUpdate.long_ = this.farmacia.long_;
      farmaciaToUpdate.meters = this.farmacia.meters;
      farmaciaToUpdate.msgUnread = this.farmacia.msgUnread;
      farmaciaToUpdate.name = this.farmacia.name;
      farmaciaToUpdate.openingTime = this.farmacia.openingTime;
      farmaciaToUpdate.phone = this.farmacia.phone;
      farmaciaToUpdate.vat = this.farmacia.vat;
      farmaciaToUpdate.email = this.farmacia.email;
      farmaciaToUpdate.address = addressSplitted[0] + "," + addressSplitted[1];
      farmaciaToUpdate.webUrl = this.farmacia.webUrl
      farmaciaToUpdate.sections = this.allSezioni;
      //console.log(this.fullAddress);

      this.farmaciaService.updateFarmacia(farmaciaToUpdate).subscribe(
        (farma) => {
          if (!farma) throw new Error();
          this.router.navigate(["/farmacie"]);
        },
        (err) => {}
      );
    }
  }

  aggiungiFarmacia() {
    if (this.farmacia.deliveryService == "Nessun corriere")
      this.farmacia.deliveryService = null;
    let addressSplitted = this.farmacia.address.split(",");

    if (addressSplitted.length != 4 || addressSplitted[2].includes("Italia")) {
      // caso in cui non è presente il numero civico nell'indirizzo
      /*  addressSplitted[2] = ""; */
      //let cap = addressSplitted[1].split(" ")[1];
      //let address1 = addressSplitted[1].split(" ")[1].split(" ")[1];
      //console.log(addressSplitted[1]);
      alert(
        "Attenzione! l'indirizzo digitato è errato. Ricordati di inserire anche il numero civico e la sintassi corretta deve essere: Via, civico, Cap Comune Provincia, Italia"
      );
    } else if (addressSplitted[3].includes("Italia")) {
      // caso in cui è presente il numero civico nell'indirizzo
      addressSplitted[3] = "";
      let cap = addressSplitted[2].substring(1, 6);
      let indirizzo = addressSplitted[0];
      let civico = addressSplitted[1];
      let comune = addressSplitted[2].substring(
        7,
        addressSplitted[2].length - 2
      );
      let prov = addressSplitted[2].substring(
        addressSplitted[2].length - 2,
        addressSplitted[2].length
      );
      this.farmacia.address = addressSplitted[0] + "," + addressSplitted[1];
      this.farmacia.idCity = comune + "(" + prov.toUpperCase() + ")";
      this.farmacia.vat = cap;
      this.farmacia.sections = this.allSezioni;
      this.farmaciaService.addFarmacia(this.farmacia).subscribe((farma) => {
        if (!farma) throw new Error();
        this.router.navigate(["/farmacie"]);
      });
    }
  }

  onBlurAddress() {
    let address: string[];
    let addressDetail: string[];
    let prov;
    let location: string = "";
    this.cartService.getLocation(this.farmacia.address).subscribe(
      (x) => {
        this.indirizzo = x.candidates[0].formatted_address;
        address = this.indirizzo.split(",");
        addressDetail = address[2].split(" ");
        addressDetail.splice(0, 1);
        this.farmacia.vat = addressDetail[0];
        addressDetail.splice(0, 1);
        prov = addressDetail[addressDetail.length - 1];
        addressDetail.splice(addressDetail.length - 1, 1);
        addressDetail.forEach((element, index) => {
          location += addressDetail[index + 1] ? element + " " : element;
        });
        this.farmacia.idCity = location + " (" + prov.toUpperCase() + ")";
      },
      (err) => {
        console.log("errore: " + err);
      }
    );
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((term) => term !== ""),
      switchMap((term) => {
        // console.log(term);
        /* this._service.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })) */
        return term.length < 4
          ? of([])
          : this.cartService.getLocation(term).pipe(
              switchMap((values) => {
                let tempAddress = [];
                this.arrayIndirizzi = [];
                tempAddress = values.candidates;

                tempAddress.forEach((x) => {
                  this.arrayIndirizzi.push(x.formatted_address);
                });

                return of(this.arrayIndirizzi);
              }),
              catchError(() => {
              /*   alert("errore") */
                return of([]);
              })
            );
      }),
      tap(() => [])
    );

  /* searchLocation = (text$: Observable<string>) =>
     text$.pipe(
       debounceTime(300),
       distinctUntilChanged(),
       map(term => {
         if (term !== "") {
           this.cartService.getLocation(term).subscribe((values) => {
             let tempAddress = [];
             this.arrayIndirizzi = [];
             tempAddress = values.candidates;
 
             tempAddress
               .forEach((x) => {
                 this.arrayIndirizzi.push(x.formatted_address);
               });
 
             return this.arrayIndirizzi;
           });
         } else {
           return this.arrayIndirizzi = [];
         }
       })
     );*/

  searchLocationWithTerm(term) {
    /*     this.cartService.getLocation(term).subscribe((values) => {
      let tempAddress = [];
      this.arrayIndirizzi = [];
      tempAddress = values.candidates;

      tempAddress.forEach((x) => {
        this.arrayIndirizzi.push(x.formatted_address);
      });
    });
    debounceTime(5000);
    return this.arrayIndirizzi; */
    this.arrayIndirizzi = [];
    this.googleApi(term);
  }
  googleApi(term) {
    /*    return await Promise.all([this.cartService.getLocation(term).toPromise()]).then(
      (values) => {
        let tempAddress = [];
        tempAddress = values[0].candidates;
        let tempFarm: string[] = [];

    
        tempAddress.forEach((element) => {
          tempFarm.push(element.formatted_address);
        });
  
      return tempFarm;
      }
    ); */
    this.cartService.getLocation(term).subscribe((values) => {
      let tempAddress = [];
      tempAddress = values.candidates;

      tempAddress.forEach((x) => {
        this.arrayIndirizzi.push(x.formatted_address);
      });
      return this.arrayIndirizzi;
    });
  }
}
