import { Component, OnDestroy } from "@angular/core";
import { Messaggio } from "src/app/models/messaggio";
import {
  Subject,
  from,
  merge,
  Observable,
  interval,
  observable,
  Subscription,
} from "rxjs";
import {
  switchMap,
  map,
  windowCount,
  scan,
  take,
  tap,
  max,
} from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import {
  ChatModule,
  Message,
  User,
  Action,
  ExecuteActionEvent,
  SendMessageEvent,
  Attachment,
} from "@progress/kendo-angular-conversational-ui";
import { ChatService } from "../../chat.service";
import { MessageService } from "src/app/services/message.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Lightbox } from "ngx-lightbox";
import { MessagingService } from "src/app/messaging.service";
import { ImageService } from "src/app/services/image.service";
import { ImageSnippet } from "../aggungi-evento/aggungi-evento.component";
import { NgxSpinnerService } from "ngx-spinner";
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true}
    )
  ])
]);

@Component({
  providers: [ChatService],
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  animations: [listAnimation]
})
export class MessagesComponent implements OnDestroy {
  selectedFile: ImageSnippet;
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  last_user;
  idrecivier;
  pageSize = 5;
  pageLength1: number;
  value: boolean;
  arrayRicevuti: Messaggio[];
  arrayInviati: Messaggio[];
  pageLength: number;
  listamessaggiRicevuti: Messaggio[];
  listamessaggiInviati: Messaggio[];
  surnameUser: string;
  nameUser: string;
  listautentiOrdinata: User[];
  id2: number;
  public feed: Observable<Message[]>;
  _albums: Array<any> = [];
  parolaChiave: string;
  listOrder$: Subscription;
  attachments: Attachment[] = [
    {
      content: "",
    },
  ];
  flag: boolean = false;
  messaggio: Messaggio = new Messaggio();
  readArray: Array<any> = [];
  maxCreated = 0;
  minCreated = 0;
  arrayCreated = [];
  updateFlag = 0;
  lastUsersList;
  automaticResponse;
  public sender: User = {
    id: 1,
    name: "Farmacia",
  };

  public recipient: User = {
    id: 0,
    name: "Utente",
  };

  private local: Subject<Message> = new Subject<Message>();
  nameFarmacia: string;

  constructor(
    private svc: ChatService,
    private route: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private notificatioService: NotificationServiceService,
    private farmacieService: FarmacieService,
    private _lightbox: Lightbox,
    private msgService: MessagingService,
    private imageService: ImageService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.showUsers().then(() => {
      this.getMessaggi();
    });
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("document is hidden");
      } else {
        if (this.userService.currentUserValue.role == "ROLE_EMPLOYEE") {
          this.showUsers();
          this.getMessaggi();
        }
      }
    });


    this.farmacieService.getAllAutomaticMessages().subscribe((x)=>{
      this.automaticResponse = x;
    })

 
    this.listOrder$ = this.msgService.currentMessage.subscribe(async (x) => {
      //console.log(x);
      if (x) {
        if (x.title.includes("Nuovo Messaggio")) {
          await this.showUsers();
          await this.getMessaggi();
          if (this.last_user) {
            await this.loadChat(this.last_user);
          }
          this.listautentiOrdinata.sort(function (a, b) {
            return b.created - a.created;
          });

          this.listamessaggiInviati.sort(function (a, b) {
            return a.created < b.created ? 1 : -1;
          });
        }
      }
    });

    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.id2 = x.shop;
        this.farmacieService.getFarmacia(this.id2).subscribe((u) => {
          this.nameFarmacia = u.name;
        });
      });
    if (this.router.url.includes("/messaggi/utente")) {
      // se l'url contiene la stringa allora fai quello  che sta nell'If
      const userId = this.route.snapshot.paramMap.get("userId");
      this.userService.getprofilebyId(+userId).subscribe(async (x) => {
        await this.getMessaggi();
        this.loadChat(x);
        this.listautentiOrdinata.sort(function (a, b) {
          return b.created - a.created;
        });

        this.listamessaggiInviati.sort(function (a, b) {
          return a.created < b.created ? 1 : -1;
        });
      });
    }
  }

  public sendMessage(e: SendMessageEvent): void {
    this.local.next(e.message);

    this.messaggio.idsender = this.sender.id;
    this.messaggio.idrecipient = this.recipient.id;
    this.messaggio.body = e.message.text ? e.message.text : e.message.toString();
    this.messaggio.created = Date.now();

    this.messageService.add(this.messaggio).subscribe(async (mess) => {
      if (!mess) {
        throw new Error();
      }
/*       this.invioNotifica(); */
      await this.getMessaggi();
      this.loadChat(this.last_user);
      this.router.navigate([`messaggi/${this.messaggio.idsender}`]);
    });
    /*   this.inviaMessaggi(e.message.text); */

    /*  this.svc.submit(e.message.text);  */
  }
  async showUsers() {
    return new Promise((resolve, reject) => {
      var listaUtenti: User[];
      const id_shop = this.route.snapshot.paramMap.get("id");
      this.userService.getallUsers(+id_shop).subscribe((users) => {
        listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
        listaUtenti = listaUtenti.sort(function (a, b) {
          return a.name < b.name ? 1 : -1;
        });
  
        if (this.listautentiOrdinata == null) {
          this.listautentiOrdinata = listaUtenti.slice();
          this.lastUsersList = this.listautentiOrdinata;
          resolve(this.listautentiOrdinata); 
        } else {
          listaUtenti.forEach((superelement, index) => {
            let flag = false;
            this.listautentiOrdinata.forEach((element, index) => {
              if (superelement.id == element.id) {
                flag = true;
              }
            });
            if (flag == false) {
              this.listautentiOrdinata.push(superelement);
            }
          });
          this.lastUsersList = this.listautentiOrdinata;
          resolve(this.listautentiOrdinata); 
        }
      });
    });
  }

  async getMessaggi() {
    const id = this.route.snapshot.paramMap.get("id");
    this.userService.getprofilebyId(+id).subscribe((x) => {
      this.nameUser = x.name;
      this.surnameUser = x.surname;
    });
    return await Promise.all([
      this.messageService.getMessageInviati(id).toPromise(),
      this.userService
        .get(this.userService.currentUserValue.account)
        .toPromise(),
      this.messageService.getMessageRicevuti(id).toPromise(),
    ]).then((values) => {
      const user = values[1];
      this.listamessaggiInviati = values[0];
      this.listamessaggiRicevuti = values[2];
      this.listamessaggiRicevuti.sort(function (a, b) {
        return a.id - b.id;
      });

      let i = -1;
      let j = 0;

      let k = 0;
      let newDate;
      this.listautentiOrdinata.forEach((x, index) => {
        if (!x.created) {
          x.created = 1;
        }
        /*     if (x.newMessage) { */
        newDate = new Date(x.created);
        x.created = newDate;
        /*      this.arrayCreated.push(x);
          this.listautentiOrdinata.splice(index, 1);
 */
        /* 
          if (newDate > this.maxCreated) {
            k;
            this.maxCreated = newDate;
          }
          this.listautentiOrdinata.sort(function (a, b) {
            return a.created < b.created ? 1 : -1;
          });
          console.log(this.listautentiOrdinata); */
        /*        newDate >= this.maxCreated
            ? this.arraymove(this.listautentiOrdinata, index, 0)
            : this.arraymove(this.listautentiOrdinata, index, k);
          k++; */
        /*   } else {
          x.created = 1;
        } */
      });

      /* this.readArray = new Array(this.listautentiOrdinata.length); */
      this.listautentiOrdinata.forEach((superelement) => {
        let flag: boolean = false;
        /*         let flagUser: boolean = false; */
        i++;
        j++;
        let maxUser ={
          created: new Date('02/03/1990')
        }; 
        if (this.readArray[i] != superelement.id) {
          this.listamessaggiRicevuti.forEach((element) => {
            if (superelement.id == element.idsender) {
              if(maxUser.created.getTime() <= new Date(element.created).getTime()){
                this.listautentiOrdinata[i].lastMessage = this.truncateText(element.body, 30);
                maxUser.created = new Date(element.created);
            }

            if(this.listautentiOrdinata[i].created < new Date(element.created))  this.listautentiOrdinata[i].created = element.created;
          
            
              if (element.read == null) {
                flag = true;
                superelement.newMessage = true;
              } else {
                superelement.newMessage = false;
              }
            }
            if (this.readArray[i] != superelement.id) {
              this.listamessaggiInviati.forEach((secondelement) => {
                if (superelement.id == secondelement.idrecipient) {
                  if (secondelement.created > element.created) {
                    if(maxUser.created.getTime() <= new Date(secondelement.created).getTime()){
                      this.listautentiOrdinata[i].lastMessage = 'Tu: '+this.truncateText(secondelement.body, 30);
                      maxUser.created = new Date(secondelement.created);
                    }
                    if(this.listautentiOrdinata[i].created < new Date(secondelement.created)) this.listautentiOrdinata[i].created = secondelement.created;

                  }
                }
              });
            }
          });
          /* 
          if (flag) {
            if(){
         
            }
          } */
        }
      });
   
      if (this.updateFlag == 0) {
        this.listautentiOrdinata.sort(function (a, b) {
          return b.created - a.created;
        });
        this.listamessaggiInviati.sort(function (a, b) {
          return a.created < b.created ? 1 : -1;
        });
      }
      /*  this.listautentiOrdinata.sort(function (a, b) {
        return a.read < b.read ? 1 : -1;
      }); */
      this.pageLength1 = this.listamessaggiInviati.length;
      this.arrayInviati = this.listamessaggiInviati
        .slice((0 + 1 - 1) * this.pageSize)
        .slice(0, this.pageSize);
      /* 
      this.listamessaggiRicevuti.sort(function (a, b) {
        return a.created < b.created ? 1 : -1;
      }); */
      this.pageLength = this.listamessaggiRicevuti.length;
      this.arrayRicevuti = this.listamessaggiRicevuti
        .slice((0 + 1 - 1) * this.pageSize)
        .slice(0, this.pageSize);
        this.spinner.hide();
    });
  }
  
  ngOnDestroy(): void {
    this.listOrder$.unsubscribe();
  }

  async loadChat(user) {
    this.getMessaggi();
    if (user) this.last_user = user;
    this.flag = true;
    let messages: Message[] = [];
    this.sender = {
      id: this.route.snapshot.paramMap.get("id"),
      name: "Farmacia",
    };
    this.recipient = {
      id: user.id,
      name: user.name + " " + user.surname,
    };
    this.listamessaggiInviati.forEach((element) => {
      if (user.id == element.idrecipient) {
        const created: Date = new Date(element.created);
        /*  console.log("messaggi inviati: " + element); */
        if (element.body.includes("jpeg") || element.body.includes("jpg")) {
          const album = {
            src: element.body,
          };

          this._albums.push(album);
          const msg: Message = {
            author: this.sender,
            text: '📷',
            timestamp: created,
            attachments: [{ content: element.body }],
          };
          messages.push(msg);
        } else {
          const msg: Message = {
            author: this.sender,
            text: element.body,
            timestamp: created,
          };
          messages.push(msg);
        }
      }
    });

    this.listamessaggiRicevuti.forEach((element) => {
      if (user.id == element.idsender) {
        this.messageService.setState(element).subscribe((x) => {});
        const read: Date = new Date(element.read);
        const created: Date = new Date(element.created);
        if (element.body.includes("jpeg") || element.body.includes("jpg")) {
          const album = {
            src: element.body,
          };

          this._albums.push(album);

          const msg: Message = {
            author: this.recipient,
            text: undefined,
            timestamp: created,
            read: read ? true : false,
            attachments: [{ content: element.body }],
          };

          messages.push(msg);
          /*          console.log(msg); */
        } else {
          const msg: Message = {
            author: this.recipient,
            text: element.body,
            timestamp: created,
            read: read ? true : false,
          };
          messages.push(msg);
        }
      }
    });
    messages.sort(function (a, b) {
      return a.timestamp.getTime() - b.timestamp.getTime();
    });
    this.feed = merge(
      messages,
      //TODO: aggiungere array messaggi
      this.local
    ).pipe(scan((acc: Message[], x: Message) => [...acc, x], []));
  }

  /* invioNotifica() {
    let corpomessaggio = this.messaggio.body;
    let id = this.messaggio.idrecipient;
    corpomessaggio = corpomessaggio.includes("mediaTemp")
      ? "📷 Foto"
      : corpomessaggio;
    this.userService.getprofilebyId(id).subscribe((y) => {
      let token = y.fbtoken;
      this.notificatioService.invionotifica(
        [token],
        corpomessaggio,
        "Nuovo messaggio da " +
          this.nameFarmacia 
      );
    });
  } */
  changeValuePosition(arr, i, target) {
    [arr[i], arr[target]] = [arr[target], arr[i]];
    return arr;
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  /*   inviaMessaggi(text) {
    let j;
      this.messaggio.forEach((value) => {
        j++;
        if (value[j].body =='') {
          value.body = text;
          for (var i = 0; i <= this.messaggio.length; i++) {
            this.messageService.add(this.messaggio[i]).subscribe((mess) => {
              if (!mess) {
                throw new Error();
              }
               this.invioNotifica();
              this.router.navigate([`messaggi/${this.messaggio[i].idsender}`]);
            });
          }
        }
        console.log(value);
        this.messaggio.push(value);
      });
    }
     */
  getUserSession() {
    return localStorage.getItem("last_user");
  }

  setUserSession(last_user) {
    localStorage.setItem("last_user", last_user);
  }
  open(path): void {
    let i;
    this._albums.forEach((element, index) => {
      if (path == element.src) {
        i = index;
      }
    });
    // open lightbox
    this._lightbox.open(this._albums, i);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  onChangeFilter(event: any): any {
    this.listautentiOrdinata = this.lastUsersList;
    if (event != "") {
      this.listautentiOrdinata = this.listautentiOrdinata.filter(
        (v) =>
          v.surname.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
          v.name.toLowerCase().indexOf(event.toLowerCase()) > -1
      );
    }
  }

  processFile(imageInput: any) {
    let stringToCompare: string = imageInput.files[0].name;
    if (
      stringToCompare.split(".")[1] == "jpeg" ||
      stringToCompare.split(".")[1] == "jpg"
    ) {
      this.messaggio.body = null;
      const file: File = imageInput.files[0];
      const reader = new FileReader();
      imageInput.value = "";
      reader.addEventListener("load", (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.imageService
          .uploadMedia64(file.name, this.selectedFile.src.split(",")[1])

          .subscribe((x) => {
            this.messaggio.body = x.url;
            this.messaggio.idsender = this.sender.id;
            this.messaggio.idrecipient = this.recipient.id;
            this.messaggio.body = x.url;
            this.messaggio.created = Date.now();

            this.messageService.add(this.messaggio).subscribe(async (mess) => {
              if (!mess) {
                throw new Error();
              }
   /*            this.invioNotifica(); */
              await this.getMessaggi();
              this.loadChat(this.last_user);
            });
          });
      });

      reader.readAsDataURL(file);
    } else {
      alert("Attenzione! puoi caricare solo immagini jpeg o jpg.");
    }
  }

  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  openImageUploader(): void {
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.accept = 'image/*';
    imageInput.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files[0];
      this.processFile(file);
    });
    imageInput.click();
  }
  onSelectedAutomaticMessage(message){
    if(message.value != ''){
      const mess: any = {
        author: {id: this.route.snapshot.paramMap.get("id"), name: 'Farmacia'},
        text: message.value,
        timestamp: new Date()

      }
    this.local.next(mess);

    this.messaggio.idsender = this.sender.id;
    this.messaggio.idrecipient = this.recipient.id;
    this.messaggio.body = mess.text;
    this.messaggio.created = Date.now();

    this.messageService.add(this.messaggio).subscribe(async (mess) => {
      if (!mess) {
        throw new Error();
      }

      await this.getMessaggi();
      this.loadChat(this.last_user);
      this.router.navigate([`messaggi/${this.messaggio.idsender}`]);
    });
    }
  }
}


