import 'hammerjs';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppModule } from "./app/app.module";
import { NgModule } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { IntlModule } from "@progress/kendo-angular-intl";

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, AppModule],
  bootstrap: [AppComponent],
 
})
export class BootstrapModule {
}



platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    // Otherwise, log the boot error
  });
//.catch(err => console.error(err));
