import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FullCalendarModule } from "@fullcalendar/angular";
import { AppComponent } from "./app.component";
import { NavigationComponent } from "./parts/navigation/navigation.component";
import { CardComponent } from "./pages/card/card.component";
import { PaginationComponent } from "./parts/pagination/pagination.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { DetailComponent } from "./pages/product-detail/detail.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CartComponent } from "./pages/cart/cart.component";
import { CookieService } from "ngx-cookie-service";
import { ErrorInterceptor } from "./_interceptors/error-interceptor.service";
import { JwtInterceptor } from "./_interceptors/jwt-interceptor.service";
import { OrderComponent } from "./pages/order/order.component";
import { OrderDetailComponent } from "./pages/order-detail/order-detail.component";
import { DialogFlyer, DialogPhoto, ProductListComponent } from "./pages/product-list/product.list.component";
import {
  DialogUser,
  DialogUserTelegram,
  UserDetailComponent,
} from "./pages/user-edit/user-detail.component";
import { ProductEditComponent } from "./pages/product-edit/product-edit.component";
import { VolantinoComponent } from "./volantino/volantino.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
// import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { FarmaciaComponent } from "./pages/farmacia/farmacia.component";
import { FormsModule } from "@angular/forms";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AggiungiFarmaciaComponent } from "./pages/aggiungi-farmacia/aggiungi-farmacia.component";
import { MessagesComponent } from "./pages/messages/messages.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CalendarioComponent } from "src/app/pages/calendario/calendario.component";
import { AggungiEventoComponent } from "./pages/aggungi-evento/aggungi-evento.component";
import { NuovoMessaggioComponent } from "./pages/nuovo-messaggio/nuovo-messaggio.component";
import { GrdFilterPipe } from "./filter";
import {
  OrderEmployeeComponent,
  DialogOrderEmployee,
} from "./pages/order-employee/order-employee.component";
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { UsersComponent } from "./pages/users/users.component";
import { FarmaciaEditComponent } from "./pages/farmacia-edit/farmacia-edit.component";
import { EventoEditComponent } from "./pages/evento-edit/evento-edit.component";
import { OrderDetailConfermedComponent } from "./pages/order-detail-confermed/order-detail-confermed.component";
import { HttpClientModule } from "@angular/common/http";
import { OrderModule } from "ngx-order-pipe";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "src/app/messaging.service";
import { environment } from "../environments/environment";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { EntryPointComponent } from "./pages/entry-point/entry-point.component";
import { PopupMessageComponent } from "./pages/popup-message/popup-message.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NavigationBarComponent } from "./parts/navigation-bar/navigation-bar.component";
import { ToastrModule, ToastContainerModule } from "ngx-toastr";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { LightboxModule } from "ngx-lightbox";

/** TODO: remove when work-around is not needed*/
import "hammerjs";
import { ToasterComponent } from "./toaster/toaster.component";
import { SpedizioneComponent } from "./pages/spedizione/spedizione/spedizione.component";
import { ChatModule } from "@progress/kendo-angular-conversational-ui";
import { OrderSpedizioneComponent } from "./pages/order-spedizione/order-spedizione.component";
import { OrderSpedizioneConfermedComponent } from "./pages/order-spedizione-confermed/order-spedizione-confermed.component";
import { ManagerDashboardComponent } from "./pages/manager-dashboard/manager-dashboard.component";
import { ManagersUsersComponent } from "./pages/managers-users/managers-users.component";
import { ChartsModule } from "ng2-charts";
import {
  DialogCouponManager,
  NewCouponComponent,
} from "./pages/new-coupon/new-coupon.component";
import { CompilaCouponComponent } from "./pages/compila-coupon/compila-coupon.component";
import { InfoCouponComponent } from "./pages/info-coupon/info-coupon.component";

import {
  CouponlistEmployeeComponent,
  DialogCouponEmployee,
} from "./pages/couponlist-employee/couponlist-employee.component";
import { InfoCouponEmployeeComponent } from "./pages/info-coupon-employee/info-coupon-employee.component";
import { CreaSpedizioneComponent } from "./pages/crea-spedizione/crea-spedizione.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import { InteressatiInfoComponent } from "./pages/interessati-info/interessati-info.component";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { NgxBarcodeModule } from "ngx-barcode";
import { PrizeDetailComponent } from "./pages/prize-detail/prize-detail.component";
import {
  PrizeManagementComponent,
  DialogReward,
} from "./pages/prize-management-old/prize-management.component";
import { PrizeDetailConfermedComponent } from "./pages/prize-detail-confermed/prize-detail-confermed.component";
import { AppUserDetailComponent } from "./pages/app-user-detail/app-user-detail.component";

import "@progress/kendo-angular-intl/locales/it/all";
import { SliderNewsComponent } from './pages/slider-news/slider-news.component';
import { SliderNewsAddComponent } from './pages/slider-news-add/slider-news-add.component';
import { SliderNewsModifyComponent } from './pages/slider-news-modify/slider-news-modify.component';
import { SliderNewsInfoComponent } from './pages/slider-news-info/slider-news-info.component';
import { ProductDiscountComponent } from './pages/product-discount/product-discount.component';
import { ProductDiscountAddingComponent } from './pages/product-discount-adding/product-discount-adding.component';
import { ProductDiscountModifyComponent } from './pages/product-discount-modify/product-discount-modify.component';
import { NgxColorsModule } from 'ngx-colors';
import { ProductDiscountInfoComponent } from './pages/product-discount-info/product-discount-info.component';
import { AdvertisingComponent } from './pages/advertising/advertising.component';
import { AddAdvertisingComponent } from './pages/add-advertising/add-advertising.component';
import { AllServicesAppointmentComponent } from './pages/all-services-appointment/all-services-appointment.component';
import { NewServicesComponent } from './pages/new-services/new-services.component';
import { ServiceListComponent } from './pages/service-list/service-list.component';
import { ServiceInfoComponent } from './pages/service-info/service-info.component';
import { OrderBonusListComponent } from './pages/order-bonus-list/order-bonus-list.component';
import { AutomaticResponsesComponent } from './pages/automatic-responses/automatic-responses.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalOrderEmployeeComponent } from './pages/order-employee/modal-order-employee/modal-order-employee.component';
import { CheckPrizeCode, PrizeListComponent } from './pages/prize-list/prize-list.component';
import { MatInputModule } from '@angular/material/input';
registerLocaleData(localeIt);
@NgModule({
  declarations: [
    GrdFilterPipe,
    CalendarioComponent,
    AppComponent,
    NavigationComponent,
    CardComponent,
    PaginationComponent,
    LoginComponent,
    SignupComponent,
    DetailComponent,
    CartComponent,
    OrderComponent,
    OrderDetailComponent,
    ProductListComponent,
    UserDetailComponent,
    ProductEditComponent,
    VolantinoComponent,
    FarmaciaComponent,
    AggiungiFarmaciaComponent,
    MessagesComponent,
    AggungiEventoComponent,
    NuovoMessaggioComponent,
    OrderEmployeeComponent,
    PasswordResetComponent,
    UsersComponent,
    FarmaciaEditComponent,
    EventoEditComponent,
    OrderDetailConfermedComponent,
    EntryPointComponent,
    PopupMessageComponent,
    NavigationBarComponent,
    ToasterComponent,
    SpedizioneComponent,
    OrderSpedizioneComponent,
    OrderSpedizioneConfermedComponent,
    ManagerDashboardComponent,
    ManagersUsersComponent,
    NewCouponComponent,
    CompilaCouponComponent,
    InfoCouponComponent,
    CouponlistEmployeeComponent,
    InfoCouponEmployeeComponent,
    CreaSpedizioneComponent,
    InteressatiInfoComponent,
    ErrorPageComponent,
    DialogCouponEmployee,
    DialogUser,
    DialogCouponManager,
    DialogOrderEmployee,
    DialogReward,
    CheckPrizeCode,
    DialogUserTelegram,
    DialogFlyer,
    DialogPhoto,
    PrizeDetailComponent,
    PrizeManagementComponent,
    PrizeDetailConfermedComponent,
    AppUserDetailComponent,
    SliderNewsComponent,
    SliderNewsAddComponent,
    SliderNewsModifyComponent,
    SliderNewsInfoComponent,
    ProductDiscountComponent,
    ProductDiscountAddingComponent,
    ProductDiscountModifyComponent,
    ProductDiscountInfoComponent,
    AdvertisingComponent,
    AddAdvertisingComponent,
    AllServicesAppointmentComponent,
    NewServicesComponent,
    ServiceListComponent,
    ServiceInfoComponent,
    OrderBonusListComponent,
    AutomaticResponsesComponent,
    ModalOrderEmployeeComponent,
    PrizeListComponent,

  ],
  imports: [
    LightboxModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    BrowserModule,
    NoopAnimationsModule, 
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    OrderModule,
    FullCalendarModule,
    NgbModule,
    FormsModule,
    NgbModalModule,
    PdfViewerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    NgxColorsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    NgxBarcodeModule.forRoot(),
    ServiceWorkerModule.register("firebase-messaging-sw.js", {
      enabled: environment.production,
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      closeButton: true,
      disableTimeOut: true,
      tapToDismiss: true,
      toastClass: "toast-base",
    }),
    ToastContainerModule,
    MatMenuModule,
    MatIconModule,
    ChatModule,
    ChartsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgxSpinnerService,
    MessagingService,
    AsyncPipe,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatDatepickerModule,
    {
      // Set the default locale to it
      provide: LOCALE_ID,
      useValue: "it-IT",
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogCouponEmployee,
    DialogUser,
    DialogCouponManager,
    DialogOrderEmployee,
    DialogReward,
    CheckPrizeCode,
    DialogUserTelegram,
    DialogFlyer,
    DialogPhoto,
    ModalOrderEmployeeComponent
  ],
})
export class AppModule {}

//platformBrowserDynamic().bootstrapModule(AppModule);
