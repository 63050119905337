/* import { ProductInOrder } from "./ProductInOrder";
 */
export class ProductInfo {
  productId: string;
  productName: string;
  productPrice: number;
  oldproductPrice: number;
  productStock: number;
  productDescription: string;
  productIcon: string;
  productStatus: number; // 0: onsale 1: offsale
  categoryType: number;
  createTime: string;
  updateTime: string;
  available: boolean;
  points: number;
  productType?;
  constructor(productInOrder?) {
    if (productInOrder) {
      this.productId = productInOrder.productId;
      this.productName = productInOrder.productName;
      this.oldproductPrice = productInOrder.productPrice;
      this.productPrice = productInOrder.productPrice;
      this.productStock = productInOrder.productStock;
      this.productDescription = productInOrder.productDescription;
      this.productIcon = productInOrder.productIcon;
      this.categoryType = productInOrder.categoryType;
      this.productStatus = 0;
      this.available = false;
    } else {
      this.productId = "";
      this.productName = "";
      this.productPrice = 0;
      this.productStock = 0;
      this.oldproductPrice = 0;
      this.productDescription = "";
      this.productIcon = "";
      this.categoryType = 0;
      this.productStatus = 0;
      this.available = false;
    }
  }
}
