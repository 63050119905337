import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpHeaderResponse,
  HttpBackend,
} from "@angular/common/http";
import { apiUrl } from "../../environments/environment";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError, tap, map, retry } from "rxjs/operators";
import { JwtResponse } from "../response/JwtResponse";
import { CookieService } from "ngx-cookie-service";
import { User } from "../models/User";
import { MessagingService } from "src/app/messaging.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Card } from "../models/Card";
import { Spedizione } from "../models/Spedizione";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private httpClient: HttpClient;
  private currentUserSubject: BehaviorSubject<JwtResponse>;
  public currentUser: Observable<JwtResponse>;
  public nameTerms = new Subject<string>();
  public name$ = this.nameTerms.asObservable();
  listToken: string[];
  idUser: string;
  codiceFarmacia: number;
  nomeUtente: string;
  message: BehaviorSubject<any>;
  constructor(
    private toastService: ToastrService,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private msgService: MessagingService,
    private httpBackend: HttpBackend
  ) {
    this.httpClient = new HttpClient(httpBackend);
    this.message = new BehaviorSubject(null);
    const memo = localStorage.getItem("currentUser");
    this.currentUserSubject = new BehaviorSubject<JwtResponse>(
      JSON.parse(memo)
    );

    this.currentUser = this.currentUserSubject.asObservable();
    // this.currentUser;
    cookieService.set("currentUser", memo);
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  httpOptions3 = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
    }),
  };

  httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "text/plain",
    }),
  };

  get currentUserValue() {
    return this.currentUserSubject.value;
  }

  setToken() {
    this.msgService.requestPermission();
    this.msgService.messaging.getToken();
    this.msgService.messaging.onTokenRefresh(() => {
      this.msgService.messaging.getToken().then((tokenchange) => {
        this.updatetokenFirebase(tokenchange).subscribe((x) => {});
      });
    });
  }

  login(loginForm): Observable<JwtResponse> {
    this.setToken();
    const url = `${apiUrl}/login`;

    return this.http.post<JwtResponse>(url, loginForm, {}).pipe(
      tap((user) => {
        if (loginForm.remembered) {
          localStorage.setItem("currentUser", JSON.stringify(user));
        }

        return this.entryLogin(user);
      }),

      map((user) => {
        return this.entryLogin(user);
      }),

      catchError(this.handleError("Login Failed", null))
    );
  }

  silentLogin(loginForm, managerCredentials): Observable<any> {
    /*  this.setToken(); */

    const url = `${apiUrl}/managerlogin`;
    loginForm.token = managerCredentials.token;
    return this.http.post<any>(url, loginForm, this.httpOptions).pipe(
      tap((user) => {
        if (loginForm.remembered) {
          localStorage.setItem("currentUser", JSON.stringify(user));
        }

        return this.entryLogin(user, managerCredentials);
      }),

      map((user) => {
        console.log("utente vale; " + user);
        return this.entryLogin(user, managerCredentials);
      }),

      catchError(this.handleError("Login Failed", null))
    );
  }

  entryLogin(user: JwtResponse, managerCredentials?) {
    if (managerCredentials == undefined) {
      if (user && user.token) {
        this.cookieService.set("currentUser", JSON.stringify(user));
        // console.log(user.name);
        this.nameTerms.next(user.name);
        this.currentUserSubject.next(user);
        this.get(this.currentUserValue.account).subscribe((x) => {
          this.idUser = x.id;
          this.codiceFarmacia = x.idshop;
          this.nomeUtente = x.name;
        });

        this.currentUser = this.currentUserSubject.asObservable();

        /*      try {
          this.msgService.tokenFirebase.subscribe((tok) => {
            this.updatetokenFirebase(tok).subscribe((x) => {});
          });

          this.msgService.messaging.onTokenRefresh(() => {
            this.msgService.messaging.getToken().then((tokenchange) => {
              this.updatetokenFirebase(tokenchange).subscribe((x) => {});
            });
          });
          this.msgService.requestPermission();
          this.msgService.receiveMessage();
        } catch (err) {
          console.log(err);
        } */

        return user;
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      /*       user.token = managerCredentials.token; */
      if (user && user.token) {
        this.cookieService.set("currentUser", JSON.stringify(user));
        // console.log(user.name);
        this.nameTerms.next(user.name);
        this.currentUserSubject.next(user);
        this.get(this.currentUserValue.account).subscribe((x) => {
          this.idUser = x.id;
          this.codiceFarmacia = x.idshop;
          this.nomeUtente = x.name;
        });

        this.currentUser = this.currentUserSubject.asObservable();

        try {
          this.msgService.tokenFirebase.subscribe((tok) => {
            this.updatetokenFirebase(tok).subscribe((x) => {});
          });

          this.msgService.messaging.onTokenRefresh(() => {
            this.msgService.messaging.getToken().then((tokenchange) => {
              this.updatetokenFirebase(tokenchange).subscribe((x) => {});
            });
          });
          this.msgService.requestPermission();
          this.msgService.receiveMessage();
        } catch (err) {
          console.log(err);
        }

        return user;
      } else {
        this.router.navigate(["/login"]);
      }
    }
  }

  logout() {
    this.currentUserSubject.next(null);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("order_ordinamento");
    this.cookieService.delete("currentUser");
    this.toastService.clear();
  }
  //funzione che permette di registrare un nuovo Employee
  registerEmployee(user: User): Observable<User> {
    const url = `${apiUrl}/registeremployee`;
    return this.http.post<User>(url, user, this.httpOptions);
  }
  //metodo che aggiorna i dati dell'utente
  update(user: User): Observable<User> {
    const url = `${apiUrl}/profile`;
    return this.http.put<User>(url, user);
  }
  //metodo che setta il codice fiscale dell'utente
  setFiscalCode(id, fiscalCode): Observable<any> {
    const url = `${apiUrl}/setfiscalcode/${id}/${fiscalCode}`;
    return this.http.post<any>(url, null);
  }
  //metodo che invia i parametri di user
  updateSpedizione(user: Spedizione[], id, idShop?): Observable<Spedizione> {
    let shop = idShop ? `?shopId=${idShop}` : ''
    const url = `${apiUrl}/delivery/new/${id}${shop}`;
    return this.http.post<Spedizione>(url, user).pipe(
      catchError(this.ottieniErrore) // then handle the error
    );
  }
  /* addParcel(): Observable<Spedizione> {
    const url = `${apiUrl}/delivery/newconsegna/${id}`;
    return this.http.post<Spedizione>(url, user).pipe(
      catchError(this.ottieniErrore) // then handle the error
    );
  } */

  //metodo che ritorna i dati della delivery in base all'id dell'ordine
  getDelivery(id): Observable<Spedizione> {
    const url = `${apiUrl}/getdelivery/${id}`;
    return this.http.get<Spedizione>(url);
  }

  getPdfto64(idPdf, sede): Observable<string> {
    const url = `${apiUrl}/delivery/pdfConsegna/${idPdf}/${sede}`;
    return this.http.get<string>(url);
  }

  getPdfOtherCourier(orderId): Observable<Blob> {
    return this.http.get(`${apiUrl}/delivery/pdf/${orderId}`, {
      responseType: "blob",
    });
  }

  sendPdfAlert(idOrdine) {
    const url = `${apiUrl}/pdffailed/${idOrdine}`;
    return this.http.get<any>(url);
  }

  sendMail(email, object, message) {
    const url = `${apiUrl}/sendemail/${email}/${object}/${message}`;
    return this.http.get<any>(url);
  }

  //metodo che cancella una delivery
  deleteDelivery(idOrdine): Observable<any> {
    const url = `${apiUrl}/deletedelivery/${idOrdine}`;
    let status: any;
    console.log(this.http.delete<Spedizione>(url, { observe: "response" }));
    return this.http.delete<Spedizione>(url, { observe: "response" });
  }

  deleteUsers(idUser): Observable<any> {
    const url = `${apiUrl}/deleteuser/${idUser}`;
    return this.http.delete<any>(url, { observe: "response" });
  }
  //metodo che ritorna i dati dell'utente in base all'email
  get(email: string): Observable<User> {
    const url = `${apiUrl}/profile/${email}`;
    return this.http.get<User>(url);
  }
  //metodo che ritorna i dati dell'utente in base all'id
  getprofilebyId(id: number): Observable<User> {
    const url = `${apiUrl}/getprofilebyid/${id}`;
    return this.http.get<User>(url);
  }
  //metodo che ritorna il numero della fidelity card
  giftcardbyid(id: string): Observable<Card> {
    const url = `${apiUrl}/giftcardbyid/${id}`;
    return this.http.get<Card>(url);
  }
  getUsersByGiftcards(body): Observable<any> {
    /*   body = {
      numberCards: body,
    }; */
    const url = `${apiUrl}/getusersbygiftcards?numbers=${body}`;
    return this.http.get<any>(url);
  }

  //metodo che ritorna i dati dell'utente in base al numero di telefono
  getProfile(phone: string): Observable<User> {
    const url = `${apiUrl}/getprofile/${phone}`;
    return this.http.get<User>(url);
  }

  getTelegramUsersList(idshop): Observable<any> {
    const url = `${apiUrl}/getAllTelegramchat/${idshop}`;
    return this.http.get<any>(url);
  }

  deleteTelegramUserFromList(telegramId): Observable<any> {
    const url = `${apiUrl}/deletetelegramchat/${telegramId}`;
    return this.http.delete<any>(url);
  }
  // metodo che ritorna l'utente in base alla giftcard
  getProfileByGiftcard(idgiftcard): Observable<User> {
    const url = `${apiUrl}/getuserbygiftcard/${idgiftcard}`;
    return this.http.get<User>(url);
  }

  // metodo che ritorna un file csv mentre ne uploadi un altro
  getCsvUserByGiftcardFile(csv): Promise<any> {
    var formdata = new FormData();
    formdata.append("file", new Blob([csv], { type: "text/csv" }), csv.name);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/getreportgiftcardforcsv`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
    /*    const url = `${apiUrl}/getreportgiftcardforcsv`;

    let data = fetch(url, {
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
      body: csv,
    });

    console.log(data);
    return data; */
    /*  return this.httpClient.post<any>(url, csv, {
      headers: new HttpHeaders({
        "Content-Type": "text/csv",
      }),
    });*/
  }

  // metodo che ci permette di ottenere una password temporanea
  forgotpassword(phone: string) {
    const url = `${apiUrl}/forgetpassword/${phone}`;
    return this.http.get(url);
  }
  //metodo che ritorna una lista degli utenti in base alla farmacia preferita
  getallUsers(id_shop: number): Observable<any> {
    const url = `${apiUrl}/getprofilebyidshop/${id_shop}`;
    return this.http.get(url, this.httpOptions);
  }

  getCsvFile(): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getuserforcsv`);
  }
  //metodo che ritorna una lista degli utenti in base ai filtri
  getUsersByFilters(
    city: string,
    sex: string,
    yearStart: string,
    yearEnd: string,
    shop: number
  ) {
    let c = city ? "&city=" + city : "";
    let s = sex ? "&sex=" + sex : "";
    let yS = yearStart ? "&yearStart=" + yearStart : "";
    let yE = yearEnd ? "&yearEnd=" + yearEnd : "";
    let sh = shop ? "&shop=" + shop : "";

    return this.http.get<User[]>(`${apiUrl}/user?${c}${s}${yS}${yE}${sh}`);
  }

  updatetokenFirebase(fbtoken: string): Observable<any> {
    const url = `${apiUrl}/updatefbtoken/${this.idUser}`;
    return this.http.put(url, fbtoken, this.httpOptions2);
  }

  getDashboardCard(idShop): Observable<any> {
    return this.http.get<any>(`${apiUrl}/dashboardcard/${idShop}`);
  }

  getUserByParameter(parameter): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getUser?filter=${parameter}`);
  }
  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  ottieniErrore(error: HttpErrorResponse) {
    if (error.status != 200) {
      window.alert(
        "Attenzione, è possibile che tutti i campi non siano stati compilati correttamente o che l'indirizzo da lei digitato non sia presente nello stradario"
      );
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("Un errore è apparso:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Il Backend ha ritornato l'errore ${error.status}`);
    }
    // return an observable with a user-facing error message
    return throwError("Qualcosa è andato storto, riprova per favore");
  }
}
