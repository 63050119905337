export enum OrderStatus {
  "Inviato",
  "Confermato",
  "Annullato",
  "In attesa di preventivo",
  "Preventivo elaborato",
  "Ordine ritirato",
  "Spedizione in attesa di preventivo",
  "In attesa di pagamento",
  "Spedizione in elaborazione",
  "Ordine spedito",
  "Ordine consegnato",
}

export enum RewardStatus {
  "In attesa di accettazione",

  "Richiesta premio evasa",

  "Richiesta premio annullata",

  /* "Richiesta premio in elaborazione", */

  "In attesa di preventivo",

  "Richiesta premio elaborata",

  "Premio ritirato",

  "Richiesta Premio in elaborazione",

  "In spedizione",

  "Ritirato",
}

export enum RewardStatusNew {
  "",
  "Confermato",

  "Annullato",

  "In attesa di conferma",

  "Premio in farmacia",

  "Premio ritirato",

}
