import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { JwtResponse } from "../response/JwtResponse";
import { EventoDettagli } from '../models/eventoDettagli';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  public currentUser: Observable<JwtResponse>;
  listToken: string[];
  evento: EventoDettagli;

  httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "key=AIzaSyBrmNjDUaCed7aRzwapmw0vjYB4M9M0Wl4"
    })
  };

  constructor(
    private http: HttpClient,
  ) { }
  // metodo che invia notifiche a multipli utenti
  invionotifica(tkn: string[], testo: string, titolo: string) {
    return this.http.post(
      "https://fcm.googleapis.com/fcm/send",
      {
        registration_ids: tkn,
        notification: {
          sound: "default",
          body: testo,
          title: titolo,
          icon: 'ic_notification'        
        }
      },
      this.httpOptions2
    ).subscribe(z => { });
  }

}
