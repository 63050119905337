export enum OrderDeliveryStatus {
    "In attesa di spedizione",
    "In spedizione",
    "In consegna ",
    "In attesa di preventivo",
    "Consegnato  	",
    "Non consegnato"
    
  
  }
  