import { Role } from "../enum/Role";

export class User {
  id: string;

  email: string;

  surname: string;

  password: string;

  name: string;

  phone: string;

  address: string;

  active: boolean;

  role: string;

  idshop: number;

  fbtoken: string;

  shop: number;

  sex: string;

  privacykey3: boolean;

  privacykey2: boolean;

  privacykey1: boolean;

  idCity: string;

  vat: string;

  GiftCardSerial: string;

  born: string;

  idGiftCard: string;

  nomeShop?: string;

  fiscalCode?: string;

  telegramCode?: string;
  constructor() {}
}
