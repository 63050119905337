import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { ActivatedRoute, Router } from "@angular/router";
import { Farmacia } from "src/app/models/Farmacia";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import timeGrigPlugin from "@fullcalendar/daygrid";
import itLocale from "@fullcalendar/core/locales/it";
import { UserService } from "../../services/user.service";
import { Location, formatDate } from "@angular/common";
import interactionPlugin from "@fullcalendar/interaction"; // for dateClick
import { Role } from "src/app/enum/Role";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { DoctorServices } from "src/app/services/doctorServices.service";
import { Lightbox } from "ngx-lightbox";


@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent implements OnInit {
  idShop;
  serviceList;
  serviceListOrdinata;
  pageSize = 5;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  searchTermTemp;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private doctorService: DoctorServices,
    private _lightbox: Lightbox,
  ) {


  }

  ngOnInit() {
    this.spinner.show();
    this.idShop = this.route.snapshot.paramMap.get("id");
    this.doctorService.getServices(1).subscribe((x) => {
      this.serviceList = x.content;
      this.serviceListOrdinata = this.serviceList;
      this.pageLength = x.totalElements;

      this.spinner.hide();
    })

  }

  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.serviceListOrdinata = this.serviceList.slice(offset).slice(0, event.pageSize);
  }


  open(src): void {
    let albums = [];
    const album = {
      src: src
    }
    albums.push(album)
    // open lightbox
    this._lightbox.open(albums);
  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  removeService(service) {
    let r = confirm("Sei sicuro di voler eliminare il servizio " + service.name + "?");
    if (r) {
      this.spinner.show();
      this.doctorService.deleteServiceById(service.serviceInShopId).subscribe((x) => {
        this.doctorService.getServices(1).subscribe((y) => {
          this.serviceList = y.content;
          this.serviceListOrdinata = this.serviceList;
          this.pageLength = y.totalElements;
          alert("Rimozione avvenuta con successo");
          this.spinner.hide();
        })

      },
        (err) => {
          this.spinner.hide();
          if (err.text.includes("Servizio in farmacia")) {
            this.doctorService.getServices(1).subscribe((y) => {
              this.serviceList = y.content;
              this.serviceListOrdinata = this.serviceList;
              this.pageLength = y.totalElements;
              alert("Rimozione avvenuta con successo");
              this.spinner.hide();
            })

          }
        })
    }
  }
}


