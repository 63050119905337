import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";

import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { apiUrl } from "../../environments/environment";
import { UserService } from "../services/user.service";
import { FarmacieService } from "../services/farmacie.service";

export interface IMessage {
  type: "info" | "success" | "warning" | "danger";
  // showIn: 'default' | 'toaster',
  title: string;
  message?: string;
  delay?: number;
}

export interface IMessagePushNotification extends IMessage {
  type: "info" | "success" | "warning" | "danger";
  // showIn: 'default' | 'toaster',
  body: string;
  click_action?: string;
}
@Component({
  selector: "app-toaster",
  templateUrl: "./toaster.component.html",
  styleUrls: ["./toaster.component.css"],
})
export class ToasterComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  @Input() messages: BehaviorSubject<IMessagePushNotification>;

  private _sub$: Subscription;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private router: Router,
    private farmacieService: FarmacieService
  ) {
    this._toastrService.overlayContainer = this.toastContainer;
  }

  ngOnInit() {
    let idShop;
    const id = this.route.snapshot.paramMap.get("id");
    this._sub$ = this.messages.subscribe((x) => {
      if (x) {
        /*   if (x.title.includes("preventivo") || x.title.includes("Preventivo")) {
          this.userService
            .get(this.userService.currentUserValue.account)
            .subscribe((g) => {
              this.farmacieService.getFarmacia(g.idshop).subscribe((z) => {
                this.userService
                  .sendMail(
                    "t.briganti@netcomgroup.eu",
                    "Nuovo ordine",
                    "Salve " +
                      z.name +
                      ", è in arrivo un nuovo ordine sul Backoffice!"
                  )
                  .subscribe((y) => {});
              });
            });
        } */
        const _$ = this._toastrService[x.type](
          x.message || x.body,
          x.title
        ).onTap.subscribe(() => {
          /*  this._messages.getMessaggi(); */
          if (x.title.includes("messaggio") || x.title.includes("Messaggio")) {
            this.userService
              .get(this.userService.currentUserValue.account)
              .subscribe((y) => {
                idShop = y.shop;
                this.router.navigateByUrl(`messaggi/${idShop}`);
              });
          } else {
            this.router.navigateByUrl("orderbyemployee");
          }

          _$.unsubscribe();
        });
      }

      //window.location.reload();
    });
  }

  ngOnDestroy(): void {
    this._sub$.unsubscribe();
  }
}
