import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { SliderNewsService } from 'src/app/services/slider-news.service';

@Component({
  selector: 'app-slider-news-add',
  templateUrl: './slider-news-add.component.html',
  styleUrls: ['./slider-news-add.component.css']
})
export class SliderNewsAddComponent implements OnInit {
  title: string
  startTime = new Date();
  endTime = new Date();
  body: string;
  idShop;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private newsService: SliderNewsService) { }

  ngOnInit() {
  this.idShop = this.route.snapshot.paramMap.get("id");
  }


  onSubmit(){
    let dateToCompare = new Date (this.startTime);
    let dateEndCompare = new Date (this.endTime);
    if(dateToCompare.getTime() < dateEndCompare.getTime()){
    this.spinner.show();

    let body = {
      title: this.title,
      startDate: this.startTime,
      endDate: this.endTime,
      body: this.body,
      idShop: this.idShop
    }
    this.newsService.addNews(body).subscribe((x)=>{
      this.spinner.hide();
      alert("Aggiunta della news avvenuta con successo!")
      this.router.navigate(["/newsList/" + this.idShop])
    },
    (err)=>{
      this.spinner.hide();
      alert("Qualcosa è andato storto!")
    })
  }
  else{
    alert("Attenzione! Imposta una data valida");
  }
}
  
}
