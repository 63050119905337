import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import { SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import { Messaggio } from "../../../models/messaggio";
import { MessageService } from "../../../services/message.service";
import { Router } from '@angular/router';
import { FarmacieService } from "../../../services/farmacie.service";
@Component({
  selector: 'app-modal-order-employee',
  templateUrl: './modal-order-employee.component.html',
  styleUrls: ['./modal-order-employee.component.css']
})
export class ModalOrderEmployeeComponent implements OnInit {
  @Input() buyerEmail: string;
  @Input() idFarmacia: any;
  @ViewChild('modalOrderEmployee', { static: false }) modalContent: TemplateRef<ModalOrderEmployeeComponent>;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xl'
  }
  messaggio: Messaggio = new Messaggio();
  automaticAnswers: FormGroup;
  listaProfili: any[];
  automaticResponse: any;
  userInfo = {
    id: '',
    fullname: ''
}
  listaUtenti: any;
  listautentiOrdinata: any;
  flag: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private farmacieService: FarmacieService,
  ) {
    this.automaticAnswers = this.fb.group({
      message: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.userService.get(this.buyerEmail).subscribe((x)=>{
    this.userInfo.id = x.id;
    this.userInfo.fullname = x.name+" "+x.surname;

    this.userService.getallUsers(this.idFarmacia).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
      this.flag = this.listautentiOrdinata.filter(user => user.id === this.userInfo.id).length > 0? false : true;
    });
    });

    this.farmacieService.getAllAutomaticMessages().subscribe((x)=>{
      this.automaticResponse = x;
    })
  }

  get aS(): any {
    return this.automaticAnswers.controls;
  }

  sendAutomaticMessage() {
     
    if(!this.flag){
    this.messaggio.idsender = this.idFarmacia;
    this.messaggio.idrecipient = parseInt(this.userInfo.id);
    this.messaggio.body = this.aS['message'].value;
    this.messaggio.created = Date.now();

    this.messageService.add(this.messaggio).subscribe((mess) => {
      this.activeModal.dismiss('Cross click')
      alert("Messaggio rapido inviato con successo!");
     

     
      this.router.navigate([`messaggi/utente/${this.messaggio.idsender}/${this.messaggio.idrecipient}`]);
    },(err)=>{
      this.activeModal.dismiss('Cross click')
      alert("qualcosa è andato storto, riprova")
      
    });
  }
  else{
    this.activeModal.dismiss('Cross click')
    alert("Attenzione, l'utente non risulta più associato a questa farmacia")
  }
  }
}