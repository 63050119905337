import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DoctorServices {

    constructor(private http: HttpClient,
        private userService: UserService) { }


    addDoctorService(serviceBody, serviceTimetable, photo): Promise<any> {

        var formdata = new FormData();
        formdata.append("serviceInShopToSaveRequest", JSON.stringify(serviceBody))
        formdata.append("serviceInShopAvailabilityToSaveRequest", JSON.stringify(serviceTimetable))
        formdata.append("image", photo)

        var requestOptions = {
            method: "POST",
            body: formdata,
            headers: new Headers({
                Authorization: "Bearer " + this.userService.currentUserValue.token,
            }),
        };

        return fetch(`${apiUrl}/serviceInShop/create`, requestOptions)
            .then((response) => response)
            .then((result) => {
                return result;
            })
            .catch((error) => console.log("error", error));


    }

    getServices(serviceType: number): Observable<any> {
        return this.http.get(`${apiUrl}/getAllServices/${serviceType}`)
            .pipe();
    }


    getServiceDetail(serviceId: number): Observable<any> {
        return this.http.get(`${apiUrl}/getServiceDetails/${serviceId}`)
            .pipe();
    }


    modifyDoctorService(serviceBody, serviceTimetable, photo, serviceId): Promise<any> {

        var formdata = new FormData();
        formdata.append("serviceInShopToUpdateRequest", JSON.stringify(serviceBody))
        formdata.append("serviceInShopAvailabilityToSaveRequest", JSON.stringify(serviceTimetable))
        formdata.append("image", photo)

        var requestOptions = {
            method: "PUT",
            body: formdata,
            headers: new Headers({
                Authorization: "Bearer " + this.userService.currentUserValue.token,
            }),
        };

        return fetch(`${apiUrl}/serviceInShop/modify/${serviceId}`, requestOptions)
            .then((response) => response)
            .then((result) => {
                return result;
            })
            .catch((error) => console.log("error", error));


    }


    deleteServiceById(serviceId: number): Observable<any> {
        return this.http.delete(`${apiUrl}/serviceInShop/delete/${serviceId}`)
            .pipe();
    }
}