import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { Lightbox } from 'ngx-lightbox';
import { Giorno, Week } from '../user-edit/user-detail.component';
import { DoctorServices } from 'src/app/services/doctorServices.service';

export interface ServiceTimetable {
  dayOfWeek: string,
  morningStartTime: string,
  morningEndTime: string,
  afternoonStartTime: string,
  afternoonEndTime: string
}

@Component({
  selector: 'app-new-services',
  templateUrl: './new-services.component.html',
  styleUrls: ['./new-services.component.css']
})
export class NewServicesComponent implements OnInit {
  timeTable: ServiceTimetable[] = []
  startChiusura: string;
  endChiusura: string;
  nameDay: any;
  days = new Giorno();
  startDays = new Week();
  endDays = new Week();
  description: string;
  serviceName;
  date;
  dateTo;
  specialistName;
  specialistSurname;
  price;
  body: string;
  idShop;
  file;
  lastFile;
  _albums: Array<any> = [];
  fileFlag: boolean = false;
  fasceOrariGiorni = [
    { time: "Unico turno/Continuato" }, //Orario Continuato
    { time: "A turni" }, // a turni
  ];
  fasceOrarioLunedi: string;
  fasceOrarioMartedi: string;
  fasceOrarioMercoledi: string;
  fasceOrarioGiovedi: string;
  fasceOrarioVenerdi: string;
  fasceOrarioSabato: string;
  fasceOrarioDomenica: string;
  durationTime: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private _lightbox: Lightbox,
    private doctorService: DoctorServices) { }

  ngOnInit() {
    this.idShop = this.route.snapshot.paramMap.get("id");
    const album = {
      src: "",
    }
    this._albums.push(album);
  }


  onSubmit() {
    let errorcount = 0;
    this.spinner.show();
    let serviceBody = {
      name: this.serviceName,
      operatorName: this.specialistName,
      operatorSurname: this.specialistSurname,
      description: this.description,
      minutesAverageDuration: this.durationTime,
      cost: this.price,
      serviceType: 1
    }
    let tempArray: ServiceTimetable;

    if (this.startDays.lunedi != null) {
      errorcount = parseInt(this.startDays.lunedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.lunedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.lunedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.lunedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.lunedi) > parseInt(this.endDays.lunedi) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.lunedi2) > parseInt(this.endDays.lunedi2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "MONDAY",
        morningStartTime: this.startDays.lunedi,
        morningEndTime: this.endDays.lunedi,
        afternoonStartTime: this.startDays.lunedi2,
        afternoonEndTime: this.endDays.lunedi2,
      }
      this.timeTable.push(tempArray);

    }

    if (this.startDays.martedi != null) {
      errorcount = parseInt(this.startDays.martedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.martedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.martedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.martedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.martedi) > parseInt(this.endDays.martedi) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.martedi2) > parseInt(this.endDays.martedi2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "TUESDAY",
        morningStartTime: this.startDays.martedi,
        morningEndTime: this.endDays.martedi,
        afternoonStartTime: this.startDays.martedi2,
        afternoonEndTime: this.endDays.martedi2,
      }
      this.timeTable.push(tempArray);
    }

    if (this.startDays.mercoledi != null) {
      errorcount = parseInt(this.startDays.mercoledi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.mercoledi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.mercoledi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.mercoledi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.mercoledi) > parseInt(this.endDays.mercoledi) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.mercoledi2) > parseInt(this.endDays.mercoledi2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "WEDNESDAY",
        morningStartTime: this.startDays.mercoledi,
        morningEndTime: this.endDays.mercoledi,
        afternoonStartTime: this.startDays.mercoledi2,
        afternoonEndTime: this.endDays.mercoledi2,
      }
      this.timeTable.push(tempArray);
    }

    if (this.startDays.giovedi != null) {
      errorcount = parseInt(this.startDays.giovedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.giovedi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.giovedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.giovedi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.giovedi) > parseInt(this.endDays.giovedi) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.giovedi2) > parseInt(this.endDays.giovedi2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "THURSDAY",
        morningStartTime: this.startDays.giovedi,
        morningEndTime: this.endDays.giovedi,
        afternoonStartTime: this.startDays.giovedi2,
        afternoonEndTime: this.endDays.giovedi2,
      }
      this.timeTable.push(tempArray);


    }

    if (this.startDays.venerdi != null) {
      errorcount = parseInt(this.startDays.venerdi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.venerdi) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.venerdi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.venerdi2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.venerdi) > parseInt(this.endDays.venerdi) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.venerdi2) > parseInt(this.endDays.venerdi2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "FRIDAY",
        morningStartTime: this.startDays.venerdi,
        morningEndTime: this.endDays.venerdi,
        afternoonStartTime: this.startDays.venerdi2,
        afternoonEndTime: this.endDays.venerdi2,
      }
      this.timeTable.push(tempArray);

    }

    if (this.startDays.sabato != null) {
      errorcount = parseInt(this.startDays.sabato) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.sabato) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.sabato2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.sabato2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.sabato) > parseInt(this.endDays.sabato) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.sabato2) > parseInt(this.endDays.sabato2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "SATURDAY",
        morningStartTime: this.startDays.sabato,
        morningEndTime: this.endDays.sabato,
        afternoonStartTime: this.startDays.sabato2,
        afternoonEndTime: this.endDays.sabato2,
      }
      this.timeTable.push(tempArray);

    }

    if (this.startDays.domenica != null) {
      errorcount = parseInt(this.startDays.domenica) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.domenica) > 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.domenica2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.endDays.domenica2) <= 12 ? +1 : errorcount;
      errorcount = parseInt(this.startDays.domenica) > parseInt(this.endDays.domenica) ? +1 : errorcount;
      errorcount = parseInt(this.startDays.domenica2) > parseInt(this.endDays.domenica2) ? +1 : errorcount;
      tempArray = {
        dayOfWeek: "SUNDAY",
        morningStartTime: this.startDays.domenica,
        morningEndTime: this.endDays.domenica,
        afternoonStartTime: this.startDays.domenica2,
        afternoonEndTime: this.endDays.domenica2,
      }
      this.timeTable.push(tempArray);
    }

    if (errorcount == 0) {
      this.doctorService.addDoctorService(serviceBody, this.timeTable, this.file).then((x) => {
        this.router.navigate(["/allServices/" + this.idShop]);
        console.log(x);
        this.timeTable = [];
        this.spinner.hide()
      },
        (err) => {
          this.spinner.hide()
          alert("Servizio aggiunto con successo!")
          this.timeTable = [];
        })
    }
    else {
      alert("Attenzione, gli orari sono stati compilati erroneamente.");
      this.spinner.hide();
    }
  }



  upload(fileUploaded) {
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
    const file: File = fileUploaded.files[0];




    if (fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "jpg" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "mp4" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "jpeg") {
      this.file = file;
      this.lastFile = file;


      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this._albums[0].src = reader.result;
      }



    }
    else {
      alert("Errore con l'upload dei file. Il Sistema gestisce i seguenti formati: .jpg, .jpeg")
      this.fileFlag = false;
    }

    this.fileFlag = this.file != undefined ? true : false

  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


}
