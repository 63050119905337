import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { apiUrl } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SliderNewsService {
  constructor(private http: HttpClient,
    ) {}


  //metodo che ottiene la lista di tutte le news
  getNews(): Observable<any> {
    return this.http.get<any[]>(`${apiUrl}/getAllNews`);
  }

  //metodo che ritorna una singola news dalla lista
  getSingleNews(idNews): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getNews/${idNews}`);
  }

  //metodo che modifica una news in specifico  
  modifyNews(body): Observable<any>{
    return this.http.put<any>(`${apiUrl}/modifyNews`, body)
  }
 
  //metodo che aggiunge una news alla lista
  addNews(body): Observable<any> {
    return this.http.post<any>(`${apiUrl}/addNews`, body);
  }

  //metodo che rimuove una news dalla lista
  removeNews(idNews): Observable<any> {
    return this.http.delete<any>(`${apiUrl}/deleteNews/${idNews}`);
  }


}
