import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { User } from './models/User';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { IMessagePushNotification } from './toaster/toaster.component';
@Injectable()
export class MessagingService {

  messaging: firebase.messaging.Messaging;
  currentMessage = new BehaviorSubject<IMessagePushNotification>(null);
  tokenFirebase: Subject<string> = new Subject<string>();
  user: User;
  tokenUser = '';
  isSupportedFCM = false;
  constructor(private afAuth: AngularFireAuth, private angularFireDB: AngularFireDatabase,
    private angularFireMessaging: AngularFireMessaging) {
    try {

      this.messaging = firebase.messaging();
      this.angularFireMessaging.messaging.subscribe(
        (_messaging) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        }
      );
    } catch (e) {
      console.log('Unable to Instantiate Firebase Messaging', e);
    }

  }



  updateToken(token) {
    if (this.isSupportedFCM) {
      this.afAuth.authState.subscribe(user => {
        //if (!user) return;
        const data = {};
        data[user.uid] = token;
        this.angularFireDB.object('fcmTokens/').update(data);
      });
    }

  }

  requestPermission() {

    try {

      this.messaging.requestPermission()
        .then(
          () => {
            console.log('Notification permission granted.');
            this.isSupportedFCM = true;

            return this.messaging.getToken();
          })
        .then(token => {
          this.tokenUser = token;
          this.tokenFirebase.next(token);

          //console.log(this.tokenUser)
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });
    } catch (e) {
      console.log('Unable to Request PUSH Permission', e);
    }
  }


  receiveMessage() {
    this.isSupportedFCM = true;
    // this.angularFireMessaging.messages.subscribe((payload) => {
    //   console.log("Message received. ", payload);
    //   this.currentMessage.next(payload);
    //   console.log(payload); })
    if (this.isSupportedFCM) {
      this.messaging.onMessage((payload) => {
        // console.log("Message received. ", payload);
        this.currentMessage.next({ ...payload.notification, type: 'info' });
       
      });
    }
  }

  showMessage() {
    try {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        console.log(payload);
      })
    } catch (e) {
      console.log(e)
    }
  }



}
