import { AfterContentChecked, Component, OnInit } from "@angular/core";
import { ProductInfo } from "../../models/productInfo";
import { ProductService } from "../../services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-product-edit",
  templateUrl: "./product-edit.component.html",
  styleUrls: ["./product-edit.component.css"]
})
export class ProductEditComponent implements OnInit, AfterContentChecked {
  product = new ProductInfo();
  name$;
  name: string;
  productId: string;
  isEdit = false;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private location: Location
  ) { }

  ngOnInit() {
    this.name$ = this.userService.name$.subscribe(aName => {
      this.name = aName;
    }); //modifica fatta da torre sul nome che compare quando si aggiorna la pagina
    this.name = this.userService.currentUserValue.name;

    this.productId = this.route.snapshot.paramMap.get("id");
    if (this.productId) {
      this.isEdit = true;
      this.productService
        .getDetail(this.productId)
        .subscribe(prod => (this.product = prod));
    }
  }

  modificaProdotto() {
    this.productService.update(this.product).subscribe(
      prod => {
        if (!prod) throw new Error();
        this.router.navigate(["/seller"]);
      },
      (err) => {alert("Attenzione, qualcosa è andato storto") }
    );
  }

  onSubmit() {
    if (this.productId) {
      this.modificaProdotto();
    } else {
      this.aggiunginuovoProdotto();
    }
  }

  aggiunginuovoProdotto() {
    this.productService.create(this.product).subscribe(
      prod => {
        if (!prod) throw new Error();
        this.router.navigate(["/seller/product"]);
      },
      (e) => { alert("Attenzione, qualcosa è andato storto")}
    );
  }

  ngAfterContentChecked(): void {
    console.log(this.product);
  }

}
