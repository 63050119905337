import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { ActivatedRoute, Router } from "@angular/router";
import { Farmacia } from "src/app/models/Farmacia";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import timeGrigPlugin from "@fullcalendar/daygrid";
import itLocale from "@fullcalendar/core/locales/it";
import { UserService } from "../../services/user.service";
import { Location, formatDate } from "@angular/common";
import interactionPlugin from "@fullcalendar/interaction"; // for dateClick
import { Role } from "src/app/enum/Role";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

export interface IEventCalendar {
  title: string;
  start: string;
  end?: string;
  id: number;
  url: string;
  color: string;
  idShop: string;
  interested?: number;
}
@Component({
  selector: "app-calendario",
  templateUrl: "./calendario.component.html",
  styleUrls: ["./calendario.component.css"],
})
export class CalendarioComponent implements OnInit {
  @ViewChild("calendar", { static: true })
  calendarComponent: FullCalendarComponent;
  currentUser: JwtResponse;

  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  listaEventiCalendar: IEventCalendar[] = [];
  listaEventi: IEventCalendar[] = [];
  evento: EventoDettagli;
  farmacia: Farmacia;
  listaeventiEufarma: EventoDettagli[];
  name$;
  name: string;
  arrayEventi: IEventCalendar[];
  pageSize = 5;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  idProva: number;
  id2: number;
  values: boolean;
  id: string;
  idFarmacia: number;
  idEvento: string;
  minDate: any;
  role: string;
  index = 1;

  constructor(
    private calendarioService: CalendarioService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService
  ) {
    this.stampalistaEventi();

    this.minDate = formatDate(Date.now(), "yyyy-MM-dd", "it-IT");
    this.values = false;
    this.role = this.userService.currentUserValue.role;

    if (this.router.url.includes("/calendario/farmacia")) {
      this.values = true;
    }
    let idUser;

    this.currentUser = this.userService.currentUserValue;
    this.idProva = +this.route.snapshot.paramMap.get("id");

    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.idFarmacia = x.idshop;
        idUser = x.id;
        if (
          this.route.snapshot.paramMap.get("id") != idUser &&
          this.userService.currentUserValue.role === Role.Employee
        ) {
          this.router.navigate(["/login"]);
        }
      });
  }

  ngOnInit() {
    this.spinner.show();
  }

  stampalistaEventi() {
    if (
      this.userService.currentUserValue.role === Role.Manager &&
      this.router.url.includes("calendario/farmacia")
    ) {
      this.id = this.route.snapshot.paramMap.get("id");
    } else if (
      !this.router.url.includes("calendario/farmacia") &&
      this.userService.currentUserValue.role === Role.Manager
    ) {
      this.id = "0";
    } else if (this.userService.currentUserValue.role === Role.Employee) {
      this.id = this.route.snapshot.paramMap.get("id");
    }

    this.calendarioService.getEvents(this.id).subscribe((events) => {
      events = events ? events : [];
      if (events instanceof Array) {
        this.listaEventi = events;
        this.pageLength = this.listaEventi.length;
        this.arrayEventi = this.listaEventi.sort(function (a, b) {
          return a.start > b.start ? 1 : -1;
        });

        this.arrayEventi = this.listaEventi
          .slice((0 + 1 - 1) * this.pageSize)
          .slice(0, this.pageSize);
        const listaEventi: IEventCalendar[] = [];
        events.forEach((x) => {
          var colore = "white";
          const date = new Date(`${x.year}-${x.month}-${x.day}`);
          var txtValUpper = x.title;
          if (txtValUpper.toUpperCase() == "SERVIZIO NOTTURNO") {
            colore = "yellow";
          }
          if (txtValUpper.toUpperCase() == "AVVISO") {
            colore = "red";
          }

          if (
            x.idshop == 0 &&
            txtValUpper.toUpperCase() != "SERVIZIO NOTTURNO" &&
            txtValUpper.toUpperCase() != "AVVISO"
          ) {
            colore = "blue";
          }

          if (
            x.idshop != 0 &&
            txtValUpper.toUpperCase() != "SERVIZIO NOTTURNO" &&
            txtValUpper.toUpperCase() != "AVVISO"
          ) {
            colore = "green";
          }

          if (x.kind == 0) {
            colore = "blue";
          }
          if (x.kind == 1) {
            colore = "green";
          }

          /*     const event: IEventCalendar = {
            title: "",
            start: `${x.year}-${x.month}-${x.day}`,
            id: x.id,
            url: "javascript:void(0)",
            color: colore,
            idShop: x.idshop
          }; */

          if (parseInt(x.duration, 10) > 1) {
            for (var i = 0; i < parseInt(x.duration, 10); i++) {
              const date = new Date(`${x.year}-${x.month}-${x.day}`);
              date.setDate(date.getDate() + i);
              /*    console.warn(date.toISOString()); */
              var date_calendar: any = date.toISOString().split("T")[0];

              const event: IEventCalendar = {
                title: "",
                start: date_calendar,
                id: x.id,
                url: "javascript:void(0)",
                color: colore,
                idShop: x.idshop,
              };
              listaEventi.push(event);
            }
            /*   date.setDate(date.getDate() + (parseInt(x.duration, 10) - 1));
            const month = date.getMonth() + 1;
            const day = date.getDate() + 1;
            event.end = `${date.getFullYear()}-${
              month.toString().length > 1 ? month : "0" + month
            }-${day.toString().length > 1 ? day : "0" + day}`; */
          } else {
            const event: IEventCalendar = {
              title: "",
              start: `${x.year}-${x.month}-${x.day}`,
              id: x.id,
              url: "javascript:void(0)",
              color: colore,
              idShop: x.idshop,
            };
            listaEventi.push(event);
          }
        });
        this.listaEventiCalendar = listaEventi;
        this.spinner.hide();
      }
    });
  }

  eliminaEvento(evento) {
    /*    let day;
    let month;
    let year; */
    var domanda = confirm(
      "Vuoi eliminare l'evento " +
        evento.title +
        " del  " +
        evento.day +
        "/" +
        evento.month +
        "/" +
        evento.year +
        "?"
    );
    if (domanda == true) {
      this.spinner.show();
      this.calendarioService.deleteEvent(evento).subscribe((z) => {
        if (z.status == 200) {
          this.stampalistaEventi();
        }
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
        alert("Attenione! Qualcosa è andato storto. Riprova");
      });
      //   _ => {
      //     this[eventName] = this[eventName].filter(e => e.id != evento.id);
  /*     if (this.userService.currentUserValue.role === Role.Employee) {
        this.userService.getallUsers(evento.idshop).subscribe((x) => {
          let usersListToken = [];
          x.forEach((element) => {
            if (element.fbtoken && element.privacykey2 && element.privacykey3)
              usersListToken.push(element.fbtoken);
          });
          if (usersListToken.length != 0) {
            this.notificatioService.invionotifica(
              usersListToken,
              evento.shop.name + " ha eliminato l'evento in questa data",
              "L'evento del " +
                evento.day +
                "-" +
                evento.month +
                "-" +
                evento.year +
                " è stato eliminato"
            );
          }
      
        });
      }  *//* else {
        this.userService.getallUsers(evento.idshop).subscribe((x) => {
          let matriceNumber: number;
          let usersListToken = [];
          x.forEach((element) => {
            if (element.fbtoken && element.privacykey2 && element.privacykey3)
              usersListToken.push(element.fbtoken);
          });
          matriceNumber = Math.ceil(usersListToken.length / 1000); //1
          var matriceNotifica: string[][] = [];
          for (var i: number = 0; i < matriceNumber; i++) {
            matriceNotifica[i] = [];
          }
          let regolatore: boolean;
          matriceNotifica.forEach((element, index) => {
            regolatore = false;
            usersListToken.forEach((secondelement, j) => {
              if (!regolatore)
                if (matriceNotifica[index].length != 1000) {
                  matriceNotifica[index].push(secondelement);
                } else {
                  usersListToken.splice(0, j);
                  regolatore = true;
                }
            });
          });

          if (matriceNotifica.length != 0) {
            let cont = 0;
            do {
              this.notificatioService.invionotifica(
                matriceNotifica[cont],
                evento.shop.name + " ha eliminato l'evento in questa data",
                "L'evento del " +
                  evento.day +
                  "-" +
                  evento.month +
                  "-" +
                  evento.year +
                  " è stato eliminato"
              );
              cont++;
            } while (matriceNotifica.length > cont);
          }
        });
      } */
    }
  }

  handleDateClick(arg) {
    // handler method
    if (
      arg.dateStr >= this.minDate &&
      (this.userService.currentUserValue.role === Role.Employee ||
        this.userService.currentUserValue.role === Role.Manager) &&
      !this.router.url.includes("calendario/farmacia")
    ) {
      const id = this.route.snapshot.paramMap.get("id");
      this.router.navigate(["/calendario/new"], {
        queryParams: { idshop: id, date: arg.dateStr },
      });
    }
  }

  handleEventClick(event) {
    // handler method
    this.calendarioService.getEvent(event.event.id).subscribe((t) => {
      this.idEvento = t.idshop;
      let kindevento = t.kind;
      const id = event.event.id;
      if (
        !this.router.url.includes("calendario/farmacia") &&
        this.userService.currentUserValue.role === Role.Manager &&
        kindevento == "0"
      ) {
        /*  +this.idEvento == this.idFarmacia &&
        (this.userService.currentUserValue.role === Role.Employee ||
          this.userService.currentUserValue.role === Role.Manager) &&
        !this.router.url.includes("calendario/farmacia") */ this.router.navigate(
          [`/editEvento/${id}`]
        );
      } else if (
        !this.router.url.includes("calendario/farmacia") &&
        this.userService.currentUserValue.role === Role.Employee &&
        kindevento == "1"
      ) {
        this.router.navigate([`/editEvento/${id}`]);
        console.log();
      }
    });
  }

  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.arrayEventi = this.listaEventi.slice(offset).slice(0, event.pageSize);
  }
}
