import { User } from "./User";

export class Messaggio {
  idsender: number;
  idrecipient: number;
  title = "nuovo messaggio";
  body: string;
  created = Date.now();
  attached: boolean;
  id: number;
  read: Date;
  sender: User;
  recipient: User;
  deletebysender: boolean;
  deletebyrecipient: boolean;
}
