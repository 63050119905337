import { Component, OnInit } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { element } from "protractor";
import { User } from "src/app/models/User";
import { DomSanitizer } from "@angular/platform-browser";
import { Lightbox } from "ngx-lightbox";
import { FarmacieService } from "src/app/services/farmacie.service";

@Component({
  selector: "app-interessati-info",
  templateUrl: "./interessati-info.component.html",
  styleUrls: ["./interessati-info.component.css"],
})
export class InteressatiInfoComponent implements OnInit {
  idEvento: any;
  listautentiOrdinata: User[];
  interessatiCounter: number = 0;
  evento: any[];
  infoEvento;
  _albums: Array<any> = [];
  idShop;
  constructor(
    private calendarioService: CalendarioService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificatioService: NotificationServiceService,
    private sanitizer: DomSanitizer,
    private _lightbox: Lightbox,
    private farmaciaService: FarmacieService
  ) {}

  ngOnInit() {
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.farmaciaService.getFarmacia(x.shop).subscribe((y) => {
          this.idShop = y.id;
        });
      });

    this.idEvento = +this.route.snapshot.paramMap.get("id");
    this.calendarioService.getEvent(this.idEvento).subscribe((x) => {
      this.infoEvento = x;
      /*       this.idShop = this.infoEvento.shop.id; */
    });
    this.calendarioService
      .getAttachmentCalendar(this.idEvento)
      .subscribe((x) => {
        console.log(x);
        x.forEach((element, index) => {
          const src = x[index];
          const descrizione = "allegato_" + index + ".jpg";
          const album = {
            src: src,
            descrizione: descrizione,
          };

          this._albums.push(album);
        });
      });
    this.calendarioService.getInterested(this.idEvento).subscribe((x) => {
      this.evento = x;

      this.evento.forEach((superelement) => {
        if (superelement.i.interest == true) this.interessatiCounter++;
      });
    });
  }
  dettaglioUtente(utente: User) {
    //console.log(utente.id);
    "profiloutente/:id";
    window.open(`profiloutente/${utente.id}`);
  }

  showUsers() {
    var listaUtenti: User[];
    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
      listaUtenti = listaUtenti.sort(function (a, b) {
        return a.name < b.name ? 1 : -1;
      });
      this.listautentiOrdinata = listaUtenti.slice();
    });
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  eliminaAllegato(image) {
    var domanda = confirm("Sei sicuro di voler eliminare l'allegato?");
    if (domanda == true) {
      /*       console.log(image.split("/")); */
      this.calendarioService
        .deleteAttachmentCalendar(image.split("/")[5])
        .subscribe(
          (y) => {
            alert("Allegato rimosso con successo!");
            this.calendarioService
              .getAttachmentCalendar(this.idEvento)
              .subscribe((x) => {
                console.log(x);
                x.forEach((element, index) => {
                  const src = x[index];
                  const descrizione = "allegato_" + index + ".jpg";
                  const album = {
                    src: src,
                    descrizione: descrizione,
                  };

                  this._albums.push(album);
                });
              });
          },
          (err) => {
            alert("Allegato rimosso con successo!");
            this.calendarioService
              .getAttachmentCalendar(this.idEvento)
              .subscribe((x) => {
                console.log(x);
                this._albums = [];
                x.forEach((element, index) => {
                  const src = x[index];
                  const descrizione = "allegato_" + index + ".jpg";
                  const album = {
                    src: src,
                    descrizione: descrizione,
                  };

                  this._albums.push(album);
                });
              });
          }
        );
    }
  }
}
