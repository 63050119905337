import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiUrl } from "../../environments/environment";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { Observable } from "rxjs";
import { Giorno } from "../pages/user-edit/user-detail.component";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CalendarioService {
  constructor(private http: HttpClient,
    private userService: UserService) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // metodo che ritorna tutta la lista degli eventi
  getEvents(idshop: string): Observable<any> {
    const url = `${apiUrl}/getalleventsfromtoday/${idshop}`;
    return this.http.get(url);
  }

  // metodo per inserire un nuovo evento
 
  postEvent(body, arrayPhoto?): Promise<any> {
    var formdata = new FormData();
    arrayPhoto.forEach(element => {
      formdata.append("iconList", element)
    });
    formdata.append("calendarToSaveRequest", JSON.stringify(body))    
    
  
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/setevent`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  
  }
  // metodo per eliminare un'evento
  deleteEvent(evento: EventoDettagli) {
    const url = `${apiUrl}/deleteevent/${evento.id}`;
    return this.http.delete(url, { observe: "response" });
  }
  // metodo per aggiornare un evento
  updateEvent(evento: EventoDettagli): Observable<EventoDettagli> {
    const url = `${apiUrl}/modifyevent`;
    return this.http.put<EventoDettagli>(url, evento);
  }

  //metodo che ritorna i dettagli di un singolo evento
  getEvent(id: number): Observable<EventoDettagli> {
    const url = `${apiUrl}/getevent/${id}`;
    return this.http.get<EventoDettagli>(url);
  }
  getInterested(id: number): Observable<any> {
    const url = `${apiUrl}/interest/${id}`;
    return this.http.get<any>(url);
  }

  //metodo che ritorna gli orari di servizio di una farmacia, compreso di eccezioni.
  getCloseDay(idshop): Observable<Giorno> {
    const url = `${apiUrl}/getshopcalendar/${idshop}`;
    return this.http.get<Giorno>(url);
  }

  //metodo che setta gli orari di servizio per una farmacia.
  setCloseDay(struttura) {
    const url = `${apiUrl}/setshopcalendar`;
    return this.http.post<Giorno>(url, struttura);
  }

  //metodo che cancella una eccezione di un orario.
  deleteCloseDay(id) {
    const url = `${apiUrl}/deleteshopcalendarexception/${id}`;
    return this.http.delete(url, id);
  }

  //metodo che ritorna gli allegati di un evento.
  getAttachmentCalendar(idEvento): Observable<string[]> {
    const url = `${apiUrl}/getattachmentcalendar/${idEvento}`;
    return this.http.get<string[]>(url);
  }

  //metodo che cancella un allegato relativo ad un evento.
  deleteAttachmentCalendar(filename): Observable<any> {
    const url = `${apiUrl}/deletettachmentcalendar/${filename}`;
    return this.http.delete<any>(url);
  }
}
