import { ProductInfo } from "./productInfo";

export class ProductInOrder {
  productId: string;
  productName: string;
  productPrice: number;
  oldproductPrice: number;
  productStock: number;
  productDescription: string;
  productIcon: string;
  categoryType: number;
  count: number;
  available: boolean;
  dalle?: any;
  alle?: any;
  cartUserId?: number;
  productPoints?: number;
  productNote?: string;
  
  constructor(productInfo, quantity = 1) {
    this.productId = productInfo.productId;
    this.productName = productInfo.productName;
    this.oldproductPrice = productInfo.productPrice;
    this.productPrice = productInfo.productPrice;
    this.productStock = productInfo.productStock;
    this.productDescription = productInfo.productDescription;
    this.productIcon = productInfo.productIcon;
    this.categoryType = productInfo.categoryType;
    this.count = quantity;
    this.available = false;
    this.dalle = productInfo.dalle;
    this.alle = productInfo.alle;
    this.cartUserId = productInfo.cartUserId;
  }
}
