import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { OrderService, IProcutList } from "../../services/order.service";
import { Order } from "../../models/Order";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { formatDate } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { Location } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { Item } from "src/app/models/Item";
import { isNullOrUndefined } from "util";
import { Lightbox } from "ngx-lightbox";
import { apiUrl } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ImageSnippet } from "../aggungi-evento/aggungi-evento.component";
import { ProductService } from "src/app/services/product.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Spedizione } from "src/app/models/Spedizione";
import { CartService } from "src/app/services/cart.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { renderFlagCheckIfStmt } from "@angular/compiler/src/render3/view/template";
import { $$ } from "protractor";

@Component({
  selector: "app-crea-spedizione",
  templateUrl: "./crea-spedizione.component.html",
  styleUrls: ["./crea-spedizione.component.css"],
})
export class CreaSpedizioneComponent implements OnInit {
  item: string = "";
  itemVolantino: string = "";
  delivery: boolean = false;
  nomeProdotto: string;
  nomeProdottoVolantino: string;
  order$: Observable<Order>;
  productList: ProductInOrder[] = [];
  orderAmount = 0;
  deliveryData;
  name$;
  name: string;
  selectedOption: string;
  name1: string;
  orderId: string;
  emailProva: string;
  surnameOrder: string;
  nomeShop: any;
  surname1: string;
  minDate: string;
  prezzoMinimo: number;
  farmaciaSelected;
  productFlag: boolean = false;
  priceFlag: boolean = false;
  desc: string;
  nome_prodotto: string;
  _albums: Array<any> = [];
  order_status;
  indirizzo;
  nameAndSurname;
  località: string;
  /*  dalleFarmacia;
  alleFarmacia; */
  costoDelivery: number = 2.5;
  blobArray: Array<Blob> = [];
  blobUrl;
  selectedFile: ImageSnippet;
  page: Array<any>;
  arrayIndirizzo: string;
  fullAddress: string;
  pageVolantino: ProductInOrder[];
  dalleFarmacia;
  alleFarmacia;
  tipoDelivery = [
    {
      tipo: "Domicilio",
    },
    { tipo: "Farmacia" },
  ];
  tipoConsegna: any;
  nome: string;
  user = new Spedizione();
  idOrdine: number;
  passwordConfirm: string;
  valueUtente: boolean;
  email: string;
  card: string;
  listaUtenti: Spedizione[];
  listautentiOrdinata: Spedizione[];
  importoContrassegno: boolean = false;
  users: Array<Spedizione> = [];
  confirmedFlag: boolean = false;
  idShop;
  userTook: any;
  searchUserTemp: any = '';
  coordinate: any;
  formatoColli = [
    {
      tipo: "piccolo",
    },
    { tipo: "medio" },
    { tipo: "personalizzato" },
  ];
  dalle_alle = [
    {
      orario: "",
    },
    {
      orario: "08:00",
    },
    { orario: "09:00" },
    { orario: "10:00" },
    {
      orario: "11:00",
    },
    { orario: "12:00" },
    { orario: "13:00" },
    {
      orario: "14:00",
    },
    { orario: "15:00" },
    { orario: "16:00" },
    {
      orario: "17:00",
    },
    { orario: "18:00" },
  ];
  id2: number;
  misureColli: string;
  numeroColli: string;
  altezzaColli: string;
  larghezzaColli: string;
  profonditaColli: string;
  nameFarmacia: string;
  paymentValore = "Contrassegno";
  fullAddressRitiro: string;
  fullAddressDestinatario: string;
  valoreReale: number;
  splittedAddressRitiro;
  corriere;
  totaleProdotto;
  codiceFiscale: string;
  arrayIndirizzi: any[];
  cartUserId: number;
  numerotel: any;
  idFarmacia;
  sectionEnabledState: { [key: string]: boolean } = {};
  listafarmacieOrdinata: any;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private notificationService: NotificationServiceService,
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private productService: ProductService,
    private cartService: CartService,
    private farmacieService: FarmacieService
  ) { }

  ngOnInit() {
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
        this.id2 = x.shop;
        if(this.id2 == 0) this.farmaciaList();
        this.farmacieService.getFarmacia(this.id2).subscribe((x) => {
          x.sections.forEach(section => {
            this.sectionEnabledState[section.sectionEnum] = section.enabled;
        });
          this.idFarmacia = x.id;
          this.user.telefono = x.phone;
          this.user.emailRichiedente = x.email;
          this.corriere = x.deliveryService;
          if (this.corriere == "Altro") {
            this.dalle_alle = [
              {
                orario: "",
              },
              {
                orario: "08:00",
              },
              { orario: "09:00" },
              { orario: "10:00" },
              {
                orario: "11:00",
              },
              { orario: "12:00" },
              { orario: "13:00" },
              {
                orario: "14:00",
              },
              { orario: "15:00" },
              { orario: "16:00" },
              {
                orario: "17:00",
              },
              { orario: "18:00" },
            ];
          }
          this.user.nomeRichiedente = x.name;
          this.user.clienteritiro = x.name;
          this.splittedAddressRitiro = x.fullAddress.split(
            /[ ][(]|[ ][-][ ]|[)][ ]/g
          );
          if (this.splittedAddressRitiro.length == 4) {
            this.user.cAPritiro = this.splittedAddressRitiro[0];
            this.user.localitaritiro = this.splittedAddressRitiro[1];
            this.user.provinciaritiro = this.splittedAddressRitiro[2];
            this.user.indirizzoritiro =
              this.splittedAddressRitiro[3].split(",")[0] +
              "," +
              this.splittedAddressRitiro[3].split(",")[1];
          }
        });
      });
    this.cartService.getCart().subscribe(
      (x) => {
        this.cartService.removeCart().subscribe((y) => { });

        this.cartService.createCart().subscribe((d) => {
          this.cartUserId = x[0].cartUserId;
        });
      },
      (err) => {
        this.cartService.createCart().subscribe();
      }
    );

    this.minDate = formatDate(Date.now(), "yyyy-MM-dd", "it-IT");

    /* 
    const subs$ = this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        let products = x.products.map((x) => {
          x.oldproductPrice = x.productPrice;
          this.desc = x.productDescription;
          this.nome_prodotto = x.productName;
          x.available = x.available == null ? false : x.available;

          if (this.nome_prodotto.includes("downloadFile")) {
            let numberValue = parseInt(this.desc, 10);
            for (let i = 1; i <= numberValue; i++) {
              const src = `${apiUrl}/getBillImage/${this.route.snapshot.paramMap.get(
                "id"
              )}/${i}`;
              const descrizione = "allegato_" + i + ".jpg";
              const album = {
                src: this.sanitizer.bypassSecurityTrustUrl(src),
                descrizione: descrizione,
              };
              const blob = new Blob([src], {
                type: "application/octet-stream",
              });
              this.blobArray.push(blob);

              this._albums.push(album);
            }
          }

          return x;
        });

        
        this.productList = products;
        subs$.unsubscribe();
      }); */
  }

  getProductList() {
    let i = 0;
    let flag = false;
    do {
      let flagCicle = true;
      this.productList.forEach((element) => {
        if (
          this.item.replace(/\s/g, "") == element.productName.replace(/\s/g, "")
        ) {
          flagCicle = false;
        }
      });
      if (
        this.item.replace(/\s/g, "") ==
        this.page[i].descrizione.replace(/\s/g, "") &&
        flagCicle
      ) {
        this.productList[0];
        let singleProduct = new ProductInOrder(this.item);
        singleProduct.productName = this.item;
        singleProduct.productDescription = this.page[i].codminsan;
        singleProduct.count = 1;
        singleProduct.productId = this.page[i].codice == null ? this.page[i].codminsan : this.page[i].codice;
        singleProduct.productPrice = 0;
        singleProduct.available = true;
        let prodottoPerCarrello = {};
        prodottoPerCarrello["productId"] = singleProduct.productDescription;
        prodottoPerCarrello["count"] = singleProduct.count;
        this.cartService.addItem(prodottoPerCarrello).subscribe((z) => { });
        this.productList.push(singleProduct);
        flag = true;
      }

      i++;
      ProductInOrder;
    } while (flag == false && i < this.page.length);
  }
  getProductVolantinoList() {
    let i = 0;
    let flag = false;
    do {
      let flagCicle = true;
      this.productList.forEach((element) => {
        if (
          this.itemVolantino.replace(/\s/g, "") ==
          element.productName.replace(/\s/g, "")
        ) {
          flagCicle = false;
        }
      });
      if (
        this.itemVolantino.replace(/\s/g, "") ==
        this.pageVolantino[i].productName.replace(/\s/g, "") &&
        flagCicle
      ) {
        this.pageVolantino[i].count = 1;
        this.pageVolantino[i].productDescription =
          this.pageVolantino[i].productId;
        this.cartService.addItem(this.pageVolantino[i]).subscribe((z) => { });
        this.productList.push(this.pageVolantino[i]);
        flag = true;
      }

      i++;
    } while (flag == false && i < this.pageVolantino.length);
  }
  removeProduct(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare il prodotto " +
      this.productList[i].productName +
      "?"
    );
    if (r == true) {
      this.productList.splice(i, 1);
    }
  }
  onBlurAddress() {
    let address: string[];
    if (this.indirizzo != undefined) {
      this.cartService.getLocation(this.indirizzo).subscribe(
        (x) => {
          let addressSplitted: string[];
          let secondSplitted: string[];

          let location: string = "";
          addressSplitted = x.candidates[0].formatted_address.split(",");
          //console.log(addressSplitted);
          if (addressSplitted[2].includes("Italia")) {
            // caso in cui non è presente il numero civico nell'indirizzo
            /*  addressSplitted[2] = ""; */
            //let cap = addressSplitted[1].split(" ")[1];
            //let address1 = addressSplitted[1].split(" ")[1].split(" ")[1];
            //console.log(addressSplitted[1]);
            alert(
              "Attenzione! l'indirizzo digitato è errato. Ricordati di inserire anche il numero civico e la sintassi corretta deve essere: Via, civico, Cap Comune Provincia, Italia"
            );
          } else if (addressSplitted[3].includes("Italia")) {
            // caso in cui è presente il numero civico nell'indirizzo
            addressSplitted[3] = "";
            let cap = addressSplitted[2].substring(1, 6);
            let indirizzo = addressSplitted[0];
            let civico = addressSplitted[1];
            let comune = addressSplitted[2].substring(
              7,
              addressSplitted[2].length - 2
            );
            let prov = addressSplitted[2].substring(
              addressSplitted[2].length - 2,
              addressSplitted[2].length
            );

            this.fullAddress =
              cap +
              " - " +
              comune +
              "(" +
              prov +
              ") " +
              indirizzo +
              "," +
              civico;
            //console.log(this.fullAddress);
          }
          console.log(this.fullAddress);
          /*
                  secondSplitted = addressSplitted[2].split(" ");
                  let cap = secondSplitted[1];
                  let prov = secondSplitted[secondSplitted.length - 1];
                  secondSplitted.splice(0, 1);
                  secondSplitted.splice(0, 1);
                  secondSplitted.splice(secondSplitted.length - 1, 1);
                  secondSplitted.forEach((element, index) => {
                    location += secondSplitted[index + 1] ? element + " " : element;
                  });
                  if (addressSplitted.includes("Italia")) {
                    addressSplitted[2] = "";
                  }
                  //console.log(addressSplitted);
          
                  this.fullAddress =
                    cap +
                    " - " +
                    location +
                    " (" +
                    prov +
                    ") " +
                    addressSplitted[0] +
                    "," +
                    addressSplitted[1];
          */

          this.arrayIndirizzo = x.candidates[0].formatted_address;
          const body = JSON.stringify({
            origin:
              x.candidates[0].geometry.location.lat +
              "," +
              x.candidates[0].geometry.location.lng,
          });
          this.coordinate = body;
        },
        (err) => {
          console.log("errore: " + err);
        }
      );
    }
  }
  processFile(i) {
    /*  this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(this.blobArray[i])
      ); */

    const reader = new FileReader();

    return reader.readAsDataURL(this.blobArray[i]);
  }

  annullaOrdine(dom = "Sicuro di voler annullare l'ordine?") {
    var domanda = confirm(dom);
    if (domanda == true) {
      this.router.navigate(["/orderbyemployee"]);
    }
  }

  sum(): string {
    let amount = 0;
    let contrassegno = 0;

    this.productList.forEach((c) => {
      amount += c.productPrice * c.count;
    });
    if (this.corriere == "GLS" && this.delivery == true) {
      if (amount > 100) {
        contrassegno = (amount * 1) / 100;
        amount += contrassegno;
      } else {
        contrassegno = 1;
        amount += contrassegno;
      }
    }
    if(this.selectedOption == 'spedizione') amount += this.costoDelivery;
    return amount.toFixed(2).toString();
  }

  setproductPrice(price: number) {
    if (price === null) {
      this.prezzoMinimo = 0;
    }
    this.prezzoMinimo = price;
  }
  toDelivery() {
    this.codiceFiscale = this.codiceFiscale.toUpperCase();
    let currentDate = new Date();
    let dataConfronto = currentDate;
    dataConfronto.setUTCHours(-1, -1, -1);
    const dataTo = new Date(this.deliveryData);
    let day = dataTo.getDay();
    let dateToCompare = dataTo.getDate() + "/" + dataTo.getMonth() + 1;
    let flag = false;
    let dateToComparare = new Date();
    let time = dateToComparare.getHours();
    if (
      this.corriere == "GLS" &&
      time >= 12 &&
      dataTo.getDate() == dateToComparare.getDate()
    ) {
      flag = true;
      alert(
        "Attenzione! Dopo le 12 non è possibile effettuare consegne in giornata."
      );
    }
    if (
      dateToCompare == "25/12" ||
      dateToCompare == "26/12" ||
      dateToCompare == "1/01" ||
      dateToCompare == "6/01" ||
      dateToCompare == "25/04" ||
      dateToCompare == "2/06" ||
      dateToCompare == "15/08"
    ) {
      flag = true;
      alert("Attenzione, non è possibile fare consegne nei giorni festivi.");
    }
    if ((day == 0 || day == 6) && this.corriere == "GLS") {
      flag = true;
      alert(
        "Attenzione! Non è possibile effettuare consegne di Sabato e di Domenica."
      );
    }
    if (dataTo < dataConfronto) {
      flag = true;
      alert(
        "Attenzione, la data di spedizione non può essere precedente a quella di oggi"
      );
    }
    if (!flag) {
      let productChanges = {};
      productChanges["cartUserId"] = this.cartUserId;
      productChanges["total"] = parseFloat(this.sum());
      productChanges["fiscalCode"] = this.codiceFiscale;
      productChanges["startDelivery"] = this.dalleFarmacia;
      productChanges["endDelivery"] = this.alleFarmacia;
      productChanges["deliveryDate"] = formatDate(
        dataTo.getTime(),
        "yyyy-MM-ddTHH:mm:ss.840+0000",
        "it-IT"
      );
      productChanges["products"] = [];

      this.productList.forEach((x) => {
        var price: any = x.productPrice ? x.productPrice.toFixed(2) : "0.00";
        price = parseFloat(price);

        productChanges["products"].push({
          price: price,
          productId: x.productId,
          productName: x.productName,
          count: x.count,
          available: true,
          productDescription: x.productDescription
            ? x.productDescription
            : x.productId,
        });
      });
      let speseDiSpedizione = {
        price: this.costoDelivery,
        productId: "001122334",
        productName: "Spese di spedizione",
        count: 1,
        available: true,
        productDescription: "Spese di spedizione",
      };

      this.cartService.addItem(speseDiSpedizione).subscribe((y) => {
        productChanges["products"].push({
          price: this.costoDelivery,
          productId: "001122334",
          productName: "Spese di spedizione",
          count: 1,
          available: true,
          productDescription: "Spese di spedizione",
        });


        if (this.id2 == 0){
          this.cartService
          .checkoutShipping(
            this.fullAddress,
            this.nameAndSurname,
            this.codiceFiscale.toUpperCase(),
            this.numerotel,
            this.coordinate,
            this.nomeShop.id
          )
          .subscribe(
            (y) => {
              productChanges["orderId"] = y.orderId;
              /*             productChanges["startDelivery"] = this.dalle_alle;
              productChanges["endDelivery"] = this.alleFarmacia; */
              this.user.dataRitiro = this.deliveryData;
              this.idOrdine = y.orderId;
              /* this.orderService
                .statusChanger(this.idOrdine, 6)
                .subscribe((x) => { */
              const sub1$ = this.orderService
                .changePrice(productChanges as IProcutList)
                //.toPromise()
                .subscribe((res) => {
                  this.orderService
                    .setPaymentState(this.idOrdine, this.sum(), this.nomeShop.id)
                    .subscribe((t) => { });
                  this.user.clientedestinatario =
                    res.buyerName + " " + res.buyerSurname;
                  this.user.telefonodestinatario = res.buyerPhone;
                  this.user.noteSpedizione =
                    "Num.Telefono destinatario: " +
                    this.user.telefonodestinatario;
                  let splittedAddressDestinatario: string[];
                  splittedAddressDestinatario = this.fullAddress.split(
                    /[ ][(]|[ ][-][ ]|[)][ ]/g
                  );
                  if (splittedAddressDestinatario.length == 4) {
                    this.user.cAPdestinatario = splittedAddressDestinatario[0];
                    this.user.localitadestinatario =
                      splittedAddressDestinatario[1];
                    this.user.provinciadestinatario =
                      splittedAddressDestinatario[2];
                    this.user.indirizzodestinatario =
                      splittedAddressDestinatario[3];
                  }
                  this.delivery = true;
                });
              /*   }); */
              /* this.cartService.getCart().subscribe((t) => {}); */
            },

            (err) => {
              if (err.includes("gli indirizzi non sono nello stesso comune")) {
                alert(
                  "ATTENZIONE! La spedizione è permessa esclusivamente per consegne Comune su Comune."
                );
              } else {
                alert(
                  "Attenzione! l'indirizzo digitato è errato. Ricordati di inserire anche il numero civico e la sintassi corretta deve essere: Via, civico, Cap Comune Provincia, Italia"
                );
              }
            }
          );
        }

        else{
          this.cartService
          .checkoutShipping(
            this.fullAddress,
            this.nameAndSurname,
            this.codiceFiscale.toUpperCase(),
            this.numerotel,
            this.coordinate,
          )
          .subscribe(
            (y) => {
              productChanges["orderId"] = y.orderId;
              /*             productChanges["startDelivery"] = this.dalle_alle;
              productChanges["endDelivery"] = this.alleFarmacia; */
              this.user.dataRitiro = this.deliveryData;
              this.idOrdine = y.orderId;
              /* this.orderService
                .statusChanger(this.idOrdine, 6)
                .subscribe((x) => { */
              const sub1$ = this.orderService
                .changePrice(productChanges as IProcutList)
                //.toPromise()
                .subscribe((res) => {
                  this.orderService
                    .setPaymentState(this.idOrdine, this.sum())
                    .subscribe((t) => { });
                  this.user.clientedestinatario =
                    res.buyerName + " " + res.buyerSurname;
                  this.user.telefonodestinatario = res.buyerPhone;
                  this.user.noteSpedizione =
                    "Num.Telefono destinatario: " +
                    this.user.telefonodestinatario;
                  let splittedAddressDestinatario: string[];
                  splittedAddressDestinatario = this.fullAddress.split(
                    /[ ][(]|[ ][-][ ]|[)][ ]/g
                  );
                  if (splittedAddressDestinatario.length == 4) {
                    this.user.cAPdestinatario = splittedAddressDestinatario[0];
                    this.user.localitadestinatario =
                      splittedAddressDestinatario[1];
                    this.user.provinciadestinatario =
                      splittedAddressDestinatario[2];
                    this.user.indirizzodestinatario =
                      splittedAddressDestinatario[3];
                  }
                  this.delivery = true;
                });
              /*   }); */
              /* this.cartService.getCart().subscribe((t) => {}); */
            },

            (err) => {
              if (err.includes("gli indirizzi non sono nello stesso comune")) {
                alert(
                  "ATTENZIONE! La spedizione è permessa esclusivamente per consegne Comune su Comune."
                );
              } else {
                alert(
                  "Attenzione! l'indirizzo digitato è errato. Ricordati di inserire anche il numero civico e la sintassi corretta deve essere: Via, civico, Cap Comune Provincia, Italia"
                );
              }
            }
          );
        }
      
      });
      /*    setTimeout("1000"); */
      //console.log(this.fullAddress);
    }
  }

  toCollect() {
    const dataTo = new Date(this.deliveryData);
      let productChanges = {};
      productChanges["cartUserId"] = this.cartUserId;
      productChanges["total"] = parseFloat(this.sum());
      productChanges["startDelivery"] = this.dalleFarmacia;
      productChanges["endDelivery"] = this.alleFarmacia;
      productChanges["deliveryDate"] = formatDate(
        dataTo.getTime(),
        "yyyy-MM-ddTHH:mm:ss.840+0000",
        "it-IT"
      );
      productChanges["products"] = [];
      productChanges["endDelivery"] = this.alleFarmacia;
      productChanges["userId"] = this.userTook.id;

      this.productList.forEach((x) => {
        var price: any = x.productPrice ? x.productPrice.toFixed(2) : "0.00";
        price = parseFloat(price);

        productChanges["products"].push({
          price: price,
          productId: x.productId,
          productName: x.productName,
          count: x.count,
          available: true,
          productDescription: x.productDescription
            ? x.productDescription
            : x.productId,
        });
      });
              const sub1$ = this.orderService
                .changePrice(productChanges as IProcutList)
                //.toPromise()
                .subscribe((res) => {
                  this.orderService.sendPreventivo(res.orderId).subscribe((response)=>{
                    this.router.navigate(["/orderbyemployee"]);
                  })
                });
    
  }
  toProduct() {
    this.delivery = false;
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((term) => term !== ""),
      switchMap((term) => {
        return term.length < 4
          ? of([])
          : this.cartService.getLocation(term).pipe(
            switchMap((values) => {
              let tempAddress = [];
              this.arrayIndirizzi = [];
              tempAddress = values.candidates;

              tempAddress.slice(0, 5).forEach((x) => {
                this.arrayIndirizzi.push(x.formatted_address);
              });

              return of(this.arrayIndirizzi);
            }),
            catchError(() => {
              return of([]);
            })
          );
      }),
      tap(() => [])
    );

  onKeyup() {
    let wordSearch = this.nomeProdotto;
    setTimeout(() => {
      if (wordSearch == this.nomeProdotto) {
        if (this.nomeProdotto) {
          this.onBlurProdotto();
        }
      }
    }, 3000);
    /*     var timer = setTimeout(() => {
      this.onBlurProdotto();
    }, 3000); */
  }
  onKeyupVolantino() {
    let wordSearch = this.nomeProdottoVolantino;
    setTimeout(() => {
      if (wordSearch == this.nomeProdottoVolantino) {
        if (this.nomeProdottoVolantino) {
          this.onBlurProdottoVolantino();
        }
      }
    }, 3000);
    /*   var timer = setTimeout(() => {
      this.onBlurProdottoVolantino();
    }, 3000); */
  }
  onBlurProdotto() {
    let cont = 0;
    this.page = [];
    do {
      this.productService;
      cont++;
      this.productService
        .getAllProdotti(this.nomeProdotto.replace(/ /g, "_"), cont)
        .subscribe((x) => {
          if (x != []) {
            x.forEach((element) => {
              if (element != undefined) this.page.push(element);
              this.item = this.page[0].descrizione;
            });
          }
        });
    } while (cont <= 9);
    /*    
    this.productService
      .getAllProdotti(this.nomeProdotto.replace(/ /g, "_"))
      .subscribe((x) => {
        this.page = x;
        this.item = this.page[0].descrizione;
      }); */
  }
  onBlurProdottoVolantino() {
    this.pageVolantino = [];
    this.productService.getAllInPage(1, 9999).subscribe((x) => {
      this.pageVolantino = this.searchProd(x);
      this.itemVolantino = this.pageVolantino[0].productName;
    });
  }

  searchProd(page) {
    let tempFarm = [];
    page.content
      .filter(
        (v) =>
          v.productName
            .toLowerCase()
            .indexOf(this.nomeProdottoVolantino.toLowerCase()) > -1
      )
      /* .slice(0, 10) */
      .forEach((element) => {
        tempFarm.push(element);
      });
    return tempFarm;
  }

  /*  calcolaPaymentType(cond){
    switch(cond){
      case "Paypal":
        return 0;
      case "Carta di credito":
        return 1;
      case "Contrassegno":
        return 2;
    }
  } */
  sendOrder() {
    this.user.colli = "1";
    this.user.tipoCollo = "0";
    this.user.resaCAssegno = "M";
    this.user.modalitaIncasso = "CONT";
    this.user.clienteritiro = this.user.nomeRichiedente;
    this.user.importocontrassegno = this.sum();
    this.user.pesoReale = this.valoreReale.toString();
    this.users = [];
    var r = confirm("Sei sicuro di aver compilato correttamente i campi?");
    if (r == true) {
      this.confirmedFlag = true;
    }
    let dalle2 = parseInt(this.user.dalle2.substring(0, 2));
    let alle2 = parseInt(this.user.alle2.substring(0, 2));
    if (dalle2 >= alle2) {
      this.confirmedFlag = false;
      window.alert(
        "Attenzione, ora iniziale superiore o uguale a quella finale"
      );
    }
    if (this.valoreReale <= 0) {
      this.confirmedFlag = false;
      window.alert(
        "Attenzione, il peso reale non può essere inferiore o pari a 0"
      );
    }

    if (
      this.user.localitaritiro.toLowerCase() !=
      this.user.localitadestinatario.toLowerCase() &&
      this.corriere == "GLS"
    ) {
      this.confirmedFlag = false;
      window.alert(
        "ATTENZIONE! La spedizione è permessa esclusivamente per consegne Comune su Comune."
      );
    }

    switch (this.misureColli) {
      case "piccolo":
        this.user.misureColli = "1,11,111,1";
        break;
      case "medio":
        this.user.misureColli = "1,22,222,1";
        break;
      case "personalizzato":
        this.user.misureColli =
          this.numeroColli +
          "," +
          this.altezzaColli +
          "," +
          this.larghezzaColli +
          "," +
          this.profonditaColli;
    }
    let currentDate = new Date(this.deliveryData);
    /* let orarioConfronto = currentDate; */
    /*     let nameday = currentDate.getDay();
    let hh = currentDate.getHours(); */
    let d = currentDate.getDate();
    let m = currentDate.getMonth() + 1;
    let y = currentDate.getFullYear();
    this.user.dataRitiro =
      d.toString() + "/" + m.toString() + "/" + y.toString(); /* else {
        let day: any = currentDate.getDate() + 3;
        let month: any = currentDate.getMonth() + 1;
        let year: any = currentDate.getFullYear();
        this.user.dataRitiro =
          day.toString() + "/" + month.toString() + "/" + year.toString();
      } */ /*     if (nameday == 6) {
      let day: any = currentDate.getDate() + 2;
      let month: any = currentDate.getMonth() + 1;
      let year: any = currentDate.getFullYear();
      this.user.dataRitiro =
        day.toString() + "/" + month.toString() + "/" + year.toString();
    } */ /* if (nameday != 5 && nameday != 6) {
      if (hh < 12) {
        let day: any = currentDate.getDate();
        let month: any = currentDate.getMonth() + 1;
        let year: any = currentDate.getFullYear();
        this.user.dataRitiro =
          day.toString() + "/" + month.toString() + "/" + year.toString();
      } else {
        let day: any = currentDate.getDate() + 1;
        let month: any = currentDate.getMonth() + 1;
        let year: any = currentDate.getFullYear();
        this.user.dataRitiro =
          day.toString() + "/" + month.toString() + "/" + year.toString();
      }}
      else{
        this.user.dataRitiro = currentDate.toString();
      } */
    /*   if (nameday == 5) {
      if (hh < 12) {
        let day: any = currentDate.getDate();
        let month: any = currentDate.getMonth() + 1;
        let year: any = currentDate.getFullYear();
        this.user.dataRitiro =
          day.toString() + "/" + month.toString() + "/" + year.toString();
      } */ if (this.confirmedFlag == true) {
      this.user.alle2 = alle2.toString();
      this.user.dalle2 = dalle2.toString();
      this.users.push(this.user);

      if(this.id2 == 0){
      this.userService
        .updateSpedizione(this.users, this.idOrdine, this.nomeShop.id)
        .subscribe((u) => {
          const sub2$ = this.orderService
            .statusChanger(this.idOrdine, 9)
            .subscribe(
              (res) => {
                this.router.navigate(["/orderbyemployee"]);
              },
              () => {
                sub2$.unsubscribe();
              },
              () => {
                sub2$.unsubscribe();
              }
            );
        });
      }
      else{
        this.userService
        .updateSpedizione(this.users, this.idOrdine)
        .subscribe((u) => {
          const sub2$ = this.orderService
            .statusChanger(this.idOrdine, 9)
            .subscribe(
              (res) => {
                this.router.navigate(["/orderbyemployee"]);
              },
              () => {
                sub2$.unsubscribe();
              },
              () => {
                sub2$.unsubscribe();
              }
            );
        });
      }
    }
  }
  async _findPlace() {
    let url = encodeURIComponent(this.indirizzo);
    console.log(
      `https://nominatim.openstreetmap.org/search?q=${url}&country=it&format=jsonv2&accept-language=it&addressdetails=1&countrycodes=it`
    );
    console.log("entro nel try di _findPlace");
    let response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${url}&country=it&format=jsonv2&accept-language=it&addressdetails=1&countrycodes=it`,
      // `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${url}&key=AIzaSyCbvE3uw4Qjbm8II5q_ChsL3ffOMwWudVA&sessiontoken=1234567890&language=it&components=country:it`,
      {
        method: "GET",
      }
    );
    console.log(response);
    switch (response.status) {
      case 200:
        console.log("sei in 200 di cerca prodotto");
        if (response != null) {
          let responseJson = await response.json();
          console.log(responseJson);

          var places = [];
          for (let index = 0; index < responseJson.length; index++) {
            const item = responseJson[index].address;
            console.log(item);

            /*
                  "road": "Via dei Mille",
                          "suburb": "Municipalità 1",
                          "city": "Napoli",
                          "county": "Napoli",
                          "state": "Campania",
                          "postcode": "80121",
                          "country": "Italia",
                          "country_code": "it"
              */

            console.log("esco nel try di _findPlace");
          }
        }
    }
  }

  searchUser(){
    this.userService.getUserByParameter(this.searchUserTemp).subscribe((resp)=>{
      console.log(resp);
      if((!resp.email.includes("employee@eufarma") && this.id2 == resp.shop) || this.id2 == 0)
        this.userTook = resp;
    else{

      this.userTook = null;
    }
    })
  }

  removeUser(){
    this.userTook = null;
  }


  searchFarmacia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? [] : this.searchFarm(term))
    );

  searchFarm(term: string) {
    return this.listafarmacieOrdinata
      .filter(v => v.name.toLowerCase().includes(term.toLowerCase()))
      .slice(0, 10);
  }

  formatter = (x: { name: string }) => x.name;

  onFarmaciaSelected(event: any) {
    this.farmaciaSelected = event.item;
    
    console.log(this.farmaciaSelected);
  }

  farmaciaList() {
    this.farmacieService.getFarmacie().subscribe((data) => {
      let farmacia = data.filter((e) => e.id != 0 && e.deliveryService == "Altro");
      farmacia = farmacia.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listafarmacieOrdinata = farmacia.slice();
    
    });
  }
}
