import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { SliderNewsService } from 'src/app/services/slider-news.service';

@Component({
  selector: 'app-slider-news-modify',
  templateUrl: './slider-news-modify.component.html',
  styleUrls: ['./slider-news-modify.component.css']
})
export class SliderNewsModifyComponent implements OnInit {
  title: string
  startTime = new Date();
  endTime = new Date();
  body: string;
  idNews;
  shopId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private newsService: SliderNewsService) { }

  ngOnInit() {
  this.idNews = this.route.snapshot.paramMap.get("id");
  this.newsService.getSingleNews(this.idNews).subscribe((x)=>{
    this.title = x.title,
    this.startTime = x.startDate;
    this.endTime = x.endDate;
    this.body = x.body;
    this.shopId = x.shop.id

  })
  }


  onSubmit(){
    let dateToCompare = new Date (this.startTime);
    let dateEndCompare = new Date (this.endTime);
    if(dateToCompare.getTime() < dateEndCompare.getTime()){

    
    this.spinner.show();

    let body = {
      id: this.idNews,
      title: this.title,
      startDate: this.startTime,
      endDate: this.endTime,
      body: this.body,
    }
    this.newsService.modifyNews(body).subscribe((x)=>{
      this.spinner.hide();
      alert("Modifica della news avvenuta con successo!")
      this.router.navigate(["/newsList/" + this.shopId])
    },
    (err)=>{
      this.spinner.hide();
      alert("Qualcosa è andato storto!")
    })
  }
  else{
    alert("Attenzione! Imposta una data valida")
  }
}


}
