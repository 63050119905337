import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessagingService } from "src/app/messaging.service";
import { BehaviorSubject } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { SwPush } from "@angular/service-worker";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit /* , OnDestroy */ {
  message: BehaviorSubject<any>;
  constructor(
    readonly swPush: SwPush,
    private msgService: MessagingService,
    private _userSrv: UserService,
    private router: Router,
  ) {
    this.message = new BehaviorSubject(null);
    /* this.detectBrowserVersion(); */

  }

  private subscribeToPush() {
    return this.swPush.requestSubscription({
      serverPublicKey:
        "BG4gZFop4ltXKM0Gz7mBlxbDg_YFYeSrt2vKThoR12DSu_1oV0mDWpQNTc5CpStWCgeFXihpprkTZe3Xq0nBMFU",
    });
  }

  setTokenAndUpdate() {
    this._userSrv.setToken();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;
  }
  ngOnInit() {
/*     if (!window.location.pathname.includes("profiloutente")) {
      window.onbeforeunload = () => {
        this._userSrv.logout();
      };
    } */
    this.setTokenAndUpdate();
    /* 
    this.msgService.requestPermission();
    this.msgService.messaging.getToken();
    this.msgService.messaging.onTokenRefresh(() => {
      this.msgService.messaging.getToken().then((tokenchange) => {
        this._userSrv.updatetokenFirebase(tokenchange).subscribe(x => { })
      })
    })
    this.msgService.receiveMessage();

    this.message = this.msgService.currentMessage;  */

    // localStorage.getItem("token");
    // setInterval(function () {
    //   this.msgService.tokenFirebase.subscribe((tok) => {
    //     this.userService.updatetokenFirebase(+this.userService.idUser, this.msgService.tokenUser).subscribe(x => {
    //     })
    //   });
    // }.bind(this), 3000);

    /*
    // TEST dwPush
    console.log('this.swPush.isEnabled ', this.swPush.isEnabled);
    if (this.swPush.isEnabled) {
      this.subscribeToPush().then(
        result => {
          // Code
          const resultjson = result.toJSON();
          console.log('resultjson: ',resultjson);
          this.msgService.messaging.getToken().then((tokenchange) => {
            console.log(tokenchange);
          }) ;
        },
        error => {
          // Code
          console.log(error);
        }
      );
      this.swPush.messages.subscribe((x) => {
        console.log('swPush.messages: ', x);
        this.message.next(x);
      });

      this.swPush.notificationClicks.subscribe(
        (x) => {
          console.log(x);
        });
    }*/
  }
  detectBrowserVersion() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      var ua = navigator.userAgent;
      var tem;
      var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      let browserVersion = M.join(' ');
      console.log(browserVersion)
      if (parseInt(browserVersion.split(" ")[0] == 'Chrome' && browserVersion.split(" ")[1]) < 70) {
        var r = confirm("Attenzione, la versione di Chrome risulta obsoleta e alcune funzionalità potrebbero essere bloccate, vuoi essere reindirizzato alla pagina per aggiornarlo?")
        if (r) {
          let url = location.href;
          location.href = "https://support.google.com/chrome/answer/95414?hl=it&co=GENIE.Platform%3DDesktop";

        }
      }
    }
    else {
      alert("Attenzione! Il sito è ottimizzato su Chrome, alcune funzionalità potrebbero non funzionare.")
    }
  }

  /*   ngOnDestroy() {
    this._userSrv.logout();
  } */
}
