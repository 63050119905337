import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OrderService } from "../../services/order.service";
import { Order } from "../../models/Order";
import { OrderStatus } from "../../enum/OrderStatus";
import { UserService } from "../../services/user.service";
import { JwtResponse } from "../../response/JwtResponse";
import { Subscription, Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { Role } from "../../enum/Role";
import { Location } from "@angular/common";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"]
})
export class OrderComponent implements OnInit, OnDestroy {
  page: any;
  OrderStatus = OrderStatus;
  currentUser: JwtResponse;
  Role = Role;
  name$;
  name: string;
  querySub: Subscription;
  filtro: string;
  direzione: string;
  // aggiunto ricerca

  constructor(
    private httpClient: HttpClient,
    private orderService: OrderService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {

    this.currentUser = this.userService.currentUserValue;
    this.querySub = this.route.queryParams.subscribe(() => {
      this.update();
    });
  }

  update() {
    let nextPage = 1;
    let size = 15;
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      size = +this.route.snapshot.queryParamMap.get("size");
    }

  }

  cancel(order: Order) {
    this.orderService.cancel(order.orderId).subscribe(res => {
      if (res) {
        order.orderStatus = res.orderStatus;
      }
    });
  }

  finish(order: Order) {
    this.orderService.finish(order.orderId).subscribe(res => {
      if (res) {
        order.orderStatus = res.orderStatus;
      }
    });
  }

  ngOnDestroy(): void {
    this.querySub.unsubscribe();
  }

}
