import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { SliderNewsService } from 'src/app/services/slider-news.service';

@Component({
  selector: 'app-slider-news-info',
  templateUrl: './slider-news-info.component.html',
  styleUrls: ['./slider-news-info.component.css']
})
export class SliderNewsInfoComponent implements OnInit {
  title: string
  startTime: Date
  endTime: Date
  body: string;
  idNews;
  shopId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private newsService: SliderNewsService) { }

  ngOnInit() {
    this.spinner.show()
  this.idNews = this.route.snapshot.paramMap.get("id");
  this.newsService.getSingleNews(this.idNews).subscribe((x)=>{
    this.title = x.title,
    this.startTime = x.startDate;
    this.endTime = x.endDate;
    this.body = x.body;
    this.shopId = x.shop.id
    this.spinner.hide();

  })
  }



}
