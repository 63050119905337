import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { apiUrl } from "../../environments/environment";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ProductDiscountService {

  constructor(private http: HttpClient, private userService: UserService) {

   }
    
   
   //metodo che ottiene la lista di tutti prodotti scontati
  getAllDiscountedProducts(): Observable<any> {
    return this.http.get<any[]>(`${apiUrl}/getAllDiscountedProduct`);
  }
  
   //metododo che ottiene un prodotto scontato nello specifico
  getDiscountedProduct(idProduct): Observable<any> {
    return this.http.get<any[]>(`${apiUrl}/getDiscountedProduct/${idProduct}`);
  }

   //metodo che aggiunge una prodotto scontato alla lista
   addDiscountedProduct(body, photo): Promise<any> {

    var formdata = new FormData();
    formdata.append("productInDiscountToSaveRequest", JSON.stringify(body))    
    formdata.append("icon", photo)
  
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userService.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/discountedProduct/new`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));

  
  }

    //metodo che modifica un prodotto scontato in specifico  
    modifyDiscountedProduct(body, photo): Promise<any> {
      var formdata = new FormData();
      formdata.append("productInDiscountToUpdateRequest", JSON.stringify(body))    
      formdata.append("icon", photo)
    
      var requestOptions = {
        method: "PUT",
        body: formdata,
        headers: new Headers({
          Authorization: "Bearer " + this.userService.currentUserValue.token,
        }),
      };

    return fetch(`${apiUrl}/discountedProduct/modify`, requestOptions)
      .then((response) => response)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
    }

     //metodo che rimuove un prodotto scontato dalla lista
  removeDiscountedProduct(id): Observable<any> {
    return this.http.delete<any>(`${apiUrl}/discountedProduct/delete/${id}`);
  }
}
