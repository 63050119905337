import { Component, OnInit, Input, OnDestroy, Inject, ViewChild } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { OrderService } from "../../services/order.service";
import { Order } from "../../models/Order";
import { Router, ActivatedRoute } from "@angular/router";
import { OrderStatus } from "../../enum/OrderStatus";
import { UserService } from "../../services/user.service";
import { JwtResponse } from "../../response/JwtResponse";
import { Subscription } from "rxjs";
import { Role } from "../../enum/Role";
import { Location } from "@angular/common";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { map } from "rxjs/operators";
import { async } from "q";
import { Sort } from "@angular/material/sort";
import { MessagingService } from "src/app/messaging.service";
import { isHitsEqual } from "@fullcalendar/interaction/interactions/HitDragging";
import { IMessagePushNotification } from "src/app/toaster/toaster.component";
import { Farmacia } from "src/app/models/Farmacia";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { NgxSpinnerService } from "ngx-spinner";
import { apiUrl } from "src/environments/environment";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogCouponEmployee } from "../couponlist-employee/couponlist-employee.component";
import { database } from "firebase";
import { ThemeService } from "ng2-charts";
import { ModalOrderEmployeeComponent } from "./modal-order-employee/modal-order-employee.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export interface DialogData {
  from;
  to;
}

export class Prodotti {
  available: boolean;
  cartUserId: string;
  categoryType: number;
  count: number;
  id: number;
  productDescription: string;
  productIcon: string;
  productId: string;
  productName: string;
  productPrice: number;
  productStock: string;
}
@Component({
  selector: "app-order-employee",
  templateUrl: "./order-employee.component.html",
  styleUrls: ["./order-employee.component.css"],
})
export class OrderEmployeeComponent implements OnInit, OnDestroy {
  @ViewChild('modalOrderEmployee', { static: true }) private orderEmployeeModal: ModalOrderEmployeeComponent;
  order_spedizione: boolean = false;
  page: any;
  OrderStatus = OrderStatus;
  currentUser: JwtResponse;
  Role = Role;
  listaOrdini: Order[];
  name$;
  name: string;
  order$: Observable<Order>;
  querySub: Subscription;
  searchText: string;
  token2: string;
  token: string;
  email: string;
  nomeFarmacia: string;
  farmacia: string;
  order: Order;
  surname: string;
  ordinamento = "orderId";
  direzionamento = "asc";
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength1: number;
  size: number;
  localStorage2: Storage;
  cont: number = -1;
  @Input() currentPage: number;
  model: any = {
    email: "",
    password: "",
    fbtoken: this.msgService.tokenUser,
    remembered: true,
  };
  oggettoDirezione = {
    field: "updateTime",
    dir: "desc",
  };
  updateTimeDate: any;
  updateTime: string; //"2019-04-10"
  searchTerm: string; //"%25%25"
  searchTermTemp;
  statoOrdine: any; //stato ordine form
  actualStatus: number;
  listaFarmacie: Farmacia[];
  status_ordine = [
    { stato: "Tutti gli ordini" },

    { stato: "Confermato" },

    { stato: "Annullato" },

    { stato: "In attesa di preventivo" },

    { stato: "Preventivo elaborato" },

    { stato: "Ordine ritirato" },

    { stato: "Spedizione in attesa di preventivo" },

    { stato: "Spedizione in elaborazione" },

    { stato: "In attesa di pagamento" },

    { stato: "Ordine spedito" },

    { stato: "Ordine consegnato" },
  ];
  fromTime;
  toTime;
  uplhandler = null;
  listOrder$: Subscription;
  idshop: number;
  urlOrdini: string;
  showButton = false;
  blob: Blob;
 
  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private msgService: MessagingService,
    private FarmacieService: FarmacieService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private farmaciaService: FarmacieService,
    private modalService: NgbModal
  ) {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("document is hidden");
      } else {
        if (this.userService.currentUserValue.role == "ROLE_EMPLOYEE") {
          this.update();
        }
      }
    });
    if (this.router.url.includes("/orderbyemployee")) {
      this.listOrder$ = this.msgService.currentMessage.subscribe(async (x) => {
        if (x) {
          if (x.title.includes("Preventivo") || x.title.includes("Ordine"))
            await this.update();
        }
      });
    }
  }

  ngOnInit() {
    /*   this.spinner.show(); */
    const account = this.userService.currentUserValue.account;
    this.userService.get(account).subscribe((x) => {
      this.idshop = x.shop;
    });

    this.currentUser = this.userService.currentUserValue;
    this.querySub = this.route.queryParams.subscribe(() => {
      this.update();
    });
    /* this.uplhandler = setInterval(
      function () {
        this.update();
      }.bind(this),
      500000
    );*/
  }

  async update() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.orderService
        .getPageEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.searchTerm
        )
        .subscribe(
          (page) => {
            /*      for (var i = 0; i < page.content.length; i++) {
              if (page.content[i].paymentType == 2) {
                var totale = 0;
                var prodotti = page.content[i].products;
                for (var j = 0; j < prodotti.length; j++) {
                  if (prodotti[j].productName != "Spese di spedizione") {
                    totale += prodotti[j].productPrice * prodotti[j].count;
                  }
                }
                let gls = totale / 100;
                page.content[i].orderAmount = (
                  parseFloat(page.content[i].orderAmount) +
                  parseFloat(gls < 1 ? "0.00" : gls.toFixed(2))
                ).toFixed(2);
              }
            } */
            this.page = page;
            this.spinner.hide();
          },
          (_) => {
            console.log("Get Orde Failed");
            this.spinner.hide();
          }
        );
    } else {
      this.orderService
        .getPageEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.searchTerm
        )
        .subscribe(
          (page) => {
            /*       for (var i = 0; i < page.content.length; i++) {
              if (page.content[i].paymentType == 2) {
                var totale = 0;
                var prodotti = page.content[i].products;
                for (var j = 0; j < prodotti.length; j++) {
                  if (prodotti[j].productName != "Spese di spedizione") {
                    totale += prodotti[j].productPrice * prodotti[j].count;
                  }
                }
                let gls = totale / 100;
                page.content[i].orderAmount = (
                  parseFloat(page.content[i].orderAmount) +
                  parseFloat(gls < 1 ? "0.00" : gls.toFixed(2))
                ).toFixed(2);
              }
            } */
            this.page = page;
            this.spinner.hide();
            if (page.content.length > 0) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          },
          (_) => {
            console.log("Get Orde Failed");
            this.spinner.hide();
          }
        );
    }
    return await this.page;
  }

  deleteOrdine(order: Order) {
    var domanda = confirm(
      "Sei sicuro di voler annullare il preventivo n." + order.orderId + " ?"
    );
    if (domanda == true) {
      this.orderService.nullPreventivo(order.orderId).subscribe((res) => { });
      /*       this.orderService.show(order.orderId).subscribe((x) => { */
      /*        this.email = x.buyerEmail; */

      /*     this.userService.get(this.email).subscribe(async (y) => { */
      /*       this.token2 = y.fbtoken; */
      /*    this.notificatioService.invionotifica(
           [this.token2],
           "Il preventivo relativo all'ordine n." +
             order.orderId +
             " è stato annullato, contattare la tua farmacia di riferimento per informazioni",
           "Notifica preventivo ordine n." + order.orderId
         ); */
      this.update();
      /*    }); */
      /*  }); */
    }
  }

  cancellaOrdine(order: Order) {
    this.orderService.cancel(order.orderId).subscribe((res) => {
      if (res) {
        order.orderStatus = "2";
      }
    });
  }

  confermaOrdine(order: Order) {
    this.orderService.finish(order.orderId).subscribe((res) => { });
  }

  ordineRitirato(order: Order) {
    let orderStatus;
    this.orderService.orderRetired(order.orderId).subscribe(async (x) => {
      await this.update();
    });
    /*  this.orderService.show(order.orderId).subscribe((x) => {
       this.email = x.buyerEmail;
       orderStatus = x.orderStatus; */

    /*    this.userService.get(this.email).subscribe((y) => {
         this.token = y.fbtoken;
         switch (orderStatus) {
           case 1:
             this.notificatioService.invionotifica(
               [this.token],
               " L'ordine n." +
                 order.orderId +
                 " è stato ritirato con successo.",
               "Notifica ordine n." + order.orderId
             );
             break;
 
           case 9:
             this.notificatioService.invionotifica(
               [this.token],
               " L'ordine n." +
                 order.orderId +
                 " è stato preso in carico dal corriere con successo.",
               "Notifica ordine n." + order.orderId
             );
         } */
    /*    }); */

    /*   }); */
  }

  ordineConsegnato(order: Order) {
    this.farmaciaService.getFarmacia(this.idshop).subscribe((farma) => {
      if (farma.deliveryService == "Altro") {
        let orderStatus;
        this.orderService.statusChanger(order.orderId, 10).subscribe(async (x) => { await this.update(); });
        /*     this.orderService.show(order.orderId).subscribe((x) => { */
        /*      this.email = x.buyerEmail;
             orderStatus = x.orderStatus; */

        /*  this.userService.get(this.email).subscribe((y) => {
           this.token = y.fbtoken;
           this.notificatioService.invionotifica(
             [this.token],
             " L'ordine n." +
               order.orderId +
               " è stato consegnato con successo.",
             "Notifica ordine n." + order.orderId
           );
         }); */

        /*  }); */
      } else {
        alert(
          "Spiacente, questa funzione è disponibile solo con 'Altro Corriere'."
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.querySub.unsubscribe();
    this.listOrder$.unsubscribe();
    clearInterval(this.uplhandler);
    this.uplhandler = null;
  }

  invioNotifica(order: Order) {
    this.orderService.sendNotifyForOrder(order.orderId).subscribe((x) => {
      alert("Notifica correttamente inviata!")



    })
    /*     this.orderService.show(order.orderId).subscribe((x) => {
          this.email = x.buyerEmail;
    
          this.userService.get(this.email).subscribe((y) => {
            this.token = y.fbtoken;
            this.notificatioService.invionotifica(
              [this.token],
              "Ricordati di venire a ritirare l'ordine n." + order.orderId,
              "Notifica ordine n." + order.orderId
            );
          });
        }); */
  }

  ordina(ordinaField) {
    this.ordinamento = ordinaField;
    this.oggettoDirezione.field = ordinaField;
    if (this.oggettoDirezione.dir == "asc") {
      this.oggettoDirezione.dir = "desc";
    } else {
      this.oggettoDirezione.dir = "asc";
    }
    this.setOrderSession(this.oggettoDirezione);
    this.orderService
      .getPageEmployee(
        this.page.number + 1,
        15,
        this.oggettoDirezione.field,
        this.oggettoDirezione.dir,
        this.actualStatus,
        this.updateTime,
        this.searchTerm
      )
      .subscribe(
        (page) => {
          this.page = page;
        },
        (_) => {
          console.log("Get Order Failed");
          this.spinner.hide();
        }
      );
  }

  checkOrderIcon(field: string, dir: string) {
    return (
      this.oggettoDirezione.field === field && this.oggettoDirezione.dir === dir
    );
  }

  checkOrderIconStatus() {
    return this.cont;
  }

  getOrderSession() {
    return localStorage.getItem("order_ordinamento");
  }

  setOrderSession(oggettoDirezione) {
    localStorage.setItem("order_ordinamento", JSON.stringify(oggettoDirezione));
  }

  compilaSpedizione(order) {
    this.order_spedizione = true;
    //console.log(utente.id);
    ("order/:orderid");
    this.router.navigateByUrl(`order/${order}`);
  }
  onSubmit() {
    let id = 0;
    let nextPage = 1;
    this.size = 15;
    if (this.searchTermTemp != undefined && this.searchTermTemp != "") {
      this.searchTerm = "%25" + this.searchTermTemp + "%25";
    } else if (this.searchTermTemp == undefined || this.searchTermTemp == "") {
      this.searchTerm = undefined;
    }

    if (this.updateTimeDate != undefined && this.updateTimeDate != "") {
      this.updateTime = this.updateTimeDate.toString();
    } else if (this.updateTimeDate == undefined || this.updateTimeDate == "") {
      this.updateTime = undefined;
    }

    if (this.statoOrdine == undefined || typeof this.statoOrdine != "string") {
      this.statoOrdine = undefined;
    }

    switch (this.statoOrdine) {
      case "Tutti gli ordini":
        this.actualStatus = -1;
        break;

      case "Confermato":
        this.actualStatus = 1;
        break;
      case "Annullato":
        this.actualStatus = 2;
        break;
      case "In attesa di preventivo":
        this.actualStatus = 3;
        break;
      case "Preventivo elaborato":
        this.actualStatus = 4;
        break;
      case "Ordine ritirato":
        this.actualStatus = 5;
        break;
      case "Spedizione in attesa di preventivo":
        this.actualStatus = 6;
        break;
      case "In attesa di pagamento":
        this.actualStatus = 7;
        break;
      case "Spedizione in elaborazione":
        this.actualStatus = 8;
        break;
      case "Ordine spedito":
        this.actualStatus = 9;
        break;
      case "Ordine consegnato":
        this.actualStatus = 10;
        break;
    }

    let orderSession = JSON.parse(this.getOrderSession());
    if (!orderSession) {
      this.setOrderSession(this.oggettoDirezione);
      orderSession = JSON.parse(this.getOrderSession());
    }
    this.oggettoDirezione = orderSession;

    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      id = +this.route.snapshot.queryParamMap.get("id");

      this.orderService
        .getPageEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.searchTerm
        )
        .subscribe(
          (page) => {
            this.page = page;
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    } else {
      this.orderService
        .getPageEmployee(
          nextPage,
          this.size,
          orderSession.field,
          orderSession.dir,
          this.actualStatus,
          this.updateTime,
          this.searchTerm
        )
        .subscribe(
          (page) => {
            this.page = page;
          },
          (_) => {
            console.log("Get Order Failed");
            this.spinner.hide();
          }
        );
    }
  }
  /* pdfOrdini() {
     this.orderService.getOrderPdfByFarm2(this.idshop).subscribe(
       (x) => {
         const byteArray = new Uint8Array(atob(x.pdf).split('').map(char => char.charCodeAt(0)));
         console.log(byteArray);
         let blob = new Blob([byteArray], {
           type: "application/pdf",
         });
         let dwldLink = document.createElement("a");
         let url = URL.createObjectURL(blob);
         let isSafariBrowser =
           navigator.userAgent.indexOf("Safari") != -1 &&
           navigator.userAgent.indexOf("Chrome") == -1;
         if (isSafariBrowser) {
           //if Safari open in new window to save file with random filename.
           dwldLink.setAttribute("target", "_blank");
         }
         var filename = "ListaOrdiniFarmacia" + this.idshop;
         dwldLink.setAttribute("href", url);
         dwldLink.setAttribute("download", filename + ".pdf");
         dwldLink.style.visibility = "hidden";
         document.body.appendChild(dwldLink);
         dwldLink.click();
         document.body.removeChild(dwldLink);
       },
       (err) => {
         console.log(err);
       }
     );
   }*/

  csvOrdini() {
    this.spinner.show();
    this.orderService.getOrderCsv().then((x) => {
      this.downloadFileCsv(x, "lista-ordini");
    }),
      (err) => {
        alert("Attenzione, la farmacia selezionata non ha ordini.");
        console.log(err);
      };
  }
  ConvertToCSV(objArray, headerList) {
    let detailproduct = [
      /*       "available", */
      /*      "cartUserId", */
      /*       "categoryType", */
      /*  "count", */
      /*  "id", */
      "productDescription",
      /*   "productIcon", */
      "productId",
      "productName",
      /*    "productPrice",
      "productStock", */
    ];
    let detailshop = ["id", "name"];
    let products: Prodotti[];
    let shopInfo: any;
    let tempShop: {
      id;
      name;
    };
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No;";
    for (let index in headerList) {
      row += headerList[index] + ";";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      /* per filtrare per status     if (array[i].orderStatus == "1" || array[i].orderStatus == "10") { */
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        products = array[i].customOrderExport["products"];
        shopInfo = array[i].customOrderExport["shop"];
        for (let j = 0; j < products.length; j++) {
          for (let index1 in detailproduct) {
            if (products[j][detailproduct[index1]] == null) {
              products[j][detailproduct[index1]] = " ";
            }
            /*  console.log(products[j]); */
            //console.log(products[j][detailproduct[index1]]);
          }
        }
        /*   for (let j = 0; j < shopInfo.length; j++) {
          for (let index1 in detailshop) {
            if (shopInfo[j][detailshop[index1]] == null) {
              shopInfo[j][detailshop[index1]] = " ";
            }

          }
        } */
        if (head == "products") {
          products.forEach((element) => {
            element.productDescription = element.productDescription.replace(
              ";",
              "-"
            );
            delete element.available;
            delete element.cartUserId;
            delete element.categoryType;
            delete element.count;
            delete element.id;
            delete element.productIcon;
            delete element.productPrice;
            delete element.productStock;
            delete element.productId;
          });

          line +=
            array[i].customOrderExport[head] != null
              ? ";" + JSON.stringify(products)
              : ";";
        } else if (head == "shop") {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" +
              array[i].customOrderExport[head].id +
              "  " +
              array[i].customOrderExport[head].name
              : ";";
        } else if (head == "fidelityNumber") {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + '="' + array[i].customOrderExport[head] + '"'
              : ";";
        } else {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + array[i].customOrderExport[head]
              : ";";
        }
        /* if (head == "shop") {
          tempShop = {
            id: shopInfo.id,
            name: shopInfo.name,
          };
          
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + JSON.stringify(tempShop)
              : ";";
        } */

        /* line +=
          array[i].customOrderExport[head] != null
            ? ";" + array[i].customOrderExport[head]
            : ";"; */
      }
      str += line + "\r\n";
    }
    /*  } */

    return str;
  }

  pdfOrdini() {
    /*     var r = confirm("vuoi scaricare un pdf in base ad un range di tempo?"); */
    /*  if (r == true) { */
    const dialogRef = this.dialog.open(DialogOrderEmployee, {
      width: "300px",
      data: {
        from: this.fromTime,
        to: this.toTime,
      },
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result != undefined) {
        this.fromTime = result.from;
        this.toTime = result.to;
        this.orderService
          .getPdfEmployeeFromTo(this.fromTime, this.toTime)
          .subscribe(
            (x) => {
              var newBlob = new Blob([x], { type: "application/pdf" });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              var link = document.createElement("a");
              link.href = data;
              let today = new Date();
              link.download = "lista_ordini_" + today.getTime() + ".pdf";
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );

              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
              }, 100);
            },
            (e) => {
              alert("Attenzione! Data non valida.");
              console.log(e);
            }
          );
      }
    }); /* else {
     } 
      this.orderService
        .getPdfEmployee(this.actualStatus, this.updateTime, this.searchTerm)
        .subscribe(
          (x) => {
            var newBlob = new Blob([x], { type: "application/pdf" });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

          
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement("a");
            link.href = data;
            let today = new Date();
            link.download = "lista_ordini_" + today.getTime() + ".pdf";
           
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );

            setTimeout(function () {

              window.URL.revokeObjectURL(data);
              link.remove();
            }, 100);
          },
          (e) => {
            console.log(e);
          }
        );
    
    } */
  }




  downloadFile(data, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "buyerAddress",
      "buyerEmail",
      "buyerName",
      "buyerPhone",
      "buyerSurname",
      "createTime",
      "deliveryDate",
      "deliveryOrderStatus",
      "endDelivery",
      "fidelityNumber",
      "fiscalCode",

      /*   "idShop", */
      "orderAmount",
      "orderId",
      "orderStatus",
      "paymentType",
      "products",
      "shop",
      "startDelivery",
      //"updateTime",
    ]);
    //console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.spinner.hide();
  }

  downloadFileCsv(data: any, filename) {
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, "Book.csv");
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
    this.spinner.hide();
  }

  async openAutomaticAnswersModal(buyerEmail) {
    const modalRef = this.modalService.open(ModalOrderEmployeeComponent); 
    modalRef.componentInstance.buyerEmail = buyerEmail
    modalRef.componentInstance.idFarmacia = this.idshop;
  }
}

@Component({
  selector: "dialog-orderEmployee",
  templateUrl: "./dialog-orderEmployee.html",
})
export class DialogOrderEmployee {
  constructor(
    public dialogRef: MatDialogRef<DialogOrderEmployee>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }
}
