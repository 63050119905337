import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { OrderService, IProcutList } from "../../services/order.service";
import { Order } from "../../models/Order";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { formatDate } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { Location } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { Item } from "src/app/models/Item";
import { isNullOrUndefined } from "util";
import { Lightbox } from "ngx-lightbox";
import { apiUrl } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ImageSnippet } from "../aggungi-evento/aggungi-evento.component";
import { FarmacieService } from "src/app/services/farmacie.service";

@Component({
  selector: "app-prize-detail",
  templateUrl: "./prize-detail.component.html",
  styleUrls: ["./prize-detail.component.css"],
})
export class PrizeDetailComponent implements OnInit {
  order$: Observable<Order>;
  productList: ProductInOrder[];
  orderAmount = 0;
  deliveryData;
  name$;
  name: string;
  name1: string;
  orderId: string;
  emailProva: string;
  surnameOrder: string;
  surname1: string;
  minDate: string;
  prezzoMinimo: number;
  productFlag: boolean = false;
  priceFlag: boolean = false;
  dalleFarmacia;
  alleFarmacia;
  costoDelivery: number;
  currentDate: Date = new Date();
  blobArray: Array<Blob> = [];
  blobUrl;
  selectedFile: ImageSnippet;
  nomeProdotto: string;
  quantitaProdotto: number;
  quantitaProdottoListino: number;
  stringaModifica: string = "Modifica preventivo";
  tipoDelivery = [
    {
      tipo: "Domicilio",
    },
    { tipo: "Farmacia" },
  ];
  tipoConsegna: any;
  giftCard: string;
  corriere: string;
  aggiungiSlot: boolean = false;
  item: string = "";
  itemVolantino;
  page: Array<any>;
  nomeProdottoListino;
  listaProdotto: any[] = [];
  pageVolantino;
  desc: string;
  noteSpedizione: string;
  promotionalCode: string;
  order_status;
  _albums: Array<any> = [];
  nome_prodotto: string;
  idshop;

  constructor(
    private FarmacieService: FarmacieService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationServiceService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.minDate = formatDate(Date.now(), "yyyy-MM-dd", "it-IT");
    const account = this.userService.currentUserValue.account;
    this.userService.get(account).subscribe((x) => {
      this.idshop = x.shop;
    });
    this.orderService
      .showReward(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        this.emailProva = x.buyerEmail;

        this.name1 = x.buyerName;
        this.surname1 = x.buyerSurname;
        this.orderId = this.route.snapshot.paramMap.get("id");
        this.order_status = x.orderStatus;
        this.promotionalCode = x.promotionalCode;
        if (this.order_status == 6) this.costoDelivery = 2.5;
        else this.costoDelivery = 0;
        this.userService.get(x.buyerEmail).subscribe((z) => {
          this.userService.giftcardbyid(z.idGiftCard).subscribe((x) => {
            this.giftCard = x.number ? x.number : " ";
            this.FarmacieService.getFarmacia(z.shop).subscribe((y) => {
              this.corriere = y.deliveryService;
            });
          });
        });
      });

    /* this.orderService
        .allegatoRicette(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          console.warn(x);
          this.urlAllegati = x.url;
        }); */
    const subs$ = this.orderService
      .showReward(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        let products = x.products.map((x) => {
          x.oldproductPrice = x.productPrice;
          this.desc = x.productDescription;
          this.nome_prodotto = x.productName;
          x.available = x.available == null ? false : x.available;
          /*   x.dalle = this.dalleFarmacia;
          x.alle = this.alleFarmacia; */
          if (this.nome_prodotto.includes("downloadFile")) {
            let numberValue = parseInt(this.desc, 10);
            for (let i = 1; i <= numberValue; i++) {
              const src = `${apiUrl}/getBillImage/${this.route.snapshot.paramMap.get(
                "id"
              )}/${i}`;
              const descrizione = "allegato_" + i + ".jpg";
              const album = {
                src: this.sanitizer.bypassSecurityTrustUrl(src),
                descrizione: descrizione,
              };
              const blob = new Blob([src], {
                type: "application/octet-stream",
              });
              this.blobArray.push(blob);

              this._albums.push(album);
            }
          }

          return x;
        });

        /*   this.orderService.getBillImage(this.route.snapshot.paramMap.get("id"), this.desc).subscribe((answer) =>{
          this._albums = answer;
           }); */
        this.productList = products;
        /*this.productList.forEach((element) => {
          if (element.productDescription == null) {
            element.productDescription = element.productId;
          }
        });*/
        subs$.unsubscribe();
      });
  }
  processFile(i) {
    /*  this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(this.blobArray[i])
      ); */

    const reader = new FileReader();

    return reader.readAsDataURL(this.blobArray[i]);
  }

  inviaPreventivo() {
    let dataConfronto = this.currentDate;
    dataConfronto.setUTCHours(-1, -1, -1);
    let productChanges = {};
    const dataTo = new Date(this.deliveryData);
    productChanges["orderId"] = parseInt(
      this.route.snapshot.paramMap.get("id"),
      10
    );
    productChanges["total"] = parseFloat(this.sum("price"));
    productChanges["deliveryDate"] = formatDate(
      dataTo.getTime(),
      "yyyy-MM-ddTHH:mm:ss.840+0000",
      "it-IT"
    );
    productChanges["startDelivery"] = this.dalleFarmacia;
    productChanges["endDelivery"] = this.alleFarmacia;
    productChanges["products"] = [];
    this.productList.forEach((x) => {
      var price = x.productPrice ? x.productPrice.toFixed(2) : "0.00";
      if (x.productId != "Bill") {
        productChanges["products"].push({
          price: price,
          productId: x.productId,
          available: x.available,
          count: x.count,
          productDescription: x.productDescription,
        });
      }
      if (x.available == true) {
        this.productFlag = true;
      }
    });

    if (this.deliveryData == undefined) {
      this.productFlag = true;
      alert("Attenzione! La data deve essere valorizzata!");
    }
    if (this.dalleFarmacia >= this.alleFarmacia) {
      this.productFlag = false;
      alert(
        "Attenzione, il campo 'dalle' non può essere superiore o pari ad 'alle'"
      );
    }
    if (dataTo < dataConfronto) {
      this.productFlag = false;
      alert(
        "Attenzione, la data di spedizione non può essere precedente a quella di oggi"
      );
    }
    if (this.productFlag == true) {
      const sub1$ = this.orderService
        .changePriceReward(productChanges as IProcutList)
        //.toPromise()
        .subscribe((res) => {
          if (res) {
            const sub2$ = this.orderService
              .statusChangerReward(this.route.snapshot.paramMap.get("id"), 1)
              .subscribe(
                (res) => {
                  this.router.navigate([
                    "/gestione-premi/" + this.route.snapshot.paramMap.get("id"),
                  ]);
                },
                () => {
                  sub1$.unsubscribe();
                  sub2$.unsubscribe();
                },
                () => {
                  sub1$.unsubscribe();
                  sub2$.unsubscribe();
                }
              );
          }
        });

      let tokenUser;
      let tokenEmployee;
      this.orderService
        .showReward(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          this.userService.getprofilebyId(x.idShop).subscribe((y) => {
            tokenEmployee = y.fbtoken;
            let email = x.buyerEmail;
            this.userService.get(email).subscribe((y) => {
              tokenUser = y.fbtoken;
              this.notificationService.invionotifica(
                [tokenUser, tokenEmployee],
                "La richiesta per il premio n." +
                  this.orderId +
                  " è stata elaborata",
                "Richiesta premio n." + this.orderId
              );
            });
          });
        });
    }
  }
  annullaPreventivo(dom = "Sicuro di voler annullare il preventivo?") {
    var domanda = confirm(dom);
    if (domanda == true) {
      this.orderService
        .statusChangerReward(this.route.snapshot.paramMap.get("id"), 2)
        .subscribe((res) => {
          this.router.navigate(["/gestione-premi/" + this.idshop]);
        });
      let token;
      this.orderService
        .showReward(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          let email = x.buyerEmail;
          this.userService.get(email).subscribe((y) => {
            token = y.fbtoken;
            this.notificationService.invionotifica(
              [token],
              "La richiesta per il premio n." +
                this.orderId +
                " è stata annullata",
              "Richiesta Premio n." + this.orderId
            );
          });
        });
    }
  }

  sum(purpose): string {
    let amount = 0.0;
    let totalPoints = 0;
    amount += this.costoDelivery;

    this.productList.forEach((c) => {
      if (c.available) {
        amount += c.productPrice * c.count;
        totalPoints += c.productPoints * c.count;
      }
    });
    if (purpose == "price") return amount.toFixed(2).toString();

    if (purpose == "points") return totalPoints.toString();
  }

  setproductPrice(price: number) {
    if (price === null) {
      this.prezzoMinimo = 0;
    }
    this.prezzoMinimo = price;
  }
}
