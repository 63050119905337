import { Component, OnInit } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { UserService } from "../../services/user.service";
import { Location } from "@angular/common";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Role } from "src/app/enum/Role";
import { ImageService } from "src/app/services/image.service";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Farmacia } from "src/app/models/Farmacia";
import { element } from "protractor";
import {
  areAllEquivalent,
  ArrayType,
} from "@angular/compiler/src/output/output_ast";
import { NgxSpinnerService } from "ngx-spinner";
import { User } from "src/app/models/User";

export class ImageSnippet {
  pending: boolean = false;
  status: string = "init";

  constructor(public src: string, public file: File) { }
}

@Component({
  selector: "app-aggungi-evento",
  templateUrl: "./aggungi-evento.component.html",
  styleUrls: ["./aggungi-evento.component.css"],
})
export class AggungiEventoComponent implements OnInit {
  fileToUpload: File = null;
  idFarmacia: number[] = [];
  allFarmacie: string[] = [];
  nomeShop: string = "";
  nomeShopForUser: string = "Tutti"
  evento: EventoDettagli;
  name$;
  name: string;
  flag: boolean = false;
  dataObj: NgbDate;
  listUser: string[];
  token: string;
  email: string;
  id2: number;
  showSpecificUsers: boolean = false;
  nomeFarmacia: string;
  selectedFile: ImageSnippet;
  file: File;
  listaFarmacie: Farmacia[];
  listafarmacieOrdinata: Farmacia[];
  listOfImage: Array<any> = [];
  idevento2: string;
  sexChoice = [
    {
      sex: "M",
    },
    { sex: "F" },

    { sex: "Altro" },

    { sex: " " },
  ];
  citta: string = '';
  etaDa: string = '';
  etaA: string = '';
  sesso: any = '';
  dataDa: Date;
  dataA: Date;
  listaCitta: string[] = [];
  listaCittaNew: string[];
  allUtenti: User[] = [];
  codiceGiftcard;
  constructor(
    private calendarioService: CalendarioService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private notificatioService: NotificationServiceService,
    private farmacieService: FarmacieService,
    private imageService: ImageService,
    private spinner: NgxSpinnerService,
  ) {
    this.evento = new EventoDettagli();
    let idshop;
    let idfarmaciaUtente;
    this.route.queryParamMap.subscribe((param) => {
      idshop = param.get("idshop");
    });
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        idfarmaciaUtente = x.shop;
        this.idevento2 = x.idshop.toString();
        if (
          idshop != idfarmaciaUtente &&
          this.userService.currentUserValue.role === Role.Employee
        ) {
          this.router.navigate(["/login"]);
        }
      });

    //   });
  }

  set dataModel(obj: NgbDateStruct) {
    if (obj.day < 10 && obj.day > 0) {
      this.evento.day = "0" + obj.day.toString();
    } else {
      this.evento.day = obj.day.toString();
    }
    if (obj.month < 10 && obj.month > 0) {
      this.evento.month = "0" + obj.month.toString();
    } else {
      this.evento.month = obj.month.toString();
    }
    this.evento.year = obj.year.toString();
    this.dataObj = new NgbDate(obj.year, obj.month, obj.day);
  }

  get dataModel(): NgbDateStruct {
    // this.dp.navigateTo({ year: 2013, month: 2, day: 10 });

    return this.dataObj;
  }
  ngOnInit() {
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        if (this.router.url.includes("/profiloutente"))
          this.evento.idshop = x.shop.toString();
        this.id2 = x.shop;
        if (this.id2 == 0) {
          this.evento.kind = "0";
          // eventi farmacia Eufarma
        } else {
          this.evento.kind = "1";
          // eventi farmacia preferita
        }
        this.farmacieService.getFarmacia(this.id2).subscribe((z) => {
          this.nomeFarmacia = z.name;
        });
      });
    /*    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe(x => {
        this.id2 = x.shop;
      }); */

    // funzione per visualizzare il nome dell'account corrente
    this.name$ = this.userService.name$.subscribe((aName) => {
      this.name = aName;
    });
    this.name = this.userService.currentUserValue.name;

    this.route.queryParamMap.subscribe((param) => {
      this.evento.idshop = param.get("idshop");
      if (param.get("date")) {
        const data = param.get("date").split("-");
        this.dataModel = {
          day: parseInt(data[2], 10),
          month: parseInt(data[1], 10),
          year: parseInt(data[0], 10),
        };
      }
    });
    this.farmaciaList();
    // date 2019-11-28
  }
  removeSingolaFarmacia(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare la farmacia " +
      this.allFarmacie[i] +
      " dalla lista?"
    );
    if (r == true) {
      this.allFarmacie.splice(i, 1);
      this.idFarmacia.splice(i, 1);
    }

    /*     console.log("farmacie: " + this.allFarmacie + " id: " + this.idFarmacia); */
  }
  onMoreFarmacie() {
    
    let tempShop;
    let flag = false;
    tempShop = this.nomeShop;

    const foundShop = this.listafarmacieOrdinata.find(farmacia => farmacia.name === this.nomeShop);
    if (!foundShop) {
        alert("Attenzione, non è stata trovata una farmacia con questo nome.");
        return; 
    }

    this.allFarmacie.push(tempShop);
    let i = 0;
    do {
      if (this.nomeShop == this.listafarmacieOrdinata[i].name) {
        this.idFarmacia.push(this.listafarmacieOrdinata[i].id);
        flag = true;
      }
      if (
        this.nomeShop == null ||
        this.nomeShop == "" ||
        this.nomeShop == " " ||
        this.nomeShop == "  " ||
        this.nomeShop == "Tutti"
      ) {
        var r = alert(
          "Attenzione, selezionando tutte le farmacie svuoterai la lista corrente e creerai un evento per tutti"
        );
        this.idFarmacia = new Array(0);
        this.allFarmacie = ["Tutti"];
        flag = true;
      }

      /*  if (this.nomeShop == "Tutti" && this.idFarmacia.length > 0) {
        window.alert(
          "Attenzione, hai selezionato prima una farmacia e poi tutte le altre, sto svuotando la lista"
        );
        this.idFarmacia = new Array(0);
        this.allFarmacie = [];
      } */
      i++;
    } while (flag == false && i < this.listafarmacieOrdinata.length);
    this.nomeShop = null;
    /*     console.log("farmacie: " + this.allFarmacie + " id: " + this.idFarmacia); */
  }
  svuotaLista() {
    this.allFarmacie = [];
    this.idFarmacia = [];
    this.allUtenti = [];
  }
  onSubmit() {
    this.aggiungiEvento();
  }

  listOfUser() {
    if (+this.etaA < +this.etaDa) {
      this.flag = true;
    }
  
    if (this.sesso === "") {
      this.sesso = undefined;
    }
  
    if (!this.flag) {
      this.spinner.show();

      const farmaciaDesiderata = this.listafarmacieOrdinata.filter(farmacia => farmacia.name === this.nomeShopForUser)[0];

    if (this.nomeShopForUser != '' && farmaciaDesiderata == undefined) {
      this.spinner.hide();
        alert("Attenzione, non è stata trovata una farmacia con questo nome.");
        return; 
    }
      if (!isNaN(+this.etaDa) && !isNaN(+this.etaA) && +this.etaDa >= 0 && +this.etaA >= 0) {
        this.userService.getUsersByFilters(this.citta, this.sesso, this.etaDa, this.etaA, farmaciaDesiderata.id ? farmaciaDesiderata.id : 0)
          .subscribe(
            (users) => {
              this.allUtenti.push(...users);
              this.spinner.hide();
            },
            (error) => {
              alert("Si è verificato un errore durante il recupero degli utenti.");
              this.spinner.hide();
            }
          );
      } else {
        alert("Le età inserite non sono valide.");
        this.spinner.hide();
      }
    } else {
      alert("Impossibile creare la lista con età inserite in modo errato.");
      this.spinner.hide();
    }
  }
  
  removeSingoloUtente(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare l'utente " +
        this.allUtenti[i].name +
        " " +
        this.allUtenti[i].surname +
        " dalla lista?"
    );
    if (r == true) {
      this.allUtenti.splice(i, 1);
    }

    /*     console.log("farmacie: " + this.allFarmacie + " id: " + this.idFarmacie); */
  }

  aggiungiEvento() {
    this.spinner.show();
    let body = {
      idShop: this.evento.idshop,
      title: this.evento.title,
      time: this.evento.time,
      duration: this.evento.duration,
      note: this.evento.note,
      day: this.evento.day,
      month: this.evento.month,
      year: this.evento.year,
      kind: this.evento.kind,
      webUrl: this.evento.webUrl,
      userIdList: this.showSpecificUsers ? this.allUtenti.map(user => user.id) : undefined
    }
    if (this.idFarmacia.length != 0) {
      let usersListToken = [];
      this.idFarmacia.forEach((element) => {

        body.idShop = element.toString();
        this.calendarioService.postEvent(body, this.listOfImage).then((events) => {
          if (!events) {
            throw new Error();
          }
          if (events.status == 200) {
            /*     this.userService.getallUsers(element).subscribe((x) => { */
            /*            x.forEach((element) => {
                         if (element.fbtoken && element.privacykey2 && element.privacykey3)
                           usersListToken.push(element.fbtoken);
                       });
             
                     
           
                       if (usersListToken.length != 0) {
                         this.notificatioService.invionotifica(
                           usersListToken,
                 
                           this.nomeFarmacia +
                             " ha creato un'evento il " +
                             this.evento.day +
                             "-" +
                             this.evento.month +
                             "-" +
                             this.evento.year,
                           "Farmacia " + this.nomeFarmacia
                         );
                       } */
            this.spinner.hide();
            this.router.navigate([`calendario/${this.idevento2}`]);
          }
          else {
            this.spinner.hide();
            alert("Attenzione, qualcosa è andato storto! Riprova.")
          }

          /*   }); */
        }


        );
      });
    } else {

      this.calendarioService.postEvent(body, this.listOfImage).then((events) => {
        if (!events) {
          throw new Error();
        }
        if (events.status == 200) {

          /* this.userService.getallUsers(this.id2).subscribe((x) => { */
          /*       let usersListToken = [];
                let matriceNumber: number;
      
                x.forEach((element) => {
                  if (element.fbtoken && element.privacykey2 && element.privacykey3)
                    usersListToken.push(element.fbtoken);
                });
                matriceNumber = Math.ceil(usersListToken.length / 1000); //1
                var matriceNotifica: string[][] = [];
                for (var i: number = 0; i < matriceNumber; i++) {
                  matriceNotifica[i] = [];
                } let regolatore: boolean;
                matriceNotifica.forEach((element, index) => {
                  regolatore = false;
                  usersListToken.forEach((secondelement, j) => {
                    if (!regolatore)
                      if (matriceNotifica[index].length != 1000) {
                        matriceNotifica[index].push(secondelement);
                      } else {
                        usersListToken.splice(0, j);
                        regolatore = true;
                      }
                  });
                });
      
      
              
                if (matriceNotifica.length != 0) {
                  let cont = 0;
                  do {
                    this.notificatioService.invionotifica(
                      matriceNotifica[cont],
                    
                      this.nomeFarmacia +
                        " ha creato un'evento il " +
                        this.evento.day +
                        "-" +
                        this.evento.month +
                        "-" +
                        this.evento.year,
                      "Farmacia " + this.nomeFarmacia
                    );
                    cont++;
                  } while (matriceNotifica.length > cont);
                } */
          this.spinner.hide();
          this.router.navigate([`calendario/${this.idevento2}`]);


        }
        else {
          this.spinner.hide();
          alert("Attenzione, qualcosa è andato storto! Riprova.")
        }
        /*    }); */
      }

      );
    }
  }

  takeUser() {
    if (this.allUtenti.includes(this.codiceGiftcard)) {
        alert("Attenzione, utente già presente nella lista.");
    } else {
        this.userService.getProfileByGiftcard(this.codiceGiftcard).subscribe(
            (user) => {
                if (user) {
                    this.allUtenti.push(user);
                    this.codiceGiftcard = "";
                } else {
                    alert("Attenzione! Non è stato trovato nessun utente con questa fidelity.");
                    this.codiceGiftcard = "";
                }
            },
            (err) => {
                console.error("Errore durante il recupero del profilo utente:", err);
                alert("Si è verificato un errore durante il recupero del profilo utente.");
                this.codiceGiftcard = "";
            }
        );
    }
}

csvGiftcard(file) {
  let files = file.target.files[0];
  if (files.name.split(".")[1] == "csv") {
    file.value = "";
    this.userService.getCsvUserByGiftcardFile(files).then((x) => {
      this.downloadFile(x, "report-csv");
      /*     (\d){13} */
      var tempCsv: string[] = [];
      var csvSplit = [];
      tempCsv = x.split(/[a-z]|""|";"|"";|;|"=|:|"|\b\d\b|=/gi);
      tempCsv.forEach((element, index) => {
        if (element.includes("0000")) csvSplit.push(element);
      });

      this.userService.getUsersByGiftcards(csvSplit).subscribe((y) => {
        y.forEach((element) => {
          if (element.userFound != null)
            this.allUtenti.push(element.userFound);
        });
      });
      console.log(x);
    });
  } else {
    alert(
      "Attenzione! formato caricato non valido. Puoi caricare solo i file csv."
    );
  }
}

downloadFile(data: any, filename) {
  let parsedResponse = data;
  let blob = new Blob([parsedResponse], { type: "text/csv" });
  let url = window.URL.createObjectURL(blob);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, "Book.csv");
  } else {
    let a = document.createElement("a");
    a.href = url;
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  window.URL.revokeObjectURL(url);
}

  processFile(imageInput: any) {
    let stringToCompare: string = imageInput.files[0].name;
    const file: File = imageInput.files[0];
    if (
      stringToCompare.split(".")[1] == "jpeg" ||
      stringToCompare.split(".")[1] == "jpg"
    ) {
      const reader = new FileReader();

      reader.addEventListener("load", (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);


        this.listOfImage.push(file);
      });

      reader.readAsDataURL(file);
    } else {
      alert("Attenzione! puoi caricare solo immagini jpeg o jpg.");
    }
  }

  searchFarmacia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchFarm(term)))
    );
  searchFarm(term) {
    let tempFarm = [];
    this.listafarmacieOrdinata
      .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 10)
      .forEach((element) => {
        tempFarm.push(element.name);
      });
    return tempFarm;
  }
  farmaciaList() {
    this.farmacieService.getFarmacie().subscribe((data) => {
      this.listaFarmacie = data.filter((e) => e.id != 0);
      this.listaFarmacie = this.listaFarmacie.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listafarmacieOrdinata = this.listaFarmacie.slice();
      let allEmployees: Farmacia;
      allEmployees = {
        id: 0,
        active: null,
        address: null,
        gifts: null,
        idCity: null,
        idDistrict: null,
        idEmployee: null,
        idRegion: null,
        idSmoove: null,
        lat: null,
        long_: null,
        name: "Tutti",
        openingTime: null,
        phone: null,
        meters: null,
        vat: null,
        deliveryService: null,
      };
      this.listafarmacieOrdinata.unshift(allEmployees);
    });
  }

  onCheckboxChange(event: any) {
    this.showSpecificUsers = event.target.checked;
  }

  searchCitta = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchCity(term)))
    );
  searchCity(term) {
    let tempFarm = [];
    this.listaCitta
      .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 4)
      .forEach((element) => {
        tempFarm.push(element);
      });
    return tempFarm;
  }
}

