import { Component, OnInit, NgModule } from "@angular/core";
import { Observable } from "rxjs";
import { OrderService, IProcutList } from "../../services/order.service";
import { Order } from "../../models/Order";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import localeIt from "@angular/common/locales/it";
import { Location } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { Lightbox } from "ngx-lightbox";
import { apiUrl } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { elementAt } from "rxjs/operators";
import { NgxBarcodeModule } from "ngx-barcode";
import { NotificationServiceService } from "src/app/services/notification.service.service";

@Component({
  selector: "app-prize-detail-confermed",
  templateUrl: "./prize-detail-confermed.component.html",
  styleUrls: ["./prize-detail-confermed.component.css"],
})
export class PrizeDetailConfermedComponent implements OnInit {
  order$: Observable<Order>;
  productList: ProductInOrder[];
  orderAmount = 0;
  // name$;
  name1: string;
  // name: string;
  orderId;
  email: string;
  surnameOrder: string;
  surname1: string;
  prezzo: string;
  statoOrdine: string;
  desc: string;
  nome_prodotto: string;
  _albums: Array<any> = [];
  telBuyer: any;
  giftCode: any;
  noteSpedizione;
  noteFarmacista: string;
  fbtoken;
  promotionalCode: any;
  notesFlag: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private barcode: NgxBarcodeModule,
    private notificationService: NotificationServiceService
  ) {
    this.orderService
      .showReward(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        this.productList = x.products;
        this.noteSpedizione = x.note;
        if (this.noteSpedizione != null) {
          this.notesFlag = true;
        }
        let temp = [];
        this.productList.forEach((element, index) => {
          if (element.productId == "001122334") {
            temp.push(element);
            this.productList.splice(index, 1);
          }
          if (element.productId == "112233445") {
            temp.push(element);
            this.productList.splice(index, 1);
          }
        });
        temp.forEach((x) => {
          this.productList.push(x);
        });
        this.name1 = x.buyerName;
        this.surname1 = x.buyerSurname;
        this.orderId = this.route.snapshot.paramMap.get("id");
        this.prezzo = x.orderAmount;
        this.statoOrdine = x.orderStatus;
        this.telBuyer = x.buyerPhone;
        this.noteFarmacista = x.note;
        this.promotionalCode = x.promotionalCode;
        this.userService.getProfile(this.telBuyer).subscribe((x) => {
          if (x.fbtoken && x.privacykey2 && x.privacykey3) {
            this.fbtoken = x.fbtoken;
          }
          this.userService.giftcardbyid(x.idGiftCard).subscribe((x) => {
            this.giftCode = x.number;
          });
        });

        let products = x.products.map((x) => {
          x.oldproductPrice = x.productPrice;
          this.nome_prodotto = x.productName;
          this.desc = x.productDescription;
          x.available = x.available == null ? false : x.available;
          /*      this.orderService.getBillImage(this.route.snapshot.paramMap.get("id"), this.desc).subscribe((answer) =>{
            this._albums = answer;
                
             }); */
          if (this.nome_prodotto.includes("downloadFile")) {
            let numberValue = parseInt(this.desc, 10);
            for (let i = 1; i <= numberValue; i++) {
              const src = `${apiUrl}/getBillImage/${this.route.snapshot.paramMap.get(
                "id"
              )}/${i}`;
              const descrizione = "allegato_" + i + ".jpg";
              const album = {
                src: this.sanitizer.bypassSecurityTrustUrl(src),
                descrizione: descrizione,
              };
              this._albums.push(album);
            }
          }

          return x;
        });
      });

    /* 
    const subs$ = this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        this.productList = x.products;
        let temp = [];
        this.productList.forEach((element, index) => {
          if (
            element.productId == "001122334" ||
            element.productId == "112233445"
          ) {
            temp.push(element);

            this.productList.splice(index, 1);
          }
        });
        temp.forEach((x) => {
          this.productList.push(x);
        });

        subs$.unsubscribe();
      });
 */
    /*  this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        let products = x.products.map((x) => {
          x.oldproductPrice = x.productPrice;
          this.nome_prodotto = x.productName;
          x.available = x.available == null ? false : x.available; */
    /*      this.orderService.getBillImage(this.route.snapshot.paramMap.get("id"), this.desc).subscribe((answer) =>{
            this._albums = answer;
                
             }); */
    /*    if (this.nome_prodotto.includes("downloadFile")) {
            let numberValue = parseInt(this.desc, 10);
            for (let i = 1; i <= numberValue; i++) {
              const src = `${apiUrl}/getBillImage/${this.route.snapshot.paramMap.get(
                "id"
              )}/${i}`;
              const descrizione = "allegato_" + i + ".jpg";
              const album = {
                src: this.sanitizer.bypassSecurityTrustUrl(src),
                descrizione: descrizione,
              };
              this._albums.push(album);
            }
          }

          return x;
        });

        this.productList = products;
        subs$.unsubscribe();
      }); */
  }

  ngOnInit() {}
  /*   addNotes() {
    if (this.noteSpedizione != null) {
      var r = confirm("Sei sicuro di voler aggiungere questa nota?");
      if (r) {
        this.orderService
          .sendNotes(
            this.route.snapshot.paramMap.get("id"),
            this.noteSpedizione
          )
          .subscribe((y) => {
            this.notesFlag = true;
            alert("Nota aggiunta all'ordine!");
            if (this.fbtoken) {
              this.notificationService.invionotifica(
                this.fbtoken,
                "Il farmacista ha aggiunto una nota all'ordine " + this.orderId,
                "Nota aggiunta"
              );
            }
          });
      }
    }
  }
 */

  sum(): string {
    let amount = 0.0;

    this.productList.forEach((c) => {
      if (c.available) {
        amount += c.productPrice * c.count;
      }
    });
    amount.toFixed(2);
    return amount.toString();
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  pdfOrdini() {
    /*     window.location.href = err.text; */

    this.orderService.getPdfOrderReward(this.orderId).subscribe(
      (x) => {
        var newBlob = new Blob([x], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement("a");
        link.href = data;
        let today = new Date();
        link.download = "lista_ordine_" + today.getTime() + ".pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (e) => {
        console.log(e);
      }
    );
    /*      window.location.href = err.text; */
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
}
