import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Subscription } from "rxjs";
import { JwtResponse } from "../../response/JwtResponse";
import { Router, ActivatedRoute } from "@angular/router";
import { Role } from "../../enum/Role";
import { FarmacieService } from "src/app/services/farmacie.service";

class JwtResponseNavigation extends JwtResponse {
  shop?: number;
  id?: number;
}

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit, OnDestroy {
  currentUserSubscription: Subscription;
  name$;
  name: string;
  currentUser: JwtResponseNavigation = null;
  root = "/";
  Role = Role;
  toggle: Boolean = false;
  more: Boolean = true;
  nome: string;
  sectionEnabledState: { [key: string]: boolean } = {};

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private farmaciaService: FarmacieService
  ) { }

  toggleMenu(routTo: string | undefined) {
    const domRef: HTMLElement = document.querySelector(
      "div.kt-aside-overlay"
    ) as HTMLElement;
    this.toggle = !this.toggle;
    console.log("toggle", this.toggle);
    if (domRef) {
      domRef.click();
    }
    if (routTo) {
      this.router.navigateByUrl(routTo);
      /*  this.detectBrowserVersion(); */
    }
  }

  toggleMore() {
    this.more = !this.more;
  }

  logout() {
    this.userService.logout();
    this.router.navigate(["/login"]);
  }

  ngOnInit() {
      /*   this.farmaciaService
        .getFarmacia(parseInt(this.nome.split("_")[0]))
        .subscribe((x) => {
          this.corriere = x.deliveryService;
          if (
            this.router.url.includes("/orderbyemployee") &&
            this.corriere != "Nessun corriere"
          ) {
            this.spedizione = true;
          }
        }); */
    

    this.name$ = this.userService.name$.subscribe((aName) => {
      this.name = aName;
    });
    this.nome =
      this.userService.currentUserValue &&
      this.userService.currentUserValue.name;

    // modifica fatta da torre sul nome che compare quando si aggiorna la pagina
    this.name =
      this.userService.currentUserValue &&
      this.userService.currentUserValue.name;
    this.currentUserSubscription = this.userService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
        if (this.currentUser) {
          //  if (this.currentUser.role == 'ROLE_MANAGER') {
          this.userService
            .get(this.userService.currentUserValue.account)
            .subscribe((x) => {
              this.currentUser.id = +x.id.toString();
              this.currentUser.shop = x.shop;
              if (this.userService.currentUserValue.role === Role.Employee) {
              this.farmaciaService.getFarmacia(x.shop).subscribe((farmacia) => {
                farmacia.sections.forEach(section => {
                  this.sectionEnabledState[section.sectionEnum] = section.enabled;
              });
              });
            }
            });
          //  }
          this.root = "/seller";
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
    this.name$.unsubscribe();
  }

  get shop(): number {
    return this.currentUser.shop;
  }

  get id(): number {
    return this.currentUser ? this.currentUser.id : -1;
  }
  showMenu(): Promise<boolean> | null {
    return Promise.resolve(this.currentUser !== null);
  }

  /*   detectBrowserVersion() {
      var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      if (isChrome) {
        var ua = navigator.userAgent;
        var tem;
        var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        let browserVersion = M.join(' ');
        console.log(browserVersion)
        if (parseInt(browserVersion.split(" ")[0] == 'Chrome' && browserVersion.split(" ")[1]) < 70) {
          var r = confirm("Attenzione, la versione di Chrome risulta obsoleta e alcune funzionalità potrebbero essere bloccate, vuoi essere reindirizzato alla pagina per aggiornarlo?")
          if (r) {
            let url = location.href;
            location.href = "https://support.google.com/chrome/answer/95414?hl=it&co=GENIE.Platform%3DDesktop";
  
          }
        }
      }
      else {
        alert("Attenzione! Il sito è ottimizzato su Chrome, alcune funzionalità potrebbero non funzionare.")
      }
    } */
}
