import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notification.service.service';
import { ProductDiscountService } from 'src/app/services/product-discount.service';

@Component({
  selector: 'app-product-discount',
  templateUrl: './product-discount.component.html',
  styleUrls: ['./product-discount.component.css']
})
export class ProductDiscountComponent implements OnInit {
  products;
  idShop;
  searchTermTemp;
  productsOrdinati;
  pageSize = 10;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  flagDescription: boolean = true;
  constructor(
    private spinner: NgxSpinnerService,
    private discountedProductService: ProductDiscountService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationServiceService,
  ) { }

  ngOnInit() {
    this.idShop = this.route.snapshot.paramMap.get("id");
    this.getAllProdotti();
  }


  getAllProdotti() {
    this.spinner.show();
    this.discountedProductService.getAllDiscountedProducts().subscribe((x) => {
      this.products = x;
      this.productsOrdinati = this.products;
      this.pageLength = x.length;
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
      })
  }
  removeProduct(product) {
    let r = confirm("Sei sicuro di voler eliminare " + product.productName + "?");
    if (r) {
      this.spinner.show();
      this.discountedProductService.removeDiscountedProduct(product.id).subscribe((x) => {
        this.spinner.hide();
        alert("Rimozione avvenuta con successo");
        this.getAllProdotti();

      },
        (err) => {
          if (err.text.includes("Prodotto in offerta")) {
            this.spinner.hide();
            alert("Rimozione avvenuta con successo");
            this.getAllProdotti();

          }
        })
    }
  }
  truncate(str, n) {
    if (this.flagDescription)
      return (str.length > n) ? str.substr(0, n - 1) + '...' : str;

    else
      return str;


  };

  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.productsOrdinati = this.products.slice(offset).slice(0, event.pageSize);
  }

  toggleTruncate() {
    this.flagDescription = !this.flagDescription;
  }

}
