import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User } from "../../models/User";
import { Role } from "../../enum/Role";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtResponse } from "src/app/response/JwtResponse";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-entry-point",
  templateUrl: "./entry-point.component.html",
  styleUrls: ["./entry-point.component.css"],
})
export class EntryPointComponent {
  currentUser: JwtResponse;
  Role = Role;
  returnUrl = "/";
  error = false;
  loginForm: any;
  user: JwtResponse;
  cookieDetails = {};

  constructor(
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    this.user = this.cookieService.getAll() as JwtResponse;
    //console.log(this.user);
    this.cookieService.deleteAll();
    localStorage.setItem("currentUser", JSON.stringify(this.user));
    this.userService.entryLogin(this.user);

    if (this.user.role === Role.Employee) {
      this.returnUrl = "/orderbyemployee";
    } else if (this.user.role === Role.Manager) {
      this.returnUrl = "/dashboard";
    } else if (this.user.role === Role.Customer) {
      this.userService.logout();
      this.error = true;
    }
    this.router.navigateByUrl(this.returnUrl);
  }
}
