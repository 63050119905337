import { Component, OnInit } from "@angular/core";
import { Coupon, CouponUsers } from "src/app/models/Coupon";
import { CouponService } from "src/app/services/coupon.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Lightbox } from "ngx-lightbox";

@Component({
  selector: "app-info-coupon-employee",
  templateUrl: "./info-coupon-employee.component.html",
  styleUrls: ["./info-coupon-employee.component.css"],
})
export class InfoCouponEmployeeComponent implements OnInit {
  listOfUser: CouponUsers[] = [];
  cont: number = 0;
  idCoupon: any;
  contUsed: number = 0;
  _albums: Array<any> = [];
  idShop: any;
  constructor(
    private couponService: CouponService,
    private route: ActivatedRoute,
    private router: Router,
    /*   private location: Location, */
    private userService: UserService,
    private _lightbox: Lightbox
  ) {}

  ngOnInit() {
    this.idCoupon = this.route.snapshot.paramMap.get("id");
    this.couponService.getUsers(this.idCoupon).subscribe((x) => {
      x.forEach((element) => {
        if (element.user != null) {
          this.listOfUser.push(element);
          this.cont++;

          if (element.used == true) {
            this.contUsed++;
          }
        }
      });
    });
    this.couponService.getAttachmentCoupon(this.idCoupon).subscribe((x) => {
      console.log(x);
      x.forEach((element, index) => {
        const src = x[index];
        const descrizione = "allegato_" + index + ".jpg";
        const album = {
          src: src,
          descrizione: descrizione,
        };

        this._albums.push(album);
      });
    });
  }
  /* onSubmit() {
    this.couponService.newCoupon(this.buono).subscribe((res) => {
      this.router.navigate(["/crea-coupon"]);
    });
  } */
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
