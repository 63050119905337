import { Component, OnInit } from "@angular/core";
import { Farmacia } from "src/app/models/Farmacia";
import { FarmacieService } from "src/app/services/farmacie.service";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { Location } from "@angular/common";
import { Sort } from "@angular/material/sort";
import { MessageService } from "src/app/services/message.service";
import { Messaggio } from "src/app/models/messaggio";
import { apiUrl } from "src/environments/environment";
import { OrderService } from "src/app/services/order.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Prodotti } from "../order-employee/order-employee.component";

@Component({
  selector: "app-farmacia",
  templateUrl: "./farmacia.component.html",
  styleUrls: ["./farmacia.component.css"],
})
export class FarmaciaComponent implements OnInit {
  name$;
  name: string;
  parolaChiave: string;
  farmacia: Farmacia;
  listaFarmacie: Farmacia[];
  listafarmacieOrdinata: Farmacia[];
  listamessaggiRicevuti: Messaggio[];
  listamessaggiInviati: Messaggio[];
  /*   readArray: Array<number> = []; */
  value: boolean;
  pageLength: number;
  pageSize = 5;
  pageLength1: number;
  arrayInviati: Messaggio[];
  arrayRicevuti: Messaggio[];
  url: string;
  constructor(
    private FarmacieService: FarmacieService,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private messageService: MessageService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();

    this.FarmacieService.getFarmacie().subscribe((data) => {
      this.listaFarmacie = data.filter((e) => e.id != 0);
      this.listaFarmacie = this.listaFarmacie.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listafarmacieOrdinata = this.listaFarmacie.slice();
      this.calcolaMessaggiNonLetti();
      this.listaFarmacie.forEach((farmacia) => {
        farmacia.numVisibleSections = farmacia.sections.filter((section) => section.enabled).length;
    });
    },(err)=>{
      this.spinner.hide();
    });
  }

  calcolaMessaggiNonLetti() {
  
    this.messageService.getAllUnReadMessages().subscribe(
      (result) => {
        this.listafarmacieOrdinata = this.listafarmacieOrdinata.map((farmacia) => {
          const matchingResult = result.find((item) => item.shopId === farmacia.id);
          if (matchingResult) {
            farmacia.msgUnread = matchingResult.countUnreadMessages;
          } else {
            farmacia.msgUnread = 0;
          }
  
          return farmacia;
        });
        this.spinner.hide();
       
      },
      (err) => {
        console.error('Errore durante il recupero dei conteggi dei messaggi non letti:', err);
        this.spinner.hide();
      }
    );
  }


  /* pdfOrdini(id) {
     this.orderService.getOrderPdfByFarm2(id).subscribe(
       (x) => {
         const byteArray = new Uint8Array(atob(x.pdf).split('').map(char => char.charCodeAt(0)));
         console.log(byteArray);
         let blob = new Blob([byteArray], {
           type: "application/pdf",
         });
         let dwldLink = document.createElement("a");
         let url = URL.createObjectURL(blob);
         let isSafariBrowser =
           navigator.userAgent.indexOf("Safari") != -1 &&
           navigator.userAgent.indexOf("Chrome") == -1;
         if (isSafariBrowser) {
           //if Safari open in new window to save file with random filename.
           dwldLink.setAttribute("target", "_blank");
         }
         var filename = "ListaOrdiniFarmacia" + id;
         dwldLink.setAttribute("href", url);
         dwldLink.setAttribute("download", filename + ".pdf");
         dwldLink.style.visibility = "hidden";
         document.body.appendChild(dwldLink);
         dwldLink.click();
         document.body.removeChild(dwldLink);
       },
       (err) => {
         alert("Attenzione! questa farmacia non ha nessun ordine.");
       }
     );
   }*/

  csvOrdini(id) {
    this.spinner.show();
    this.orderService.getOrderCsvByFarm3(id).then((x) => {
      this.downloadFileCsv(x, "lista-ordini");
    }),
      (err) => {
        alert("Attenzione, la farmacia selezionata non ha ordini.");
        console.log(err);
      };
  }
  ConvertToCSV(objArray, headerList) {
    let detailproduct = [
      /*       "available", */
      /*      "cartUserId", */
      /*       "categoryType", */
      /*  "count", */
      /*  "id", */
      "productDescription",
      /*   "productIcon", */
      "productId",
      "productName",
      /*    "productPrice",
      "productStock", */
    ];
    let detailshop = ["id", "name"];
    let products: Prodotti[];
    let shopInfo: any;
    let tempShop: {
      id;
      name;
    };
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No;";
    for (let index in headerList) {
      row += headerList[index] + ";";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      /* per filtrare per status     if (array[i].orderStatus == "1" || array[i].orderStatus == "10") { */
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        products = array[i].customOrderExport["products"];
        shopInfo = array[i].customOrderExport["shop"];
        for (let j = 0; j < products.length; j++) {
          for (let index1 in detailproduct) {
            if (products[j][detailproduct[index1]] == null) {
              products[j][detailproduct[index1]] = " ";
            }
            /*  console.log(products[j]); */
            //console.log(products[j][detailproduct[index1]]);
          }
        }
        /*   for (let j = 0; j < shopInfo.length; j++) {
          for (let index1 in detailshop) {
            if (shopInfo[j][detailshop[index1]] == null) {
              shopInfo[j][detailshop[index1]] = " ";
            }

          }
        } */
        if (head == "products") {
          products.forEach((element) => {
            element.productDescription = element.productDescription.replace(
              ";",
              "-"
            );
            delete element.available;
            delete element.cartUserId;
            delete element.categoryType;
            delete element.count;
            delete element.id;
            delete element.productIcon;
            delete element.productPrice;
            delete element.productStock;
            delete element.productId;
          });

          line +=
            array[i].customOrderExport[head] != null
              ? ";" + JSON.stringify(products)
              : ";";
        } else if (head == "shop") {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" +
              array[i].customOrderExport[head].id +
              "  " +
              array[i].customOrderExport[head].name
              : ";";
        } else if (head == "fidelityNumber") {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + '="' + array[i].customOrderExport[head] + '"'
              : ";";
        } else {
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + array[i].customOrderExport[head]
              : ";";
        }
        /* if (head == "shop") {
          tempShop = {
            id: shopInfo.id,
            name: shopInfo.name,
          };
          
          line +=
            array[i].customOrderExport[head] != null
              ? ";" + JSON.stringify(tempShop)
              : ";";
        } */

        /* line +=
          array[i].customOrderExport[head] != null
            ? ";" + array[i].customOrderExport[head]
            : ";"; */
      }
      str += line + "\r\n";
    }
    /*  } */

    return str;
  }


  downloadFileCsv(data: any, filename) {
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, "Book.csv");
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
    this.spinner.hide();
  }
  nuovaFarmacia() {
    this.router.navigate(["/farmacia/new"]);
  }

  nuovoDipendente() {
    this.router.navigate(["/registrati"]);
  }

  ordinalistaFarmacie(sort: Sort) {
    const data = this.listaFarmacie.slice();
    if (!sort.active || sort.direction === "") {
      this.listafarmacieOrdinata = data;
      return;
    }

    this.listafarmacieOrdinata = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compara(a.id, b.id, isAsc);
        case "name":
          return compara(a.name, b.name, isAsc);
        case "address":
          return compara(a.address, b.address, isAsc);
        case "phone":
          return compara(a.phone, b.phone, isAsc);
        case "message":
          return compara(a.msgUnread, b.msgUnread, isAsc);
        default:
          return 0;
      }
    });
  }
  silentLogin(farmacia) {
    var r = confirm(
      "Sei sicuro di voler eseguire l'accesso silenzioso con la " +
      farmacia.name +
      "?"
    );
    if (r) {
      this.spinner.show();
      let form: any = {
        username: farmacia.id + "_employee@eufarma.eu",
        password: "cRZ#&Zs9*Whsma9X",
        fbtoken: "",
        remembered: true,
      };
      let returnUrl = "/orderbyemployee";

      this.userService
        .silentLogin(form, this.userService.currentUserValue)
        .subscribe((user) => {
          if (user) {
            this.router.navigate([returnUrl]);
            this.spinner.hide();
          }
        },
          (err) => {
            this.spinner.hide();
          });
    }
  }
}
function compara(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
